import Auth from "@aws-amplify/auth";
import { urlsAws } from "../resources/foo/api-endpoints";

const solicitudesService = {
    getAnticipos: (setData, emplid, setPorcentajeSeleccionado) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getAnticipo , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    //data = data.response
                    if (data !== undefined) {
                        if (data.message === "API rate limit exceeded") {
                            setData(2);
                        } else {
                            if (data.message === undefined) {
                                try {
                                    caches.open("api-cache").then((cache) => {
                                        cache.put(urlsAws.getAnticipo + "?rut=" + emplid, new Response(JSON.stringify(data.response)));
                                        setData(data.response);
                                        setPorcentajeSeleccionado(data.response.porcentaje_anterior === "0" ? 40 : parseFloat(data.response.porcentaje_anterior)*100);
                                    });
                                    //  setData(data.response);
                                } catch {
                                    setData(2);
                                }
                            } else {
                                try {
                                    caches.match(urlsAws.getAnticipo + "?rut=" + emplid).then((response) => {
                                        if (response) {
                                            response.json().then((data) => {
                                                setData(data.response);
                                            });
                                        } else {
                                            setData(2);
                                        }
                                    });
                                } catch {
                                    setData(2);
                                }
                            }
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    caches.match(urlsAws.getAnticipo + "?rut=" + emplid).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data.response);
                            });
                        } else {
                            setData(2);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },

    getBonos: (emplid, setData) => {
        Auth.currentSession().then((auth) => {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
            };
            fetch(urlsAws.getBono , requestOptions)
                .then((response) => response.text())
                .then((result) => result.replaceAll(",}", "}"))
                .then((result) => JSON.parse(result))
                .then((response) => {
                    let res = response;
                    if (res.message == undefined && res.response != undefined) {
                        return res.response;
                    } else {
                        throw new Error("Error 429");
                    }
                })
                .then((data) => {
                    if (data.valid != "0" && data.correo != "" && data.tipo_jornada != "") {
                        if (!Array.isArray(data.bonos)) {
                            if (data.bonos.valor_bono == "") {
                                setData(2);
                            }
                        } else {
                            caches.open("api-cache").then((cache) => {
                                cache.put(urlsAws.getBono + "?emplid=" + emplid, new Response(JSON.stringify(data)));
                                setData(data);
                            });
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    caches.match(urlsAws.getBono + "?emplid=" + emplid).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data);
                            });
                        } else {
                            setData(2);
                        }
                    });
                });
        }).catch((err) => console.log(err));
    },

    postAnticipo: (lemplid, lporcentaje, ltipo_accion, lnotificacion, setData, setCargando) => {
        Auth.currentSession().then((auth) => {
            const body = JSON.stringify({ request: { emplid: lemplid, porcentaje: lporcentaje, tipo_accion: ltipo_accion, notificacion: lnotificacion } });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: body,
            };

            fetch(urlsAws.postAnticipo , requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data != undefined) {
                        if (data.message === "API rate limit exceeded") {
                            setData(2);
                        } else {
                            if (data.message == undefined) {
                                try {
                                    caches.open("api-cache").then((cache) => {
                                        cache.put(urlsAws.postAnticipo, new Response(JSON.stringify(data)));
                                        setData(data.response);
                                        if(setCargando){
                                            setCargando(false);
                                        }
                                    });
                                } catch {
                                    setData(2);
                                }
                            } else {
                                try {
                                    caches.match(urlsAws.postAnticipo).then((response) => {
                                        if (response) {
                                            response.json().then((data) => {
                                                setData(data.response);
                                                if(setCargando){
                                                    setCargando(false);
                                                }
                                            });
                                        } else {
                                            setData(2);
                                        }
                                    });
                                } catch {
                                    setData(2);
                                }
                            }
                        }
                    } else {
                        setData(2);
                    }
                })
                .catch((error) => {
                    caches.match(urlsAws.postAnticipo).then((response) => {
                        if (response) {
                            response.json().then((data) => {
                                setData(data.response);
                            });
                        } else {
                            setData(2);
                        }
                    });
                });

        }).catch((err) => console.log(err));
    },

    postBono:  (request, setStatus, setMsj) => {
        Auth.currentSession().then(async (auth) => {
            const requestOptions = {
                method: "POST",
                headers: { Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
                body: request,
            };
            const resp = await fetch(urlsAws.postBono , requestOptions)
                .then((response) => { console.log(response); return response })
                .then((response) => response.json())
                .then((response) => {
                    let res = response;
                    if (res != undefined) {
                        if (res.message == undefined && res.response != undefined) {
                            return res.response;
                        } else {
                            throw new Error("Error 429");
                        }
                    }
                })
                .then((data) => {
                    if (data.valid == 0) {
                        setMsj(data.status);
                        setStatus(2);
                    } else {
                        setStatus(true);
                        setMsj(data.status);
                    }
                })
                .catch((error) => {
                    console.log("bruh",error);
                    setStatus(2);
                    setMsj("Error vuelva a intentarlo");
                });
            return resp;
        }).catch((err) => console.log(err));
    },
};

export default solicitudesService;
