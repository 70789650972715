import React, { useState, useEffect } from 'react';
import styles from "../../resources/css/style-COL.module.css";
import image from "../../resources/images/ic-pdf.svg"
import { PDFDocument } from "pdf-lib";
import download from 'downloadjs';
import RenunciaVoluntariaService from '../../services/renuncia-voluntaria-col/service';

const DescargarArchivo = ({ email }) => {
    const [pdf, setPdf] = useState(null)
    const [loading, setLoading] = useState(true)

    const getPdf = async () => {
        const response = await RenunciaVoluntariaService.getPdf();
        if (response?.status == 200) {
            setPdf(response.data);
            setLoading(false)
        } else {
            errorMessage(null, "")
            setLoading(false)
        }
    }
    useEffect(() => {
        getPdf()
    }, [])

    const generate = async (e) => {
        e.preventDefault()
        const newData = new Buffer.from(pdf, 'base64')
        const pdfDoc = await PDFDocument.load(newData);
        const pdfBytes = await pdfDoc.save();
        download(pdfBytes, "Renuncia_voluntaria.pdf");
    };

    return (<>
        {!loading ? <div className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>
            <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Renuncia Voluntaria Digital</h2>
            <ul className={`${styles["list-unstyled"]} ${styles["d-flex"]} ${styles["justify-content-center"]} ${styles["flex-column"]} ${styles["gap-3"]} ${styles["flex-md-row"]} ${styles["gap-md-5"]} ${styles["fw-bold"]} ${styles["border-md-bottom"]} ${styles["pb-4"]} ${styles["mb-0"]} ${styles["mb-md-5"]}`}>
                <li className={`${styles["d-flex"]} ${styles["align-items-center"]}`}><span className={`${styles["ic-circle"]} ${styles["bg-orange"]}`}>1</span><span className={styles["color-primary"]}>Encuesta</span></li>
                <li className={`${styles["d-flex"]} ${styles["align-items-center"]}`}><span className={`${styles["ic-circle"]} ${styles["bg-orange"]}`}>2</span><span className={styles["color-primary"]}>Completa el Formulario</span></li>
                <li className={`${styles["d-flex"]} ${styles["align-items-center"]}`}><span className={`${styles["ic-circle"]} ${styles["bg-orange"]}`}>3</span><span className={styles["color-primary"]}>Ratifica la Carta</span></li>
                <li className={`${styles["d-flex"]} ${styles["align-items-center"]}`}><span className={`${styles["ic-circle"]} ${styles["bg-orange"]}`}>4</span><span className={styles["color-primary"]}>Descarga la Carta</span></li>
            </ul>
            <div className={`${styles["bg-light-2"]} ${styles["p-4"]} ${styles["text-center"]} ${styles["mb-3"]}`}>
                <h3 className={styles["h4"]}>Renuncia Voluntaria Digital</h3>
                <div className={styles["my-4"]}>
                    <img className={`${styles["d-inline-block"]} ${styles["image-fluid"]}`} src={image} width="60" alt="" />
                </div>
                <button type="button" className={`${styles["btn"]} ${styles["btn-degradado"]}`} onClick={e => generate(e)} >Descargar <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i></button>
            </div>
            <div className={styles["text-center"]}>
                <p className={styles["mb-0"]}>Tu renuncia ratificada fue enviada a RRHH para su tramitación.</p>
                <p>Una copia fue enviada a {email}</p>
            </div>
        </div> : <div data-testid="loading" className={styles["full-spinner"]}>
            <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
        </div>}</>
    )
}

export default DescargarArchivo;