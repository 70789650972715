import React from "react";
import { useNavigate } from "react-router-dom";
import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import { Link } from "react-router-dom";
import imagen1 from "../../resources/images/solicitudes-01.png";
import imagen2 from "../../resources/images/solicitudes-02.jpg";
import imagen3 from "../../resources/images/solicitudes-03.jpg";
import imagen4 from "../../resources/images/solicitudes-04.jpg";
import imagen5 from "../../resources/images/solicitudes-05.jpg";
import imagen6 from "../../resources/images/solicitudes-06.jpeg";
import { useFeatureFlag } from "../../hooks/useFeatureFlag";
import { FEATURE_FLAGS } from "../../utils/enum/featureFlags.enum";
import { LoadingSpinner } from "../commonComponents/LoadingSpinner";

const MisSoliciudes = () => {
    let navigate = useNavigate();
    const { data, isLoading } = useFeatureFlag(FEATURE_FLAGS.PREMIO_HABITACIONAL);

    if (isLoading) return <LoadingSpinner />
    
    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Mis Solicitudes
                    </li>
                </ol>
            </nav>

            <section className="mis-solicitudes">
                <div className="tit-group justify-content-between mb-2 row">
                    <div className="col-12 col-md-6">
                        <h1>Mis Solicitudes</h1>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-md-2 row-cols-xxl-3 g-5 w-auto">
                    <div className="col d-flex">
                        <article className="d-flex flex-column box-card w-100">
                            <div className="box-card__img">
                                <img className="img-fluid" src={imagen1} alt="" />
                            </div>
                            <div className="box-card__body d-flex flex-column flex-grow-1">
                                <h3 className="text-uppercase">
                                    <Link to={"/anticipos"}>Anticipo</Link>
                                </h3>
                                <p>¡Mantén el control de tu dinero! Selecciona el porcentaje de sueldo que quieres recibir como Anticipo Quincenal de forma rápida y sencilla para que lo administres como tú quieras.</p>
                                <div className="text-end mt-auto">
                                    <Link className="btn btn-degradado" to={"/anticipos"}>
                                        Solicitar <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </Link>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="col d-flex">
                         <article className="d-flex flex-column box-card w-100">
                            <div className="box-card__img">
                                <img className="img-fluid" src={imagen2} alt=""></img>
                            </div>
                              <div className="box-card__body d-flex flex-column flex-grow-1">
                                <h3 className="text-uppercase">
                                    <Link to={"/mis-solicitudes/defuncion"} onClick={() => window.scrollTo(0, 0)}>Bono por defunción</Link>
                                </h3>
                                <p>Te acompañamos en este difícil momento con la entrega de días de descanso y una asignación monetaria para ayudarte a cubrir los gastos fúnebres por el fallecimiento de tu familiar.</p>
                                <div className="text-end mt-auto">
                                    <a className="btn btn-degradado" onClick={() => { navigate("/mis-solicitudes/defuncion"); window.scrollTo(0, 0) }}>
                                        Solicitar <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="col d-flex">
                         <article className="d-flex flex-column box-card w-100">
                            <div className="box-card__img">
                                <img className="img-fluid" src={imagen3} alt=""></img>
                            </div>
                              <div className="box-card__body d-flex flex-column flex-grow-1">
                                <h3 className="text-uppercase">
                                    <Link to={"/mis-solicitudes/matrimonio"} onClick={() => window.scrollTo(0, 0)}>Bono por matrimonio</Link>
                                </h3>
                                <p>Queremos ser parte de ese momento especial en el que des el “SÍ”. Por lo mismo, te obsequiamos un bono para que celebres tu matrimonio o acuerdo de unión civil junto a tu pareja.</p>
                                <div className="text-end mt-auto">
                                    <a className="btn btn-degradado" onClick={() => { navigate("/mis-solicitudes/matrimonio"); window.scrollTo(0, 0) }}>
                                        Solicitar <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="col d-flex">
                         <article className="d-flex flex-column box-card w-100">
                            <div className="box-card__img">
                                <img className="img-fluid" src={imagen4} alt=""></img>
                            </div>
                              <div className="box-card__body d-flex flex-column flex-grow-1">
                                <h3 className="text-uppercase">
                                    <Link to="/mis-solicitudes/nacimiento" onClick={() => window.scrollTo(0, 0)}>Bono por nacimiento</Link>
                                </h3>
                                <p>¡Te acompañamos en la llegada de tu hijo/a al mundo! Tenemos para ti un monto de bonificación para el día de su nacimiento.</p>
                                <div className="text-end" style={{marginTop:'9%'}}>
                                    <a className="btn btn-degradado" onClick={() => { navigate("/mis-solicitudes/nacimiento"); window.scrollTo(0, 0) }}>
                                        Solicitar <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="col d-flex">
                         <article className="d-flex flex-column box-card w-100">
                            <div className="box-card__img">
                                <img className="img-fluid" src={imagen5} alt=""></img>
                            </div>
                              <div className="box-card__body d-flex flex-column flex-grow-1">
                                <h3 className="text-uppercase">
                                    <Link to="/mis-solicitudes/remoteJob" onClick={() => window.scrollTo(0, 0)}>Ley de Conciliación de la vida personal, laboral y familiar</Link>
                                </h3>
                                <p>Aquí podrás realizar tu solicitud de la Ley 21.645 de Conciliación de la vida personal, laboral y familiar.</p>
                                <div className="text-end mt-auto">                                <a className="btn btn-degradado" onClick={() => { navigate("/mis-solicitudes/ley-de-conciliacion"); window.scrollTo(0, 0) }}>
                                        Solicitar <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </article>
                    </div>
                    {data && data.isEnabled ? <div className="col d-flex">
                         <article className="d-flex flex-column box-card w-100">
                            <div className="box-card__img">
                                <img className="img-fluid" src={imagen6} alt=""></img>
                            </div>
                              <div className="box-card__body d-flex flex-column flex-grow-1">
                                <h3 className="text-uppercase">
                                    <Link to="/mis-solicitudes/premiohabitacional" onClick={() => window.scrollTo(0, 0)}>Premio Habitacional</Link>
                                </h3>
                                <p>Reconocemos el esfuerzo de los colaboradores en alcanzar el sueño de la casa propia y apoyamos el mejoramiento de viviendas adquiridas previamente.</p>
                                <div className="text-end mt-auto">                                <a className="btn btn-degradado" onClick={() => { navigate("/mis-solicitudes/premiohabitacional"); window.scrollTo(0, 0) }}>
                                        Solicitar <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                        </article>
                    </div> : null}
                </div>
            </section>
        </>
    );
};
export default MisSoliciudes;