import { useEffect, useState } from "react";
import styles from "../../resources/css/style-COL.module.css";
import Form from "./form";
import InterestConflictServices from "../../services/conflicto-interes/service";
import errorMessage from "../SharedComponents/error-message";
import { Link } from 'react-router-dom'

const ConflictoInteres = () => {
  const [isHistoricLoading, setIsHistoricLoading] = useState(false);
  const [isHistoricLoadingError, setIsHistoricLoadingError] = useState(false);
  const [conflictTypes, setConflictTypes] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [historics, setHistorics] = useState([]);
  const [interestConflictStatus, setInterestConflict] = useState({});

  const getRelationShips = async () => {
    try {
      setIsHistoricLoading(true);

      const response = await InterestConflictServices.getRelationShips();

      if (
        response !== null &&
        response !== undefined &&
        (response?.statusCode === 200 || response?.status === 200)
      ) {
        setRelationships(response?.data);
      } else {
        errorMessage(
          null,
          response?.message === null || response?.message === undefined
            ? ""
            : response?.message
        );
        setIsHistoricLoadingError(true);
      }

      setIsHistoricLoading(false);
    } catch (error) {
      setIsHistoricLoading(false);
    }
  };

  const getConflictTypes = async () => {
    try {
      setIsHistoricLoading(true);

      const response = await InterestConflictServices.getConflictTypes();

      if (
        response !== null &&
        response !== undefined &&
        (response?.statusCode === 200 || response?.status === 200)
      ) {
        setConflictTypes(response?.data);
      } else {
        errorMessage(
          null,
          response?.message === null || response?.message === undefined
            ? ""
            : response?.message
        );
        setIsHistoricLoadingError(true);
      }

      setIsHistoricLoading(false);
    } catch (error) {
      setIsHistoricLoading(false);
    }
  };

  const getInterestConflicts = async () => {
    try {
      setIsHistoricLoading(true);

      const response = await InterestConflictServices.getInterestConflicts();

      if (
        response !== null &&
        response !== undefined &&
        (response?.statusCode === 200 || response?.status === 200)
      ) {
        setHistorics(response?.data);
      } else {
        errorMessage(
          null,
          response?.message === null || response?.message === undefined
            ? ""
            : response?.message
        );
        setIsHistoricLoadingError(true);
      }

      setIsHistoricLoading(false);
    } catch (error) {
      setIsHistoricLoading(false);
    }
  };

  const getStatus = async () => {
    try {
      setIsHistoricLoading(true);

      const response = await InterestConflictServices.getStatus();

      if (
        response !== null &&
        response !== undefined &&
        (response?.statusCode === 200 || response?.status === 200)
      ) {
        setInterestConflict(response?.data);
      } else {
        errorMessage(
          null,
          response?.message === null || response?.message === undefined
            ? ""
            : response?.message
        );
        setIsHistoricLoadingError(true);
      }

      setIsHistoricLoading(false);
    } catch (error) {
      setIsHistoricLoading(false);
    }
  };

  const reload = () => {
    getInterestConflicts();
    getStatus();
  };

  useEffect(() => {
    getRelationShips();
    getConflictTypes();
    getInterestConflicts();
    getStatus();
  }, []);

  return (
    <>
      {/* <!-- inicio breadcrumbs -->   */}
      <nav
        className={
          styles["breadcrumbs"] +
          " " +
          styles["d-none"] +
          " " +
          styles["d-lg-block"]
        }
        aria-label="breadcrumb"
      >
        <ol className={styles.breadcrumb}>
          <li className={styles["breadcrumb-item"]}>
          <Link to="/inicio">
            <i className={`${styles["color-orange"]} ${styles["fa"]} ${styles["fa-home"]}`} aria-hidden="true"></i>
           Inicio
          </Link>
          </li>
          <li className={styles["breadcrumb-item"]}>
          Actualización Código de Ética
          </li>
          <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">
            Conflicto de Interés
          </li>
        </ol>
      </nav>
      {/* <!-- fin breadcrumbs --> */}

      {/* <!-- inicio Conflicto de Interés --> */}
      <section className={styles["conflicto-de-interes"]}>
        <h1>Conflicto de Interés</h1>

        {isHistoricLoading ? (
          <div data-testid="loading" className={styles["full-spinner"]}>
            <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
          </div>
        ) : null}

        {!isHistoricLoading && !isHistoricLoadingError ? (
          <Form
            conflictTypes={conflictTypes}
            relationships={relationships}
            historics={historics}
            reload={reload}
            interestConflictStatus={interestConflictStatus}
          />
        ) : null}
      </section>
      {/* <!-- fin Conflicto de Interés --> */}
    </>
  );
};

export default ConflictoInteres;
