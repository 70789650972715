import "../../../resources/css/style-COL.module.css";
import { CollectiveAgreementContent } from "./CollectiveAgreementContent";
import { CollectiveAgreementProvider } from "./context/CollectiveAgreementContext";

export const CollectiveAgreementPage = () => {
  return (
    <CollectiveAgreementProvider>
    <CollectiveAgreementContent/>
    </CollectiveAgreementProvider>
  )
};
