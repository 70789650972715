import { Link } from "react-router-dom";
import "../../resources/css/style-COL.module.css";
import { BreadcrumbListProps } from "./BreadcrumbList.types";
import clsx from "../../utils/clsx";

export const BreadcrumbList = (props: BreadcrumbListProps) => {
  const { items } = props;

  return (
    <>
      <nav className="breadcrumbs d-none d-lg-block" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/inicio">
              <i className="fa fa-home" aria-hidden="true"></i> Inicio
            </Link>
          </li>

          {items.map((item, i) => (
            <li
              className={clsx("breadcrumb-item", {
                active: items.length - 1 === i,
              })}
            >
              {item}
            </li>
          ))}
        </ol>
      </nav>
    </>
  );
};
