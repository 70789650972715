import React, { useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import styles from "../../resources/css/style-COL.module.css";
import { factorsOptions, leadershipQuestions } from "./data/data"
import Lista from "./_components/List.jsx"
import { useNavigate } from 'react-router-dom';

const Encuesta = ({ data }) => {
    const navigate = useNavigate()
    const [errorPreguntaUno, setErrorPreguntaUno] = useState("");
    const [errorPreguntaDos, setErrorPreguntaDos] = useState("");
    const [errorPreguntaTres, setErrorPreguntaTres] = useState("");

    const { control, handleSubmit, watch, setValue, formState: { errors } } = useForm({
        defaultValues: {
            ENC_1A: "F",
            ENC_1B: "F",
            ENC_1C: "F",
            ENC_1D: "F",
            ENC_1E: "F",
            ENC_1F: "F",
            ENC_1G: "F",
            ENC_1H: "F",
            ENC_1I: "F",
            ENC_2A: "",
            ENC_2B: "",
            ENC_2C: "",
            ENC_2D: "",
            ENC_2E: "",
            ENC_3A: '',
        }
    });

    const onSubmit = (data) => {
        let valid = true;

        // Validar que al menos un ENC_1X esté en "T"
        const selectedFactors = factorsOptions.some(option => data[option.value] === "T");
        if (!selectedFactors) {
            setErrorPreguntaUno("* Se requiere seleccionar al menos una opción.");
            valid = false;
        } else {
            setErrorPreguntaUno("")
        }

        // Validar que todos los ENC_2X estén llenos
        const enc2Fields = ["ENC_2A", "ENC_2B", "ENC_2C", "ENC_2D", "ENC_2E"];
        const emptyEnc2Fields = enc2Fields.filter(field => data[field] === "");
        if (emptyEnc2Fields.length > 0) {
            setErrorPreguntaDos("* Se requiere completar todas los campos.");
            valid = false;
        } else {
            setErrorPreguntaDos("")

        }

        // Validar que ENC_3A no esté vacío
        if (!data.ENC_3A) {
            setErrorPreguntaTres("* Se requiere seleccionar un valor.");
            valid = false;
        } else {
            setErrorPreguntaTres("")
        }

        // Si todas las validaciones pasan, enviar el formulario
        if (valid) {
            navigate("formulario", { state: data })
        }
    };

    const handleCheckboxChange = (option) => {
        const currentValues = factorsOptions.filter(opt => watch(opt.value) === "T");

        if (watch(option.value) === "T") {
            setValue(option.value, "F");
        } else if (currentValues.length < 4) {
            setValue(option.value, "T");
        }
    };

    return (
        <>
            <div className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>
                <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Renuncia Voluntaria Digital</h2>
                <ul className={`${styles["list-unstyled"]} ${styles["d-flex"]} ${styles["justify-content-center"]} ${styles["flex-column"]} ${styles["gap-3"]} ${styles["flex-md-row"]} ${styles["gap-md-5"]} ${styles["fw-bold"]} ${styles["border-md-bottom"]} ${styles["pb-4"]} ${styles["mb-0"]} ${styles["mb-md-5"]}`}>
                    <li className={`${styles["d-flex"]} ${styles["align-items-center"]}`}><span className={`${styles["ic-circle"]} ${styles["bg-orange"]}`}>1</span><span className={styles["color-primary"]}>Encuesta</span></li>
                    <li className={`${styles["d-flex"]} ${styles["align-items-center"]}`}><span className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}>2</span><span className={styles["color-primary"]}>Completa el Formulario</span></li>
                    <li className={`${styles["d-flex"]} ${styles["align-items-center"]}`}><span className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}>3</span><span className={styles["color-primary"]}>Ratifica la Carta</span></li>
                    <li className={`${styles["d-flex"]} ${styles["align-items-center"]}`}><span className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}>4</span><span className={styles["color-primary"]}>Descarga la Carta</span></li>
                </ul>
                <div>
                    <h3>Encuesta de Salida</h3>
                    <div className={`${styles["box"]} ${styles["list-group-item-info"]} ${styles["mb-3"]}`}>
                        <p>Te invitamos a contestar esta breve encuesta para conocer tu experiencia trabajando en  <strong>Cencosud</strong>.
                            Tu opinión es muy importante ya que nos permitirá mejorar la percepción laboral de todos nuestros colaboradores.
                            Esta encuesta es confidencial y te tomará pocos minutos responderla.</p>
                        <p><strong>¡Muchas gracias por compartir tu opinión!</strong></p>
                    </div>

                    <div className={`${styles["box"]}`}>
                        <div className="text-center">
                            <p className={`${styles["col-form-label-lg"]} ${styles["p-0"]}`}>Hola, {data.nombre_colaborador}.</p>
                            <p className={`${styles["col-form-label-sm"]} ${styles["p-0"]}`}>Cuando envíe este formulario, el propietario verá su nombre y dirección de correo electrónico.</p>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            {/* Primer Pregunta */}
                            <div className={`${styles["mb-5"]}`}>
                                <p className={`${styles["fw-bold"]} ${styles["m-0"]}`}>1. ¿Indica cuáles fueron los factores que motivaron tu decisión? *</p>
                                <span>Seleccione como máximo 4 opciones.</span>
                                <br></br>
                                {errorPreguntaUno && <small className="form-error-message">{errorPreguntaUno}</small>}
                                {factorsOptions.map((option, index) => (
                                    <div key={index} className={`${styles["d-flex"]} ${styles["align-items-center"]} ${styles["mb-1"]} ${styles["m-1"]}`}>
                                        <Controller
                                            name={option.value}
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={watch(option.value) === "T"}
                                                    onChange={() => handleCheckboxChange(option)}
                                                />
                                            )}
                                        />
                                        <label class={`${styles["ms-1"]}`}>{option.label}</label>
                                    </div>
                                ))}
                            </div>

                            {/* Segunda Pregunta */}
                            <div className={`${styles["mb-5"]}`}>
                                <p className={`${styles["fw-bold"]} ${styles["m-0"]}`}>2. ¿Cómo calificas el liderazgo de tu área? *</p>
                                <span>De 1 a 4, siendo 1 malo, 2 regular, 3 bueno y 4 excelente</span>
                                <br></br>
                                {errorPreguntaDos && <small className="form-error-message">{errorPreguntaDos}</small>}{" "}
                                <Lista leadershipQuestions={leadershipQuestions} control={control} />
                            </div>

                            {/* Tercer Pregunta */}
                            <div>
                                <p className={`${styles["fw-bold"]} ${styles["m-0"]}`}>3. ¿Qué tan probable es que recomiendes a Cencosud como un buen lugar para trabajar? *</p>
                                <span>Donde 1 es Poco Probable y 10 Muy Probable</span>
                                <br></br>
                                {errorPreguntaTres && <small className="form-error-message">{errorPreguntaTres}</small>}{" "}
                                <div
                                    className={`btn-toolbar  ${styles["d-flex"]} ${styles["align-items-center"]} ${styles["mb-2"]} ${styles["mt-3"]}`}
                                    role="toolbar"
                                    aria-label="Toolbar with button groups">
                                    <div className="btn-group me-2" role="group" aria-label="First group">
                                        {[...Array(10)].map((_, index) => (
                                            <Controller
                                                key={index + 1}
                                                name="ENC_3A"
                                                control={control}
                                                render={({ field }) => (
                                                    <button
                                                        type="button"
                                                        className={`btn ${field.value === index + 1 ? 'btn-primary' : 'btn-outline-secondary'}`}
                                                        onClick={() => field.onChange(index + 1)}
                                                    >
                                                        {index + 1}
                                                    </button>
                                                )}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["justify-content-md-end"]} ${styles["gap-3"]} ${styles["flex-nowrap"]} ${styles["my-5"]}`}>
                                <div className={`${styles["order-md-last"]} ${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["gap-2"]}`}>
                                    <button type="submit" className={`${styles["btn"]} ${styles["btn-degradado"]}`}>Siguiente paso<i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Encuesta;