import React, { FC, useEffect, useRef } from "react";
import { useBonusGetQuery } from "../hooks/getBonusesHook";
import { BonusFormProps, ResponseData } from "../interfaces/interfaces";
import BonusInfo from "./BonusInfo";
import RequestForm from "../../Commons/Form/components/RequestForm";
import { useNavigate } from "react-router-dom";
import { useFormStore } from "../../Commons/Stores/formStore";
import ModalLoad from "../../../commonComponents/ModalLoad";
import ModalInfoDinamic from "../../../commonComponents/ModalnfoDinamic";
import { useModalStore } from "../../Commons/Stores/modalsStore";
import { useBonusPostMutation } from "../hooks/postBonusesHook";
import { useSectionsStore } from "../store/storeBonuses";

const BonusForm: FC<BonusFormProps> = ({ type }) => {
  const refLoad = useRef<HTMLButtonElement>(null);
  const refLoadClose = useRef<HTMLButtonElement>(null);
  const refStatus = useRef<HTMLButtonElement>(null);
  const { dataModal } = useModalStore();
  const { data, isLoading, isError } = useBonusGetQuery(type, "");
  let navigate = useNavigate();
  const { dataForm, resetDataForm } = useFormStore();
  const { mutate } = useBonusPostMutation();
  const { setDataModal } = useModalStore();
  const { resetSections } = useSectionsStore();

  useEffect(() => {
    if (dataForm) {
      refLoad?.current?.click();
      mutate(dataForm, {
        onSuccess: (response) => {
          if (response.valid) {
            setTimeout(() => {
              refLoadClose?.current?.click();
            }, 500);
            setTimeout(() => {
              setDataModal({
                callBack: () => {
                  refStatus.current?.click();
                  setTimeout(() => {
                    resetDataForm();
                    navigate("/mis-solicitudes");
                  }, 1000);
                },
                icon: "fa-check-circle-o",
                msg: "Solicitud enviada correctamente.",
                subtext: "",
              });
              refStatus?.current?.click();
            }, 1000);
          } else {
            handleErrorPost(response);
          }
        },
        onError: (errorPost) => {
          console.log("Error en post: ", errorPost);
          handleErrorPost({
            status: 500,
            valid: false,
            statusText: "Ha ocurrido un error al enviar solicitud.",
          });
        },
      });
    }
  }, [dataForm]);

  useEffect(() => {
    return () => {
      resetSections();
      resetDataForm();
    };
  },[]);

  const handleErrorPost = (errorPost: ResponseData) => {
    setTimeout(() => {
      refLoadClose?.current?.click();
    }, 500);
    setTimeout(() => {
      setDataModal({
        icon: "fa-exclamation-triangle",
        msg: errorPost.statusText,
        callBack: () => {
          refStatus.current?.click();
        },
        subtext: "",
      });
      refStatus?.current?.click();
    }, 1000);
  };

  return (
    <>
      <section className={`bono-por-${type}`}>
        <h1>{data?.response.title}</h1>
        {isLoading && (
          <>
            <div className="text-center">
              <span className="spinner-border text-primary spinner-border-sm" />
              <p> Cargando información... </p>
            </div>
          </>
        )}
        {isError && (
          <>
            <div className="text-center mt-5">
              <p>
                Ha ocurrido un error al obtener la información o el bono no
                existe. Por favor, intente más tarde.
              </p>
            </div>
          </>
        )}
        {data ? (
          <>
            {!data.response.valid ? (
              <>
                <div className="text-center mt-5">
                  <p dangerouslySetInnerHTML={{ __html: data.response.reqStatus }} />
                </div>
              </>
            ) : (
              <>
                {data.response.informationHTML && (
                  <>
                    <div className="box mb-4">
                      <h2 className="h4 tit-section">{"Detalle"}</h2>
                      <article
                        dangerouslySetInnerHTML={{
                          __html: data.response.informationHTML,
                        }}
                      />
                    </div>
                  </>
                )}
                {data.response.tableSection &&
                  data.response.tableSection.length > 0 && (
                    <>
                      <BonusInfo tableSection={data.response.tableSection} />
                    </>
                  )}
                {data.response.sections.length > 0 && (
                  <>
                    <RequestForm sections={data.response.sections} />
                  </>
                )}
              </>
            )}
          </>
        ) : null}
      </section>

      <ModalLoad
        refOpen={refLoad}
        refClose={refLoadClose}
        text={"Enviando formulario..."}
      />
      <ModalInfoDinamic
        refOpen={refStatus}
        icon={dataModal.icon}
        text={dataModal.msg}
        callBack={dataModal.callBack}
        subtext={dataModal.subtext}
      />
    </>
  );
};
export default BonusForm;
