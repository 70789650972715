import { useState, useRef, useContext } from "react";
import { Context } from "../../context";
import styles from "../../resources/css/style-COL.module.css";
import { useForm } from "react-hook-form";
import errorMessage from "../SharedComponents/error-message";
import Swal from "sweetalert2";
import InterestConflictServices from "../../services/conflicto-interes/service";
import successMessage from "../SharedComponents/success-message";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-degradado ms-md-2",
    cancelButton: "btn btn-primary",
  },
  buttonsStyling: false,
});

const Fields = ({ conflictTypes, relationships, reload }) => {
  const [isSaving, setIsSaving] = useState(false);

  const { pais } = useContext(Context);
  const isAR = pais === "AR";

  const save = async (data) => {
    swalWithBootstrapButtons
      .fire({
        title: "Confirmación",
        text: "¿Desea continuar la operación?",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        showCloseButton: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setIsSaving(true);

          const dto = {
            isThereConflict: data?.is_there_a_conflict === "1" ? "Y" : "N",
            agree: data?.agree,
            nationalId: data.nationalId ?? "",
            conflictType:
              data?.conflict_type === null ||
              data?.conflict_type === undefined ||
              data?.is_there_a_conflict === "0"
                ? ""
                : data?.conflict_type,
            relationship:
              data?.relationship === null ||
              data?.relationship === undefined ||
              data?.is_there_a_conflict === "0"
                ? ""
                : data?.relationship,
            relationshipName:
              data?.relationship_name === null ||
              data?.relationship_name === undefined ||
              data?.is_there_a_conflict === "0"
                ? ""
                : data?.relationship_name,
            observation:
              data?.observation === null ||
              data?.observation === undefined ||
              data?.is_there_a_conflict === "0"
                ? ""
                : data?.observation,
          };

          // Call to service
          let response = await InterestConflictServices.createInterestConflict(
            dto
          );

          if (response === null) {
            errorMessage(null, "");
          } else {
            const status = response?.statusCode || response?.status;
            switch (status) {
              case 200:
                setIsSaving(false);
                reset();
                successMessage(reload());
                break;

              case 400:
                errorMessage(null, response?.message);
                setIsSaving(false);
                break;

              case 500:
                errorMessage(null, response?.message);
                setIsSaving(false);
                break;

              default:
                break;
            }
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const isThereConflict = watch("is_there_a_conflict", 0);

  const caracteres = useRef({});

  caracteres.current = watch("observation", "");

  return (
    <>
      <div className={styles["box"] + " " + styles["mb-4"]}>
        <h2 className={styles["h4"] + " " + styles["tit-section"]}>Firmar</h2>

        <form>
          {/* <!-- Fila 1 --> */}
          <div className={styles["row"]}>
            <div
              className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
            >
              <label className={styles["form-label"]}>
                ¿Existe conflicto?{" "}
                <span className={styles["color-red"]}>*</span>
              </label>
              <div className={styles["input-group"]}>
                <select
                  className={styles["form-select"]}
                  {...register("is_there_a_conflict", {
                    required: "* Campo obligatorio",
                  })}
                >
                  <option value="" selected>
                    Seleccione
                  </option>

                  <option key={"key_no_0"} value={0}>
                    {"No"}
                  </option>

                  <option key={"key_si_1"} value={1}>
                    {"Si"}
                  </option>
                </select>
              </div>
              <small className={styles["form-error-message"]}>
                {errors?.is_there_a_conflict?.message}
              </small>
            </div>
            <div
              className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
            >
              <label className={styles["form-label"]}>
                He leído y acepto <span className={styles["color-red"]}>*</span>
              </label>
              <div className={styles["row"]}>
                <div className={`${styles["col-12"]} ${styles["col-md-4"]}`}>
                  <div className={styles["form-check"]}>
                    <input
                      className={styles["form-check-input"]}
                      type="checkbox"
                      name="agree"
                      id="agree1"
                      // value="true"
                      {...register("agree", {
                        required: "* Campo obligatorio",
                      })}
                    />
                    <label
                      className={styles["form-check-label"]}
                      htmlFor="agree1"
                    >
                      Sí
                    </label>
                  </div>
                  <small className={styles["form-error-message"]}>
                    {errors?.agree?.message}
                  </small>
                </div>
              </div>
            </div>
          </div>

          {isThereConflict === "1" ? (
            <>
              {/* <!-- Fila 2 --> */}
              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Tipo de conflicto{" "}
                    <span className={styles["color-red"]}>*</span>
                  </label>
                  <div className={styles["input-group"]}>
                    <select
                      className={styles["form-select"]}
                      {...register("conflict_type", {
                        required: "* Campo obligatorio",
                      })}
                    >
                      <option value="" selected>
                        Seleccione
                      </option>

                      {conflictTypes.map((list) => {
                        return (
                          <option key={list.id} value={list.id}>
                            {list.description}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <small className={styles["form-error-message"]}>
                    {errors?.conflict_type?.message}
                  </small>
                </div>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Relación <span className={styles["color-red"]}>*</span>
                  </label>
                  <div className={styles["input-group"]}>
                    <select
                      className={styles["form-select"]}
                      {...register("relationship", {
                        required: "* Campo obligatorio",
                      })}
                    >
                      <option value="" selected>
                        Seleccione
                      </option>

                      {relationships.map((list) => {
                        return (
                          <option key={list.id} value={list.id}>
                            {list.description}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <small className={styles["form-error-message"]}>
                    {errors?.relationship?.message}
                  </small>
                </div>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                ></div>
              </div>
              {/* <!-- Fila 3 --> */}
              <div className={styles["row"]}>
                <div
                  className={`${styles[isAR ? "col-md-6" : "col-md-12"]} ${
                    styles["col-12"]
                  } ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Nombre familia / empresa{" "}
                    <span className={styles["color-red"]}>*</span>
                  </label>
                  <input
                    type="text"
                    className={styles["form-control"]}
                    {...register("relationship_name", {
                      required: "* Campo obligatorio",
                      maxLength: 30,
                    })}
                  />
                  <small className={styles["form-error-message"]}>
                    {errors?.relationship_name?.message}
                  </small>
                </div>

                {/* ONLY AR */}
                {isAR && (
                  <div
                    className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                  >
                    <label className={styles["form-label"]}>
                      DNI
                      <span className={styles["color-red"]}>*</span>
                    </label>
                    <input
                      type="text"
                      className={styles["form-control"]}
                      {...register("nationalId", {
                        required: "* Campo obligatorio",
                        maxLength: 20,
                      })}
                    />
                    <small className={styles["form-error-message"]}>
                      {errors?.nationalId?.message}
                    </small>
                  </div>
                )}
              </div>
              {/* <!-- Fila 3 --> */}
              <div className={styles["row"]}>
                <div
                  className={`${styles["col-12"]} ${styles["col-md-12"]} ${styles["mb-3"]}`}
                >
                  <label className={styles["form-label"]}>
                    Observaciones <span className={styles["color-red"]}>*</span>
                  </label>
                  <span className={styles["m-2"]}>
                    {caracteres.current.length}/500
                  </span>
                  <textarea
                    type="text"
                    className={styles["form-control"]}
                    name="observation"
                    {...register("observation", {
                      required: "* Campo obligatorio",
                      maxLength: 500,
                      onChange: (e) => {
                        if (
                          e.target.value.length >= caracteres.current.length
                        ) {
                          return;
                        }
                      },
                    })}
                    rows="3"
                    maxLength="500"
                  />
                  <small className={styles["form-error-message"]}>
                    {errors?.observation?.message}
                  </small>
                </div>
              </div>
            </>
          ) : null}
        </form>

        <div
          className={
            styles["d-grid"] +
            " " +
            styles["d-md-flex"] +
            " " +
            styles["justify-content-md-between"] +
            " " +
            styles["gap-2"] +
            " " +
            styles["flex-nowrap"] +
            " " +
            styles["mt-4"]
          }
        >
          <div
            className={`${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["gap-2"]}`}
          >
            <button
              type="button"
              className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["order-md-last"]} ${styles["ms-md-2"]}`}
              onClick={handleSubmit(save)}
              disabled={isSaving}
            >
              Guardar{" "}
              {isSaving ? (
                <div
                  className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}
                ></div>
              ) : (
                <i
                  className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                  aria-hidden="true"
                ></i>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Fields;
