import React from 'react';
import { Table } from "../../table/Table";
import { Controller } from 'react-hook-form';

const Lista = ({ leadershipQuestions, control }) => {
    const columnsConfig = [
        { accessor: "question", header: "", className: "col-3" },
        { accessor: "1", header: "1", className: "text-center" },
        { accessor: "2", header: "2", className: "text-center" },
        { accessor: "3", header: "3", className: "text-center" },
        { accessor: "4", header: "4", className: "text-center" },
    ];

    const buildDataTable = () => {
        return leadershipQuestions.map((question, index) => {
            const fieldName = `ENC_2${String.fromCharCode(65 + index)}`;
            return {
                question,
                1: (
                    <Controller
                        name={fieldName}
                        control={control}
                        render={({ field }) => (
                            <input
                                type="radio"
                                value={1}
                                className='form-check-input'
                                checked={field.value === "1"}
                                onChange={(e) => field.onChange(e.target.value)}
                            />
                        )}
                    />
                ),
                2: (
                    <Controller
                        name={fieldName}
                        control={control}
                        render={({ field }) => (
                            <input
                                type="radio"
                                value={2}
                                className='form-check-input'
                                checked={field.value === "2"}
                                onChange={(e) => field.onChange(e.target.value)}
                            />
                        )}
                    />
                ),
                3: (
                    <Controller
                        name={fieldName}
                        control={control}
                        render={({ field }) => (
                            <input
                                type="radio"
                                value={3}
                                className='form-check-input'
                                checked={field.value === "3"}
                                onChange={(e) => field.onChange(e.target.value)}
                            />
                        )}
                    />
                ),
                4: (
                    <Controller
                        name={fieldName}
                        control={control}
                        render={({ field }) => (
                            <input
                                type="radio"
                                value={4}
                                className='form-check-input'
                                checked={field.value === "4"}
                                onChange={(e) => field.onChange(e.target.value)}
                            />
                        )}
                    />
                ),
            };
        });
    };

    const dataTable = buildDataTable();

    return (
        <div className="w-100 mt-3">
            {dataTable.length > 0 ? (
                <Table columnsConfig={columnsConfig} data={dataTable} />
            ) : (
                <p>No hay datos disponibles</p>
            )}
        </div>
    );
};

export default Lista;
