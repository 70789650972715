import { Table } from "../../../../components/table";

export type ColumnsConfig = {
  accessor: string;
  header: string;
  className?: string;
  enabledSort?: boolean;
  onClick?: () => void;
  showColumn?: boolean;
};

export type LayoutCardProps = {
  title: string;
  columnsConfig: ColumnsConfig[];
  data: any[];
  style?: React.CSSProperties
  
};

export const LayoutCard = (props: LayoutCardProps) => {
  const { title, columnsConfig,data, style } = props;
  return (
    <div>
      <h2 className="h4 tit-section">{title}</h2>

      <Table style={style} data={data} columnsConfig={columnsConfig} />
    </div>
  );
};
