import { ImFilesEmpty } from "react-icons/im";
import { BreadcrumbList } from "../../../components/breadcrumb";
import { useCollectiveAgreementProvider } from "./context/CollectiveAgreementContext";
import { SpinerFullScreen } from "../../../components/commonComponents/SpinerFullScreen";
import { Modal } from "react-bootstrap";

export const CollectiveAgreementContent = () => {
  const {
    selectedBenefit,
    benefitsList,
    benefitsListDetail,
    handleBenefitChange,
    onSubmit,
    resetSelectedBenefit,
    register,
    handleSubmit,
    errors,
    isLoading,
    showModal,
    modalMessage,
    hideModal,
    modalIcon
  } = useCollectiveAgreementProvider();

  return (
    <>
      <BreadcrumbList
        items={["Beneficios", "Convención colectiva", "Solicitud"]}
      />
      <section>
        <h1>Solicitud Beneficios convención colectiva</h1>
      </section>
      <div className="row align-items-center">
        <div className="col-12 col-md-6 mb-3">
          <label className="form-label">Beneficios</label>
          <div className="input-group">
            <select
              className="select-col form-select"
              value={selectedBenefit?.id || ""}
              onChange={handleBenefitChange}
            >
              <option value="">Seleccione</option>
              {benefitsList.map((benefit) => (
                <option
                  key={benefit.id}
                  value={benefit.id}
                  data-id={benefit.id}
                  data-name={benefit.name}
                >
                  {benefit.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {isLoading && <SpinerFullScreen />}

        {!isLoading && selectedBenefit && (
          <>
            <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
              {benefitsListDetail
                .filter((field) => field.available)
                .map((field, index) => (
                  <div key={index} className="col-12 col-md-6">
                    <label className="form-label">
                      {field.fieldLabel} {field.isRequired && "*"}
                    </label>
                    <input
                      type={field.type}
                      className={`form-control ${
                        errors[field.fieldName] ? "is-invalid" : ""
                      }`}
                      maxLength={field.length}
                      placeholder={`Enter ${field.fieldLabel}`}
                      {...register(field.fieldName, {
                        required: field.isRequired,
                      })}
                    />
                    {errors[field.fieldName] && (
                      <div className="invalid-feedback">
                        Este campo es obligatorio
                      </div>
                    )}
                  </div>
                ))}

              <div className="col-12 d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={resetSelectedBenefit}
                >
                  Cancelar
                </button>
                <button type="submit" className="btn btn-primary">
                  Solicitar beneficio
                </button>
              </div>
            </form>
          </>
        )}
      </div>
      {!isLoading && !selectedBenefit && (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ height: "300px", textAlign: "center" }}
        >
          <ImFilesEmpty
            style={{ width: "100px", height: "100px", marginBottom: "20px" }}
          />
          <p className="fs-4 text-muted">
            Actualmente no ha seleccionado ningún beneficio
          </p>
        </div>
      )}

      <Modal show={showModal} onHide={hideModal} centered>
        <Modal.Body className="text-center py-4">
          <i
            className={`fa ${
              modalIcon === "success"
                ? "fa-check-circle-o"
                : "fa-times-circle-o"
            } fa-3x mb-2`}
            style={{
              color: modalIcon === "success" ? "green" : "red",
            }}
            aria-hidden="true"
          ></i>
          <h2 className="h5">{modalMessage}</h2>
        </Modal.Body>
      </Modal>
    </>
  );
};
