import { FC } from "react";
import { Controller } from "react-hook-form";
import { InputForm } from "../../RemoteJob/interfaces/interfaces";

const TextInput: FC<InputForm> = ({ formState, input, control }) => {
  const handleChange = (field: any, e: React.ChangeEvent<HTMLInputElement>) => {
    if (input.type === "number") {
      const value = e.target.value;
      if (/^\d*$/.test(value) || value === "") {
        field.onChange(value);
      }
    } else {
      field.onChange(e);
    }
  };
  return (
    <>
      <span className="data-list-box__text ms-2">
        <Controller
          name={input.key}
          control={control}
          defaultValue={input.defaultValue || ""}
          render={({ field }) => (
            <>
              <input
                type={input.type}
                onChange={(e) => handleChange(field, e)}
                value={field.value}
                className="form-control"
                id={input.key}
                placeholder={input.placeholder}
                readOnly={input.readOnly}
              />
            </>
          )}
        />
        {formState.errors[input.key] && formState.errors[input.key]?.message ? (
          <div className="invalid-feedback d-block">
            {formState.errors[input.key]?.message}
          </div>
        ) : null}
      </span>
    </>
  );
};

export default TextInput;
