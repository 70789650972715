import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InputsProps, FormularioProps, ResponseRemoteJob, ResultObject, ResponsePostRemoteJob, DataModalDinamicProps, bodyPostAnswer } from "../../interfaces/interfaces";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import * as RD from "@devexperts/remote-data-ts";
import { RemoteData } from "@devexperts/remote-data-ts";
import { httpClientEnv } from "../../../../../services/apiServices/httpClient";
import { HttpError } from "../../../../../services/apiServices/interfaces";
import { getRemoteJob, postRemoteJob, postRemoteJobAnswer } from "../../service/apiRequest";
import { SpinerFullScreen } from "../../../../commonComponents/SpinerFullScreen";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { VALIDATIONS_FUNCTIONS } from "../../../Commons/Form/helpers/validationSchemas";
import ModalConfirmAction from "../../../../commonComponents/ModalConfirmAction";
import FormRemoteJob from "../../components/form/form";
import AlertInfo from "../../../../commonComponents/AlertInfo";
import ModalInfoDinamic from "../../../../commonComponents/ModalnfoDinamic";
import ModalLoad from "../../../../commonComponents/ModalLoad";
import { bytesToMB } from "../../../../../services/utiles";

const RemoteJobs = () => {
    let defaultValues = {
        response: {
        endpoint: "",
        information: "",
        method:"",
        ongoingRequest: false,
        proposalRequest:false,
        proposalResponse: "",
        proposalOriginal: "",
        reqStatus: "",
        reqId: 0,
        sections: [],
        status: "",
        valid: false,
        empName: "",
        empRut: "",
        empEmail: "",
        empPhone: "",
        empJob: "",
        empLocation: "",
    }}
    const [remoteJobData, setRemoteJobData] = useState<RemoteData<HttpError, ResponseRemoteJob>>(RD.initial);
    const [remoteJobDataCopy, setRemoteJobDataCopy] = useState<ResponseRemoteJob>(defaultValues);
    const [remoteJobDataPost, setRemoteJobDataPost] = useState<RemoteData<HttpError, ResponsePostRemoteJob>>(RD.initial);
    const [remoteJobDataPostAns, setRemoteJobDataPostAns] = useState<RemoteData<HttpError, ResponsePostRemoteJob>>(RD.initial);
    const [dependencies, setDependencies] = useState<InputsProps>({});
    const [dataPost, setDataPost] = useState<InputsProps>({});
    const [totalFiles, setTotalFiles] = useState<InputsProps>({});
    const refConfirmActionSubmit = useRef<HTMLButtonElement>(null);
    const refStatus = useRef<HTMLButtonElement>(null);
    const refLoad = useRef<HTMLButtonElement>(null);
    const refLoadClose = useRef<HTMLButtonElement>(null);
    const refStatusClose = useRef<HTMLButtonElement>(null);
    const [totalSize, setTotalSize] = useState(0);
    const [dataModal, setDataModal] = useState<DataModalDinamicProps>({
        callBack : ()=> {},
        icon: '',
        msg: ''
    })
    let navigate = useNavigate();
    
    const validationSchema = yup.object().shape(
        remoteJobDataCopy.response.sections.reduce((accumulator, section) => {
            section.forms.forEach((field) => {
                if (VALIDATIONS_FUNCTIONS[field.type] && field.validations) {
                    accumulator[field.key] = VALIDATIONS_FUNCTIONS[field.type](field.validations);
                }
            });
            return accumulator;
        }, {} as { [key: string]: yup.Schema<any, any, any, any> })
    );

    const { handleSubmit, control, unregister, getValues, formState, setError, resetField, setValue } = useForm<InputsProps>({
        mode: "onSubmit",
        resolver: async (data) => {
            try {
                await validationSchema.validate(data, { abortEarly: false });
                return { values: data, errors: {} };
            } catch (validationErrors: any) {
                const errors = validationErrors.inner.reduce((acc: any, err: any) => {
                    acc[err.path] = {
                        message: err.message,
                    };
                    return acc;
                }, {});
    
                return {
                    values: data,
                    errors,
                };
            }
        },
    });

    const handleDropdownChange = (key: string, value: string) => {
        setDependencies(prevDependencies => ({
            ...prevDependencies,
            [key]: value,
        }));
    };
    
    const GetRemoteJobData = async () => {
        setRemoteJobData(RD.pending)
        RTE.run(await getRemoteJob(), httpClientEnv)
        .then(E.fold(e => { return setRemoteJobData(RD.failure(e)) }, a => {
            let data = RD.success(a)
            if (data._tag === 'RemoteSuccess' &&  data.value.response.sections.length > 0) {
                let forms = data.value.response.sections.filter((section) => section.forms.length > 0)
                if (forms.length === 0) {
                    return setRemoteJobData(RD.failure({ tag: 'httpRequestError', error: 'error GetRemoteJobData forms length' }));
                }
                return setRemoteJobData(RD.success(a));
            } else {
                return setRemoteJobData(RD.failure({ tag: 'httpRequestError', error: 'error GetRemoteJobData' }));
            } 
        }))
    };

    const PostRemoteJobData = async (newForms: InputsProps) => {
        if (remoteJobData._tag === 'RemoteSuccess') {
            RTE.run(await postRemoteJob(newForms), httpClientEnv)
            .then(E.fold(e => { 
                setTimeout(()=> {
                    refLoadClose && refLoadClose.current && refLoadClose.current.click();
                    return setRemoteJobDataPost(RD.failure(e)) 
                },1000)}, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess') {
                    refLoadClose && refLoadClose.current && refLoadClose.current.click();
                    refStatus && refStatus.current && refStatus.current.click();
                    setDataModal({
                        msg: data.value.response.valid ? 'La solicitud ha sido enviada exitosamente' : 'Ha ocurrido un error',
                        submsg: data.value.response.valid ? '': data.value.response.status,
                        icon: data.value.response.valid ? 'fa-check-circle-o' : 'fa-exclamation-triangle',
                        callBack: () => {
                            refStatusClose && refStatusClose.current && refStatusClose.current.click();
                            data._tag === 'RemoteSuccess' && data.value.response.valid ? navigate("/mis-solicitudes") : {}
                        }
                    })
                    return setRemoteJobDataPost(RD.success(a));
                } else {
                    return setRemoteJobDataPost(RD.failure({ tag: 'httpRequestError', error: 'error PostRemoteJobData' }));
                } 
            }))
        } else {
            setTimeout(()=> {
                refLoadClose && refLoadClose.current && refLoadClose.current.click();
                return setRemoteJobDataPost(RD.failure({ tag: 'httpRequestError', error: 'error PostRemoteJobData' }));
            },1000)
        }
    };

    const PostRemoteJobDataAnswer = async (answer: boolean) => {
        if (remoteJobData._tag === 'RemoteSuccess' && remoteJobData.value.response.reqId) {
            let body: bodyPostAnswer = {
                "reqId": remoteJobData.value.response.reqId,
                "isAccepted": answer,
            }
            RTE.run(await postRemoteJobAnswer(body), httpClientEnv)
            .then(E.fold(e => {
                setTimeout(()=> {
                    refLoadClose && refLoadClose.current && refLoadClose.current.click();
                    return setRemoteJobDataPostAns(RD.failure(e)) 
                },1000)}, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess' && data.value.response.valid) {
                    refLoadClose && refLoadClose.current && refLoadClose.current.click();
                    refStatus && refStatus.current && refStatus.current.click();
                    setDataModal({
                        msg: data.value.response.valid ? 'La respuesta ha sido enviada exitosamente' : 'Ha ocurrido un error',
                        submsg: data.value.response.valid ? '': data.value.response.status,
                        icon: data.value.response.valid ? 'fa-check-circle-o' : 'fa-exclamation-triangle',
                        callBack: () => {
                            refStatusClose && refStatusClose.current && refStatusClose.current.click();
                            data._tag === 'RemoteSuccess' && data.value.response.valid ? navigate("/mis-solicitudes") : {}
                        }
                    })
                    return setRemoteJobDataPostAns(RD.success(a));
                } else {
                    return setRemoteJobDataPostAns(RD.failure({ tag: 'httpRequestError', error: 'error PostRemoteJobDataAnswer' }));
                } 
            }))
        } else {
            setTimeout(()=> {
                refLoadClose && refLoadClose.current && refLoadClose.current.click();
                return setRemoteJobDataPostAns(RD.failure({ tag: 'httpRequestError', error: 'error reqNumber vacio' }));
            },1000)
        }
    };

    const filterFormByCondition = (dependen: InputsProps, form: FormularioProps[]) => {      
        const filter = form.filter(obj => {
            if (obj.dependencies && obj.dependencies.length > 0) {
                return obj.dependencies.every(dependency => {
                    const { dependencyKey, dependencyValues } = dependency;
                    const selectedValue = dependen[dependencyKey];
                    if (selectedValue) {
                        return Array.isArray(selectedValue)
                        ? selectedValue.some(value => dependencyValues.includes(value))
                        : dependencyValues.includes(selectedValue);
                    } else {
                        return false;
                    }
                });
            } else {
                return true;
            }
        });
      
        return filter;
    };

    const arrayToObj = (arrays: InputsProps[][]) => {
        return arrays.reduce((result: ResultObject, array) => {
          array.forEach((obj) => {
            const key = Object.keys(obj)[0];
            if(key){
                const value = obj[key];
                result[key] = value;
            }
          });
          return result;
        }, {});
    };

    const onSubmit = () => {
        let data: InputsProps = getValues();
        let totalEmpty = false;
        let arrayFiles: InputsProps[] = [];
        const newForms = remoteJobDataCopy.response.sections.map(item => {
            return item.forms.map((form )=> {
                const key = form.key;

                if (form.type === 'file') {
                    if (!data[key]) {
                        totalEmpty = true;
                        setError(key, { message: 'Este campo es requerido' });
                        return {};
                    } else {
                        const file = data[key] as any;
                        const reader = new FileReader();
                        reader.onload = (event) => {
                            const base64Data = event?.target?.result as string;
                            arrayFiles.push({
                                "fileName": file.name.trim(),
                                "fileDesc": form.label,
                                "fileData": base64Data.split('base64,')[1]
                            });
                        };
                        reader.readAsDataURL(file);
                    }
                }
                return {};
            })
        });

        if(!totalEmpty){
            let dataPost = arrayToObj(newForms);
            dataPost["attachments"] = arrayFiles;
            setDataPost(dataPost)
            refConfirmActionSubmit && refConfirmActionSubmit.current && refConfirmActionSubmit.current.click();
        }
    };

    useEffect(()=>{
        GetRemoteJobData();
    },[])

    useEffect(() => {
        if (remoteJobData._tag === 'RemoteSuccess') {
            const sections = remoteJobData.value.response.sections;
            const updatedSections = sections.map((section) => {
                let newForm = filterFormByCondition(dependencies, section.forms)
                return {
                    ...section,
                    forms: newForm,
                };
            });
            updatedSections.forEach((section) => {
                section.forms.forEach((form)=> {
                  if(form.type === 'file'){
                    resetField(form.key)
                    setValue(form.key, "")
                  }
                }) 
              });
            setRemoteJobDataCopy((prevRemoteJobData) => ({
                ...prevRemoteJobData,
                response: {
                    ...prevRemoteJobData.response,
                    sections: updatedSections,
                },
            }));
        }
    }, [remoteJobData, dependencies]);

    useEffect(()=> {
        if(remoteJobDataCopy.response.sections.length > 0){
            const sections = remoteJobDataCopy.response.sections;
            let data: InputsProps = getValues();
            const currentKeys = Object.keys(data);
            const objForm = sections.reduce((acc: ResultObject, section) => {
                section.forms.forEach((form) => {
                  acc[form.key] = {};
                });
                return acc;
            }, {});

            currentKeys.forEach((key) => {
                if (!(key in objForm)) {
                  unregister(key);
                  setDependencies((prevDependencies) => {
                    const updatedFiles = { ...prevDependencies };
                    delete updatedFiles[key];
                    return updatedFiles;
                   });
                }
            });
        }
    },[remoteJobDataCopy])

    useEffect(()=> {
        if(remoteJobDataPost._tag === 'RemoteFailure'){
            refLoadClose && refLoadClose.current && refLoadClose.current.click();
            refStatus && refStatus.current && refStatus.current.click();
            setDataModal({
                msg: 'Ha ocurrido un error al enviar la solicitud, por favor, intente más tarde.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                    setRemoteJobDataPost(RD.initial)
                    refStatusClose && refStatusClose.current && refStatusClose.current.click();
                }
            })
        }
    },[remoteJobDataPost])

    useEffect(()=> {
        if(remoteJobDataPostAns._tag === 'RemoteFailure'){
            refLoadClose && refLoadClose.current && refLoadClose.current.click();
            refStatus && refStatus.current && refStatus.current.click();
            setDataModal({
                msg: 'Ha ocurrido un error al enviar la solicitud, por favor, intente más tarde.',
                icon: 'fa-exclamation-triangle',
                callBack: () => {
                    setRemoteJobDataPostAns(RD.initial)
                    refStatusClose && refStatusClose.current && refStatusClose.current.click();
                }
            })
        }
    },[remoteJobDataPostAns])

    useEffect(() => {
        if (totalFiles) {
          const valuesArray = Object.values(totalFiles);
          const keysFiles = Object.keys(totalFiles);
          if (valuesArray.length > 0) {
            const validKeys = keysFiles.filter(key =>
                remoteJobDataCopy.response.sections.some((section) =>
                section.forms.some(form => {
                  return form.key === key
                })
              )
            );
            const total = validKeys.reduce(
              (acc, key) => acc + Number(totalFiles[key]),
              0
            );
      
            setTotalSize(total);
          }
        }
      }, [totalFiles, remoteJobDataCopy]);

return(
    <>
        <nav id="nav" className="breadcrumbs" aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/inicio"><i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio</Link></li>
                <li className="breadcrumb-item"><Link type="button" to="/mis-solicitudes"><i style={{ pointerEvents: "none" }} aria-hidden="true"></i> Mis Solicitudes</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{'Ley de Conciliación'}</li>
            </ol>
        </nav>
        <h2>Ley de Conciliación de la vida personal, laboral y familiar</h2>
        {remoteJobData._tag === 'RemoteSuccess' ? (
            <>
                {!remoteJobData.value.response.ongoingRequest ? (
                    <>
                        <p className="color-orange">{remoteJobData.value.response.information}</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {remoteJobDataCopy.response.sections.map((section, i )=> {
                                return(
                                        <>
                                            <div className="box mb-4">
                                            <h2 className="h4 tit-section">{section.title}</h2>
                                            {section.description && <AlertInfo color="alert-primary" text={section.description}/>}
                                                <div className="data-list-box data-list-box-even data-list-box-align-middle data-list-box-form">
                                                    <ul className="list-column-1 list-unstyled">
                                                        {section.forms.length > 0 &&
                                                            <FormRemoteJob
                                                                form={section.forms}
                                                                control={control}
                                                                handleDropdownChange={handleDropdownChange}
                                                                formState={formState}
                                                                setTotalFiles={setTotalFiles}
                                                                totalFiles={totalFiles}
                                                            />
                                                        }
                                                    </ul>
                                                </div>
                                                {section.forms.some((obj) => obj.type === "file") && (
                                                <p className="mt-2">
                                                {" "}
                                                Peso total de archivos:{" "}
                                                {Number(totalSize)
                                                    ? bytesToMB(Number(totalSize))
                                                    : 0}{" "}
                                                MB.
                                                </p>
                                            )}
                                            </div>
                                           
                                        </>
                                    )
                            })}

                        
            
                            <div className="d-flex justify-content-between align-items-center">
                                <button
                                    onClick={() => {
                                    navigate(-1)
                                    }}
                                    type="button"
                                    className="btn btn-link"
                                >
                                    Volver
                                </button>
                                <button type="submit" className="btn btn-degradado me-md-4 mb-2 mb-md-0" disabled={Object.keys(formState.errors).length > 0}>
                                    Enviar{" "}
                                    <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" ></i>
                                </button>
                            </div>
                        </form>
                    </>
                ): remoteJobData.value.response.ongoingRequest && !remoteJobData.value.response.proposalRequest ? (
                    <>
                        <div className="box mb-4" style={{ whiteSpace: 'pre-line'}}>
                            <h2 className="h4 tit-section">{'Estado de solicitud'}</h2>
                            <p>{remoteJobData.value.response.reqStatus}</p>
                            <button type="button" className="btn btn-link" onClick={() => { navigate("/mis-solicitudes"), window.scrollTo(0, 0) }}>Volver</button>
                        </div>
                    </>
                ): remoteJobData.value.response.ongoingRequest && remoteJobData.value.response.proposalRequest ? (
                    <>
                        <div className="box mb-4">
                            <h2 className="h4 tit-section">{'Estado de solicitud'}</h2>
                            <div className="data-list-box data-list-box-even data-list-box-align-middle data-list-box-form">
                                <ul className="list-column-1 list-unstyled">
                                    <li style={{backgroundColor:'#f2f2f2'}}>
                                        <span className={"data-list-box__title text-uppercase w-sm-30 me-4"}>
                                            RUT:
                                        </span>
                                        <span className="data-list-box__text ">
                                            {remoteJobData.value.response.empRut}
                                        </span>
                                    </li>
                                    <li>
                                        <span className={"data-list-box__title text-uppercase w-sm-30 me-4"}>
                                            Nombre:
                                        </span>
                                        <span className="data-list-box__text">
                                            {remoteJobData.value.response.empName}
                                        </span>
                                    </li>
                                    <li style={{backgroundColor:'#f2f2f2'}}>
                                        <span className={"data-list-box__title text-uppercase w-sm-30 me-4"}>
                                            Correo:
                                        </span>
                                        <span className="data-list-box__text">
                                            {remoteJobData.value.response.empEmail}
                                        </span>
                                    </li>
                                    <li>
                                        <span className={"data-list-box__title text-uppercase w-sm-30 me-4"}>
                                            Teléfono:
                                        </span>
                                        <span className="data-list-box__text">
                                            {remoteJobData.value.response.empPhone}
                                        </span>
                                    </li>
                                    <li style={{backgroundColor:'#f2f2f2'}}>
                                        <span className={"data-list-box__title text-uppercase w-sm-30 me-4"}>
                                            Cargo:
                                        </span>
                                        <span className="data-list-box__text">
                                            {remoteJobData.value.response.empJob}
                                        </span>
                                    </li>
                                    <li>
                                    <span className={"data-list-box__title text-uppercase w-sm-30 me-4"}>
                                            Ubicación:
                                        </span>
                                        <span className="data-list-box__text">
                                            {remoteJobData.value.response.empLocation}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="box mb-4" style={{ whiteSpace: 'pre-line'}}>
                                <h3> Propuesta Inicial: </h3> 
                                <p>{remoteJobData.value.response.proposalOriginal}</p>
                            </div>
                            <div className="box mb-4" style={{ whiteSpace: 'pre-line'}}>
                                <h3> Propuesta Empresa: </h3> 
                                <p>{remoteJobData.value.response.proposalResponse}</p>
                            </div>
                            <div className="my-4">
                                <div className="text-center">
                                    <button type="button" 
                                        onClick={(e) => { 
                                            refLoad && refLoad.current && refLoad.current.click();
                                            PostRemoteJobDataAnswer(false) 
                                        }} 
                                        className="btn btn-primary  me-4" >
                                        Rechazar{" "}
                                    </button>
                                    <button type="button" 
                                        onClick={(e) => {
                                            refLoad && refLoad.current && refLoad.current.click();
                                            PostRemoteJobDataAnswer(true) 
                                        }} 
                                        className="btn btn-degradado">
                                        Aprobar{" "}
                                        <i
                                        className="fa fa-chevron-right fa-fw fa-xs"
                                        aria-hidden="true"
                                        ></i>
                                    </button>
                                </div>
                            </div>
                            <button type="button" className="btn btn-link" onClick={() => { navigate("/mis-solicitudes"), window.scrollTo(0, 0) }}>Volver</button>
                        </div>
                    </>
                ): null}
            </>
            ): remoteJobData._tag === 'RemotePending' || remoteJobData._tag === 'RemoteInitial'  ?
                <div className="text-center mt-5">
                    <SpinerFullScreen/>
                    <p className="my-2">Cargando Formulario...</p>
                </div>
            : remoteJobData._tag === 'RemoteFailure' && 
                <div className="text-center mt-5">
                    <p>Ha ocurrido un error al obtener la información, por favor, intente más tarde.</p>
                </div>
        }
        
        <ModalConfirmAction 
            refOpen={refConfirmActionSubmit} 
            data_target={'modalConfirmAction'} 
            text={'¿Estás seguro que desas enviar el formulario?'} 
            callBack={()=>{
                refLoad && refLoad.current && refLoad.current.click();
                PostRemoteJobData(dataPost)
             }}
            />

        <ModalInfoDinamic refOpen={refStatus} icon={dataModal.icon} text={dataModal.msg} subtext={dataModal.submsg} callBack={dataModal.callBack} refClose={refStatusClose}/>
        <ModalLoad text="Cargando información..." refOpen={refLoad} refClose={refLoadClose} data_target={'modalLoadRemoteJob'}/>
    </>
)
}

export default RemoteJobs;
