import { useQuery } from "react-query";
import { getFeatureFlag } from "../services/featureFlagService";

interface ResponseFeatureFlag {
  id: number;
  feature: string;
  country: string;
  isEnabled: boolean;
}

export const useFeatureFlag = (featureFlag: string) => {
  return useQuery<ResponseFeatureFlag>([], () => getFeatureFlag(featureFlag), {
    retry: 0,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    cacheTime: 0,
  });
};
