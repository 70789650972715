import { FilesSolicProps } from "../../RemoteJob/interfaces/interfaces";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { bytesToMB } from "../../../../services/utiles";

const FilesRemoteJob: FC<FilesSolicProps> = ({
  input,
  control,
  formState,
  setTotalFiles,
  totalFiles,
  fileInputRef,
  setValue,
}) => {
  const handleFileChange = async (e: any, field: any, key: string) => {
    const files = e.target.files[0];
    let totalSize = files?.size;
    if (files) {
      setTotalFiles((prevState) => ({ ...prevState, [key]: totalSize }));
      field.onChange(files);
    } else {
      setTotalFiles((prevDependencies) => {
        const updatedFiles = { ...prevDependencies };
        delete updatedFiles[key];
        return updatedFiles;
      });
      if (setValue) {
        setValue(key, "");
      }
    }
  };

  return (
    <>
      <div className="label-wrapper w-sm-70 ms-2">
        <div className="col">
          <Controller
            name={input.key}
            control={control}
            render={({ field }) => (
              <>
                <input
                  id={input.key}
                  name={field.name}
                  type={input.type}
                  onChange={(e) => {
                    handleFileChange(e, field, input.key);
                  }}
                  ref={(el) => {
                    if (fileInputRef?.current) {
                      fileInputRef.current[input.key] = el;
                    }
                  }}
                  accept={".pdf"}
                  className="form-control"
                />
              </>
            )}
          />
          {totalFiles && totalFiles[input.key] && (
            <p>
              {" "}
              Peso de archivo:{" "}
              {Number(totalFiles[input.key])
                ? bytesToMB(Number(totalFiles[input.key]))
                : 0}{" "}
              MB.
            </p>
          )}

          {formState.errors[input.key] &&
          formState.errors[input.key]?.message ? (
            <div className="invalid-feedback d-block">
              {formState.errors[input.key]?.message}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default FilesRemoteJob;
