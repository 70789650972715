import { urlsAws } from "../../resources/foo/api-endpoints"
import { DocumentTrustProps } from "./interfaces"

const validateDocumentTrust = async ({ token, captcha }:{ token:string; captcha:string }) : Promise<DocumentTrustProps> => {
    const requestOptions = {
        method: 'GET',
    }
    const response = await fetch(`${urlsAws.documentTrust}?token=${token}&captcha=${captcha}`, requestOptions)
    
    if (!response.ok) {
        const errorResponse = await response.json();
        const errorMessage = errorResponse.message || 'Error al intentar validar el documento';
        throw new Error(errorMessage);
    }
    
    return response.json();
}


export { validateDocumentTrust }