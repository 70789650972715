import { Children, FC, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import AlertInfo from "../../../../commonComponents/AlertInfo";
import { VALIDATIONS_FUNCTIONS } from "../helpers/validationSchemas";
import * as yup from "yup";
import {
  InputsProps,
  ResultObject,
} from "../../../RemoteJob/interfaces/interfaces";
import TextInput from "../../Inputs/textInput";
import TextAreaInput from "../../Inputs/textAreaInput";
import SelectInput from "../../Inputs/selectInput";
import RadioInput from "../../Inputs/radioInput";
import FilesRemoteJob from "../../Inputs/fileInput";
import { useSectionsStore } from "../../../Bonuses/store/storeBonuses";
import { useNavigate } from "react-router-dom";
import { FILE_TOTAL_MB_SIZE_LIMIT } from "../constant/global";
import { bytesToMB } from "../../../../../services/utiles";
import { RequestFormProps, Section } from "../interfaces";
import Tippy from "@tippyjs/react";
import DateInput from "../../Inputs/dateInput";
import { useFormStore } from "../../Stores/formStore";

const RequestForm: FC<RequestFormProps> = ({ sections }) => {
  const [totalFiles, setTotalFiles] = useState<InputsProps>({});
  const fileSizeLimit = 10 * 1024 * 1024; // 10 MB
  const [totalSize, setTotalSize] = useState(0);
  const [dependencies, setDependencies] = useState<InputsProps>({});
  const {
    setSections,
    sections: sectionsData,
    filterForms,
    resetSections,
  } = useSectionsStore();
  const [sectionsOrig, setSectionOrig] = useState<Section[]>([]);
  const { setDataForm, resetDataForm } = useFormStore();
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  let navigate = useNavigate();

  const {
    handleSubmit,
    control,
    unregister,
    getValues,
    formState,
    reset,
    resetField,
    setError,
    setValue,
  } = useForm<InputsProps>({
    mode: "onSubmit",
    resolver: async (data) => {
      try {
        await validationSchema.validate(data, { abortEarly: false });
        return { values: data, errors: {} };
      } catch (validationErrors: any) {
        const errors = validationErrors.inner.reduce((acc: any, err: any) => {
          acc[err.path] = {
            message: err.message,
          };
          return acc;
        }, {});

        return {
          values: data,
          errors,
        };
      }
    },
  });
  const fileInputRefs = useRef<{ [key: string]: HTMLInputElement }>({});

  const validationSchema = yup.object().shape(
    sectionsData.reduce((accumulator: any, section: any) => {
      section.forms.forEach((field: any) => {
        if (VALIDATIONS_FUNCTIONS[field.type] && field.validations) {
          accumulator[field.key] = VALIDATIONS_FUNCTIONS[field.type](
            field.validations
          );
        }
      });
      return accumulator;
    }, {} as { [key: string]: yup.Schema<any, any, any, any> })
  );

  const onSubmit = async () => {
    let data: InputsProps = getValues();
    const fileMetadata: any[] = [];
    const formData = new FormData();
    let isEmpty = false;
    sectionsData.forEach((item: Section) => {
      item.forms.forEach((form) => {
        const { key, type } = form;
        if (type !== "file") {
          formData.append(key, data[key] || "");
        }
      });
    });
    sectionsOrig.forEach((item: Section) => {
      item.forms.forEach((form) => {
        const { key, promptValue, type } = form;
        if (!formData.has(key) && promptValue !== "" && type !== "file") {
          formData.append(key, promptValue);
        }
      });
    });

    sectionsData.forEach((item: Section) => {
      item.forms.forEach((form) => {
        const { key, promptParameters = [], documentId = "", label } = form;
        if (!data[key]) {
          isEmpty = true;
          setError(key, { message: "Este campo es requerido" });
          return {};
        } else if (form.type === "file") {
          const paramPrompt = promptParameters
            .sort((a, b) => a.order - b.order)
            .map((param) =>
              param.isConstant
                ? param.value
                : formData.get(param.key)
                ? String(formData.get(param.key))
                : ""
            )
            .filter((value) => value !== "");

          formData.append(key, data[key] as any);
          fileMetadata.push({
            fieldName: key,
            documentId,
            fileDescr: label,
            parameters: paramPrompt,
          });
        }
      });
    });

    if (fileMetadata.length > 0 && !isEmpty) {
      formData.append("fileMetadata", JSON.stringify(fileMetadata));
      setDataForm(formData);
    }
  };

  const handleDropdownChange = (key: string, value: string) => {
    setDependencies((prevDependencies) => ({
      ...prevDependencies,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (Object.keys(dependencies).length === 0) {
      setSectionOrig(sections);
    }

    if (sections.length > 0) {
      const filteredSections = filterForms(sections, dependencies);
      filteredSections.forEach((section: Section) => {
        section.forms.forEach((form) => {
          const hasDependencies =
            form.dependencies && form.dependencies.length > 0;

          if (hasDependencies) {
            const shouldReset = form.dependencies.some((dependency) => {
              const { dependencyKey } = dependency;
              return dependencies[dependencyKey] !== undefined;
            });

            if (shouldReset && !getValues(form.key)) {
              resetField(form.key);
              setValue(form.key, "");
            }
          }

          if (form.defaultValue && !getValues(form.key)) {
            setValue(form.key, form.defaultValue);
          }

          if (form.type === "file") {
            resetField(form.key);
            Object.values(fileInputRefs.current).forEach((inputRef) => {
              if (inputRef) {
                inputRef.value = "";
              }
            });
            setValue(form.key, "");
          }
        });
      });

      setTotalSize(0);
      setTotalFiles({});
      setSections(filteredSections);
    }
  }, [sections, dependencies]);

  useEffect(() => {
    if (sectionsData.length > 0) {
      const sectionCopy: Section[] = sectionsData;
      let data: InputsProps = getValues();
      const currentKeys = Object.keys(data);
      const objForm = sectionCopy.reduce((acc: ResultObject, section) => {
        section.forms.forEach((form) => {
          acc[form.key] = {};
        });
        return acc;
      }, {});

      currentKeys.forEach((key) => {
        if (!(key in objForm)) {
          unregister(key);
          setDependencies((prevDependencies) => {
            const updatedFiles = { ...prevDependencies };
            delete updatedFiles[key];
            return updatedFiles;
          });
        }
      });
    }
  }, [sectionsData]);

  useEffect(() => {
    if (totalFiles) {
      const valuesArray = Object.values(totalFiles);
      const keysFiles = Object.keys(totalFiles);
      if (valuesArray.length > 0) {
        const validKeys = keysFiles.filter((key) =>
          sectionsData.some((section: Section) =>
            section.forms.some((form) => {
              return form.key === key;
            })
          )
        );
        const total = validKeys.reduce(
          (acc, key) => acc + Number(totalFiles[key]),
          0
        );

        setTotalSize(total);
      }
    }
  }, [totalFiles, sectionsData]);

  useEffect(() => {
    if (totalSize > fileSizeLimit) {
      setDisabledSubmit(true);
    } else {
      setDisabledSubmit(false);
    }
  }, [totalSize]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {Children.toArray(
          sectionsData.map((section: Section) => {
            return (
              <>
                <div className="box mb-4">
                  <h2 className="h4 tit-section">{section.title}</h2>
                  {section.description && (
                    <AlertInfo
                      color="alert-primary"
                      text={section.description}
                    />
                  )}
                  <div className="data-list-box data-list-box-even data-list-box-align-middle data-list-box-form">
                    <ul className="list-column-1 list-unstyled">
                      {section.forms.length > 0 && (
                        <>
                          {Children.toArray(
                            section.forms.map((input, i) => {
                              let required = input.validations.some(
                                (valid) => valid.type === "required"
                              );
                              return (
                                <>
                                  <li
                                    style={{
                                      backgroundColor:
                                        i % 2 === 0 ? "#f2f2f2" : "white",
                                    }}
                                  >
                                    <span
                                      className={
                                        "row w-auto data-list-box__title text-uppercase w-sm-30 mx-1"
                                      }
                                    >
                                      {input.label} {required ? "*" : ""}
                                      {input.tooltip && (
                                        <Tippy content={input.tooltip}>
                                          <i
                                            className="fa fa-info-circle fa-fw fa-lg color-primary"
                                            aria-hidden="true"
                                            data-placement="top"
                                          ></i>
                                        </Tippy>
                                      )}
                                    </span>
                                    {input.type === "date" ? (
                                      <DateInput
                                        control={control}
                                        formState={formState}
                                        input={input}
                                      />
                                    ) : input.type === "text" ||
                                      input.type === "email" ||
                                      input.type === "number" ? (
                                      <TextInput
                                        control={control}
                                        formState={formState}
                                        input={input}
                                      />
                                    ) : input.type === "textarea" ? (
                                      <TextAreaInput
                                        control={control}
                                        formState={formState}
                                        input={input}
                                      />
                                    ) : input.type === "select" ? (
                                      <>
                                        <SelectInput
                                          control={control}
                                          formState={formState}
                                          input={input}
                                          handleFunction={handleDropdownChange}
                                          getValues={getValues}
                                        />
                                      </>
                                    ) : input.type === "radio" &&
                                      input.options.length > 0 ? (
                                      <RadioInput
                                        control={control}
                                        formState={formState}
                                        input={input}
                                      />
                                    ) : (
                                      input.type === "file" && (
                                        <FilesRemoteJob
                                          control={control}
                                          formState={formState}
                                          input={input}
                                          setTotalFiles={setTotalFiles}
                                          totalFiles={totalFiles}
                                          fileInputRef={fileInputRefs}
                                          setValue={setValue}
                                        />
                                      )
                                    )}
                                  </li>
                                  {i === section.forms.length - 1 &&
                                    input.type === "file" && (
                                      <>
                                        {totalSize > fileSizeLimit && (
                                          <p className="link-danger h6 mt-2">
                                            {" "}
                                            Se ha excedido el límite de peso de{" "}
                                            {bytesToMB(
                                              FILE_TOTAL_MB_SIZE_LIMIT
                                            )}{" "}
                                            MB.
                                          </p>
                                        )}
                                      </>
                                    )}
                                </>
                              );
                            })
                          )}
                          {section.forms.some((obj) => obj.type === "file") && (
                            <p className="mt-2">
                              {" "}
                              Peso total de archivos:{" "}
                              {Number(totalSize)
                                ? bytesToMB(Number(totalSize))
                                : 0}{" "}
                              MB.
                            </p>
                          )}
                        </>
                      )}
                    </ul>
                  </div>
                  <small>
                    {section.forms.some((form) =>
                      form.validations.some(
                        (valid) => valid.type === "required"
                      )
                    )
                      ? "(*) Campo obligatorio"
                      : ""}
                  </small>
                </div>
              </>
            );
          })
        )}
        <div className="d-flex justify-content-between align-items-center">
          <button
            onClick={() => {
              navigate(-1);
              resetDataForm();
              resetSections();
              reset();
            }}
            type="button"
            className="btn btn-link"
          >
            Volver
          </button>
          <button
            type="submit"
            className="btn btn-degradado"
            disabled={
              Object.keys(formState.errors).length > 0 || disabledSubmit
            }
          >
            Enviar
            <i
              className="fa fa-chevron-right fa-fw fa-xs"
              aria-hidden="true"
            ></i>
          </button>
        </div>
      </form>
    </>
  );
};
export default RequestForm;
