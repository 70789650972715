import { FC } from "react";
import Footer from "../elements/footer";
import Header from "../elements/header";
import { useDocumentTrust } from "./hook";
import ReCAPTCHA from "react-google-recaptcha";
import { base64ToBlob } from "../../services/utiles";
import LoadingModal from "../commonComponents/LoadingModal";

const DocumentTrust: FC = () => {
  const {
    token,
    documentData,
    errorMessage,
    handleInputChange,
    handleValidateButtonPress,
    captchaValidated,
    setCaptchaValidated,
    loadingModalOpenend,
    recaptchaKey,
    isButtonDisabled,
    recaptchaRef,
    handleClear
  } = useDocumentTrust();

  return (
    <div className="d-flex flex-column flex-nowrap space-between" style={{ height: "calc(100vh - 32px)" }}>
      <Header />
      <main id="main" className="wrapper no-menu">
        <div id="content" className="container">
          <h1>Validación de Documentos</h1>
          <div className="box mb-4 pb-5">
            <h2 className="h4 tit-section">Validar</h2>
            <div className="row align-items-end justify-content-center">
              <div className="col-9 col-md-9 col-xl-10 mb-3">
                <label className="form-label">Código</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ingresar Código"
                  value={token}
                  onChange={handleInputChange}
                />
              </div>
              <div className="text-center col-3 col-md-2 col-xl-2 mb-3 align-content-end">
                <button 
                  className="btn btn-degradado" 
                  onClick={handleValidateButtonPress}
                  disabled={isButtonDisabled}
                >
                  Validar
                </button>
              </div>
            </div>
            <div className="d-flex items-center justify-content-center mt-2">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={recaptchaKey}
                onChange={(value) => setCaptchaValidated(value ?? "")}
                hl="es"
                size="normal"
                theme="light"
              />
            </div>
            {errorMessage && 
              <div className="text-center mt-3">
                <div className="alert alert-danger alert-dismissible fade show d-inline-block mb-4" role="alert">
                  {errorMessage}
                </div>
              </div>
            }
          </div>
          {documentData && (
            <>
              <div className="text-center">
                { documentData.isDocumentValid ?
                  <div className="alert alert-success alert-dismissible fade show d-inline-block mb-4" role="alert">
                    <i className="fa fa-check-circle-o fa-fw fa-lg" aria-hidden="true"></i>
                    Documento válido. Por favor, verifique la validez de los datos
                    en el documento generado.
                  </div>
                  :
                  <div className="alert alert-warning alert-dismissible fade show d-inline-block mb-4" role="alert">
                    <i className="fa fa-exclamation-circle fa-fw fa-lg" aria-hidden="true"></i>
                    Documento caducado.
                  </div>
                }
              </div>
              <div className="row">
                <div className="col-lg-4 mb-4">
                  <div className="box">
                    <h2 className="h4 tit-section">Detalle</h2>
                    <div className="table-responsive">
                      {documentData.data.map((item, index) => (
                        <table key={index} className="table table-section table-borderless align-middle table-even mb-4">
                          <thead>
                            <tr>
                              <th className="bg-primary">
                                <span className="color-white">{item.header}</span>
                              </th>
                              <th className="bg-primary">
                                <span className="color-white"></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.rows.map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                <td className={"w-50"}>
                                  <span className="color-black fw-bold">
                                    {row.label}
                                  </span>
                                </td>
                                <td>{row.value}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 mb-4">
                  <div className="box h-100">
                    {documentData.document && (
                      <iframe
                        title="Document Preview"
                        src={URL.createObjectURL(base64ToBlob(documentData.document, "application/pdf"))}
                        style={{ width: "100%", height: "100%", border: "none" }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button 
                  className="btn btn-degradado mt-2 justify-self-end" 
                  onClick={()=> {handleClear(recaptchaRef)}}
                >
                  Limpiar
                </button>
              </div>
            </>
          )}
        </div>
        <LoadingModal
          isOpen={loadingModalOpenend}
          text="Validando documento..."
        />
      </main>
      <Footer />
    </div>
  );
};

export default DocumentTrust;
