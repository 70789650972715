import { FC } from "react";

interface ModalLoadProps {
    text?: string;
    isOpen: boolean;
    id?: string;
}

const ModalLoad: FC<ModalLoadProps> = ({ text = "", isOpen, id }) => {
    return (
        <>
            <div
                className={`modal fade ${isOpen ? 'show' : ''}`}
                id={id ? `${id}` : "modalLoad"}
                aria-labelledby="modalLoad"
                aria-hidden={!isOpen}
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                style={{ display: isOpen ? 'block' : 'none' }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body text-center py-4">
                            <div className="text-center">
                                <span className="spinner-border spinner-border-sm" />
                                <p>{text || 'Cargando información...'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && <div className="modal-backdrop fade show"></div>}
        </>
    );
};

export default ModalLoad;
