import { Link } from "react-router-dom";
import { TypeWithKey, lastFourYears } from "../../utils";
import { useTeamDashboardProvider } from "./context/TeamDashboardContext";
import { useTableProvider } from "./context/DashboardTableContext";
import { ChangeEvent } from "react";
import { managmentPayRoll } from "./context/carouselList.data";
import { DistributionChart } from "./_components/compensations/DistributionChart";
import { CompensationsResponse, PerformanceEvaluationResponse } from "./hooks";
import { PerformanceChart } from "./_components/performanceEvaluation/PerformanceChart";
export type DashboardTableLayoutProps = {
  children: JSX.Element;
};

export const DashboardTableLayout = (props: DashboardTableLayoutProps) => {
  const { children } = props;
  const { disabledSelectYear, enabledFilterCountry, getSelectedMenu } =
    useTeamDashboardProvider();
  const {
    titleSection,
    teamSelectOptions,
    onChangeTeamSelect,
    tableFetchInfo,
    teamSelect,
    showLeaders,
    idSubLeader,
    setIdSubLeader,
    handleYearChange,
    handleDownloadFile,
    onChangeFlagSelect,
  } = useTableProvider();

  const onChangeSubLeader = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setIdSubLeader(value);
  };

  const layoutInfo = tableFetchInfo.find((fetch) =>
    !getSelectedMenu
      ? fetch.key === managmentPayRoll.name
      : fetch.key === getSelectedMenu.name
  );

  const chartsMenus: TypeWithKey<JSX.Element> = {
    compensations: (
      <DistributionChart
        isLoading={layoutInfo?.response.isLoading}
        chartInfo={layoutInfo?.response.dataTable as CompensationsResponse}
      />
    ),

    performanceEvaluation: (
      <PerformanceChart
        isLoading={layoutInfo?.response.isLoading}
        chartInfo={
          layoutInfo?.response.dataTable as PerformanceEvaluationResponse
        }
      />
    ),
  };

  if (getSelectedMenu?.hideLayout) return children;

  return (
    <>
      {getSelectedMenu && chartsMenus[getSelectedMenu.name]}
      <section className="mi-equipo">
        <div className="box mb-4">
          <section className="m-0">
            <div className="tit-group d-md-flex justify-content-between mb-4 mb-md-0">
              <div className="d-flex align-items-center">
                <h4 className="text-primary fw-bold header-card tit">
                  {titleSection}
                </h4>
                <div className="line" />

                <div className="ms-2">
                  <i
                    className="fa fa-info-circle fa-fw fa-lg color-primary mb-3"
                    aria-hidden="true"
                    title="Las evaluaciones mostradas de Clima, TR y Evaluación de desempeño, corresponden al último período evaluado del colaborador."
                    data-placement="top"
                  ></i>
                </div>
              </div>

              <div className="d-flex flex-wrap justify-content-end">
                {enabledFilterCountry && (
                  <div className="col-5 col-md-auto mb-3 me-2">
                    <div className="input-group">
                      <select
                        className="form-select w-100"
                        onChange={onChangeFlagSelect}
                        placeholder="Seleccione un país"
                      >
                        <option disabled selected>
                          Seleccione un país
                        </option>
                        {layoutInfo?.response.getAllFlags.map((flag) => (
                          <option key={flag.id} value={flag.name}>
                            {flag.name}
                          </option>
                        ))}
                        <option value={0}>Mostrar todos</option>
                      </select>
                    </div>
                  </div>
                )}
                <div className="col-5 col-md-auto mb-3 me-2">
                  <div className="input-group">
                    <select
                      className="form-select w-100"
                      onChange={onChangeTeamSelect}
                      value={teamSelect}
                    >
                      <option disabled selected>
                        Seleccione
                      </option>
                      {teamSelectOptions.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {showLeaders && (
                  <div className="col-5 col-md-auto mb-3 me-2">
                    <div className="input-group">
                      <select
                        className="form-select"
                        onChange={onChangeSubLeader}
                      >
                        <option disabled selected>
                          Seleccione
                        </option>
                        {idSubLeader &&
                          layoutInfo?.response.dataTable?.map((item, index) => (
                            <option key={index} value={item.nro_document}>
                              {item.name} {item.last_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                )}
                {getSelectedMenu?.showSelectYear && (
                  <div className="col-5 col-md-auto mb-3 me-2">
                    <div className="input-group">
                      <select
                        className="form-select w-100"
                        disabled={disabledSelectYear}
                        onChange={handleYearChange}
                      >
                        {lastFourYears.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
                <div className="col-5 col-md-auto mb-3 me-2">
                  <div className="input-group">
                    <Link to="#" onClick={handleDownloadFile}>
                      Descargar XLS
                      <i
                        className="fa fa-file-excel-o fa-lg"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {children}
        </div>
      </section>
    </>
  );
};
