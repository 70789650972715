import { useEffect, useState } from "react";
import DetalleCargo from "./detalle-cargo";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import DatosPersonalesServices from "../../services/datos personales col/datos-personales-services";
import { toBase64 } from "../../services/utiles";
import styles from "../../resources/css/style-COL.module.css";

export default function ModificarDatosPersonalesCOL({
  reloadPersonalData,
  changeDisplay,
  countries,
  bloodType,
  education,
  genre,
  idioma,
  marStatus,
  info,
}) {
  const [states, setStates] = useState([]);
  const [loadingStates, setLoadingStates] = useState(false);
  const [counties, setCounties] = useState([]);
  const [loadingCounties, setLoadingCounties] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [loadingEditInfo, setLoadingEditInfo] = useState(false);
  const [noEditData, setNoEditData] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [submitDoc, setSubmitDoc] = useState(false);
  const [submitCert, setSubmitCert] = useState(false);
  const [files, setFiles] = useState({
    ATTACH_DOC_NAME: null,
    ATTACH_DOC_FILE: null,
    ATTACH_CERT_NAME: null,
    ATTACH_CERT_FILE: null,
  });

  const [error, setError] = useState(null);
  const [ok, setOk] = useState(null);

  const onSubmit = async (data) => {
    if (info.BIRTHDATE !== data.BIRTHDATE && files.ATTACH_DOC_NAME === null) {
      setSubmitDoc(true);
      return;
    }
    if (
      info.HIGHEST_ED_LVL !== data.HIGHEST_ED_LVL &&
      files.ATTACH_CERT_NAME === null
    ) {
      setSubmitCert(true);
      return;
    }

    const submit = {
      EMPLID: data.EMPLID,
      MAR_STATUS: data.MAR_STATUS,
      SEX: data.SEX,
      BLOOD_TYPE: data.BLOOD_TYPE,
      LANG_CD: data.LANG_CD,
      BIRTHCOUNTRY: data.BIRTHCOUNTRY,
      BIRTHSTATE: data.BIRTHCOUNTRY === "PE" ? data.BIRTHSTATE : "",
      COUNTY_CD: data.BIRTHCOUNTRY === "PE" ? data.COUNTY_CD : "",
      BIRTHDATE: data.BIRTHDATE,
      HIGHEST_EDUC_LVL: data.HIGHEST_EDUC_LVL,
      ATTACH_DOC_NAME: files.ATTACH_DOC_NAME ? files.ATTACH_DOC_NAME : "",
      ATTACH_DOC_DATA: data.ATTACH_DOC_DATA
        ? await toBase64(data.ATTACH_DOC_DATA[0])
        : "",
      ATTACH_CERT_NAME: files.ATTACH_CERT_NAME ? files.ATTACH_CERT_NAME : "",
      ATTACH_CERT_DATA: data.ATTACH_CERT_DATA
        ? await toBase64(data.ATTACH_CERT_DATA)
        : "",
      BIRTHPLACE: data.BIRTHCOUNTRY === "PE" ? "" : data.BIRTHPLACE,
      CN_COUNTRY: countries.filter((c) => c.country === data.BIRTHCOUNTRY)
        ? countries.filter((c) => c.country === data.BIRTHCOUNTRY)[0].dane
        : "",
    };
    setLoadingEditInfo(true);
    setEditInfo(true);
    await DatosPersonalesServices.editPersonalData(submit, setError, setOk);
  };

  useEffect(() => {
    if (error != null) handleStatusError(error);
  }, [error]);

  useEffect(() => {
    if (ok != null) handleStatusSuccess();
  }, [ok]);

  useEffect(() => {
    reset(info);
  }, []);

  useEffect(() => {
    if (watch("BIRTHCOUNTRY") === "PE") {
      setLoadingStates(true);
      DatosPersonalesServices.GetStates(
        watch("BIRTHCOUNTRY"),
        setStates,
        setLoadingStates
      );
    }
  }, [watch("BIRTHCOUNTRY")]);

  useEffect(() => {
    if (watch("BIRTHCOUNTRY") === "PE") {
      setLoadingCounties(true);
      DatosPersonalesServices.GetCounties(
        watch("BIRTHCOUNTRY"),
        watch("BIRTHSTATE"),
        setCounties,
        setLoadingCounties
      );
    }
  }, [watch("BIRTHSTATE")]);

  useEffect(() => {
    editInfo ? handleStatusSuccess() : null;
  }, [loadingEditInfo]);

  const handleStatusError = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: "center",
      title:
        '<div style="text-align: center; color: red; font-size: 16px"> Error </div>',
      html: `<div style="text-align: center; font-size: 15px"> Se ha producido un error. Intentelo de nuevo.</div>`,
      confirmButtonColor: "#11427C",
    }).then(() => {
      reloadPersonalData();
    });
    setEditInfo(false);
    setLoadingEditInfo(false);
    setNoEditData(false);
  };
  const handleStatusSuccess = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: "center",
      title:
        '<div style="text-align: center; color: rgb(40, 202, 40); font-size: 16px"> Acción realizada </div>',
      html: '<div style="text-align: center; font-size: 15px"> La acción se realizo correctamente </div>',
      confirmButtonColor: "#11427C",
    }).then(() => {
      reloadPersonalData();
    });
    setEditInfo(false);
    setLoadingEditInfo(false);
    setNoEditData(false);
  };
  return (
    <>
      <div className={`${styles["row"]} ${styles["mb-4"]}`}>
        <DetalleCargo />

        {loadingEditInfo ? (
          <div
            className={`${styles["col-12"]} ${styles["col-xl-8"]} ${styles["d-flex"]}`}
          >
            <div
              className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}
            >
              <div className={styles["alert"]} role="alert">
                <i
                  className={`${styles["fa"]} ${styles["fa-spinner"]} ${styles["fa-spin"]} ${styles["color-orange"]} ${styles["fa-fw"]}`}
                  aria-hidden="true"
                ></i>
                <span className={styles["ms-2"]}>
                  Modificando datos personales, por favor aguarde unos
                  instantes...
                </span>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              className={`${styles["col-12"]} ${styles["col-xl-8"]} ${styles["d-flex"]}`}
            >
              <div
                className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}
              >
                <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>
                  Corrección de Datos Personales
                </h2>
                <p>
                  Datos Obligatorios{" "}
                  <span className={styles["color-red"]}>*</span>
                </p>
                <form>
                  {/* <!-- Fila 1 --> */}
                  <div className={styles["row"]}>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                    >
                      <label className={styles["form-label"]}>
                        Número de Documento
                      </label>
                      <input
                        type="text"
                        className={styles["form-control"]}
                        value={info.EMPLID}
                        disabled
                      />
                    </div>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                    >
                      <label className={styles["form-label"]}>Nombre</label>
                      <input
                        type="text"
                        className={styles["form-control"]}
                        value={info.NAME_DISPLAY}
                        disabled
                      />
                    </div>
                  </div>
                  {/* <!-- Fila 2 --> */}
                  <div className={styles["row"]}>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                    >
                      <label className={styles["form-label"]}>
                        Estado Civil
                      </label>
                      <div className={styles["input-group"]}>
                        <select
                          className={styles["select-col"]}
                          {...register("MAR_STATUS", {
                            required: "* Campo obligatorio",
                          })}
                        >
                          {marStatus.map((m) => {
                            return (
                              <option
                                key={m.FIELDVALUE}
                                value={m.FIELDVALUE}
                                selected={m.FIELDVALUE === info.MAR_STATUS}
                              >
                                {m.XLATLONGNAME}
                              </option>
                            );
                          })}
                        </select>
                        <small className={styles["form-error-message"]}>
                          {errors.MAR_STATUS?.message}
                        </small>
                      </div>
                    </div>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                    >
                      <label className={styles["form-label"]}>Género</label>
                      <div className={styles["input-group"]}>
                        <select
                          className={styles["select-col"]}
                          {...register("SEX", {
                            required: "* Campo obligatorio",
                          })}
                        >
                          {genre.map((g) => {
                            return (
                              <option
                                key={g.FIELDVALUE}
                                value={g.FIELDVALUE}
                                selected={g.FIELDVALUE === info.SEX}
                              >
                                {g.XLATLONGNAME}
                              </option>
                            );
                          })}
                        </select>
                        <small className={styles["form-error-message"]}>
                          {errors.SEX?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Fila 3 --> */}
                  <div className={styles["row"]}>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                    >
                      <label className={styles["form-label"]}>
                        Tipo de Sangre
                      </label>
                      <div className={styles["input-group"]}>
                        <select
                          className={styles["select-col"]}
                          {...register("BLOOD_TYPE", {
                            required: "* Campo obligatorio",
                          })}
                        >
                          <option value="" selected>
                            Seleccione
                          </option>
                          {bloodType.map((b) => {
                            return (
                              <option
                                key={b.FIELDVALUE}
                                value={b.FIELDVALUE}
                                selected={b.FIELDVALUE === info.BLOOD_TYPE}
                              >
                                {b.XLATLONGNAME}
                              </option>
                            );
                          })}
                        </select>
                        <small className={styles["form-error-message"]}>
                          {errors.BLOOD_TYPE?.message}
                        </small>
                      </div>
                    </div>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                    >
                      <label className={styles["form-label"]}>
                        Lenguaje Materno
                      </label>
                      <div className={styles["input-group"]}>
                        <select
                          className={styles["select-col"]}
                          {...register("LANG_CD", {
                            required: "* Campo obligatorio",
                          })}
                        >
                          {idioma.map((l) => {
                            return (
                              <option
                                key={l.FIELDVALUE}
                                value={l.FIELDVALUE}
                                selected={l.FIELDVALUE === info.LANG_CD}
                              >
                                {l.XLATLONGNAME}
                              </option>
                            );
                          })}
                        </select>
                        <small className={styles["form-error-message"]}>
                          {errors.LANG_CD?.message}
                        </small>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Fila 4 --> */}
                  <div className={styles["row"]}>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                    >
                      <label className={styles["form-label"]}>
                        País de Nacimiento
                      </label>
                      <div className={styles["input-group"]}>
                        <select
                          className={styles["select-col"]}
                          {...register("BIRTHCOUNTRY", {
                            required: "* Campo obligatorio",
                          })}
                        >
                          {countries.map((c) => {
                            return (
                              <option
                                key={c.country}
                                value={c.country}
                                selected={info.BIRTHCOUNTRY === c.country}
                              >
                                {c.descr}
                              </option>
                            );
                          })}
                        </select>
                        <small className={styles["form-error-message"]}>
                          {errors.BIRTHCOUNTRY?.message}
                        </small>
                      </div>
                    </div>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]} ${
                        styles["mb-3"]
                      } ${
                        watch("BIRTHCOUNTRY") !== "PE" ? styles["d-none"] : null
                      }`}
                    >
                      <label className={styles["form-label"]}>
                        Departamento de Nacimiento
                      </label>
                      <div className={styles["input-group"]}>
                        {!loadingStates ? (
                          <select
                            className={styles["select-col"]}
                            {...register("BIRTHSTATE", {
                              required:
                                watch("BIRTHCOUNTRY") === "PE"
                                  ? "* Campo obligatorio"
                                  : false,
                            })}
                          >
                            {states.map((s) => {
                              return (
                                <option
                                  key={s.state}
                                  value={s.state}
                                  selected={info.BIRTHSTATE === s.state}
                                >
                                  {s.descrshort}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <div>
                            <div
                              className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}
                            ></div>
                          </div>
                        )}
                        <small className={styles["form-error-message"]}>
                          {errors.BIRTHSTATE?.message}
                        </small>
                      </div>
                    </div>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]} ${
                        styles["mb-3"]
                      } ${
                        watch("BIRTHCOUNTRY") === "PE" ? styles["d-none"] : null
                      }`}
                    >
                      <label className={styles["form-label"]}>
                        Ciudad de Nacimiento
                      </label>
                      <input
                        type="text"
                        className={styles["form-control"]}
                        defaultValue={info.BIRTHPLACE}
                        {...register("BIRTHPLACE", {
                          required:
                            watch("BIRTHCOUNTRY") !== "COL"
                              ? "* Campo obligatorio"
                              : false,
                        })}
                      />
                      <small className={styles["form-error-message"]}>
                        {errors.BIRTHPLACE?.message}
                      </small>
                    </div>
                  </div>
                  {/* <!-- Fila 5 --> */}
                  <div className={styles["row"]}>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]} ${
                        styles["mb-3"]
                      } ${
                        watch("BIRTHCOUNTRY") !== "PE" ? styles["d-none"] : null
                      }`}
                    >
                      <label className={styles["form-label"]}>
                        Ciudad de Nacimiento
                      </label>
                      {!loadingCounties ? (
                        <select
                          className={styles["select-col"]}
                          {...register("COUNTY_CD", {
                            required:
                              watch("BIRTHCOUNTRY") === "PE"
                                ? "* Campo obligatorio"
                                : false,
                          })}
                        >
                          {counties.map((c, key) => {
                            return (
                              <option
                                key={key}
                                value={c.county}
                                selected={info.COUNTY_CD === c.county}
                              >
                                {c.descr}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <div>
                          <div
                            className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}
                          ></div>
                        </div>
                      )}
                      <small className={styles["form-error-message"]}>
                        {errors.COUNTY_CD?.message}
                      </small>
                    </div>
                  </div>
                  {/* <!-- Fila 6 --> */}
                  <div className={styles["row"]}>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                    >
                      <label className={styles["form-label"]}>
                        Fecha de Nacimiento
                      </label>
                      <div className={styles["input-group"]}>
                        <input
                          className={styles["form-control"]}
                          type="date"
                          defaultValue={info.BIRTHDATE}
                          {...register("BIRTHDATE")}
                        />
                      </div>
                      <small className={styles["form-error-message"]}>
                        {errors.BIRTHDATE?.message}
                      </small>
                    </div>
                  </div>
                  {/* <!-- Fila 7 --> */}
                  <div className={styles["row"]}>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                    >
                      <label className={styles["form-label"]}>
                        Adjuntar certificado (Archivos menores a 5Mb)
                      </label>
                      <input
                        type="file"
                        id="file"
                        onChange={(e) => {
                          if (
                            e.target.files[0]?.type === "image/jpeg" ||
                            e.target.files[0]?.type === "image/jpg" ||
                            e.target.files[0]?.type === "application/pdf" ||
                            e.target.files[0]?.type === "image/png"
                          ) {
                            if (e.target.files[0].size <= 5000000) {
                              setFiles({
                                ATTACH_DOC_NAME: e.target?.files[0].name,
                              });
                              setValue("ATTACH_DOC_DATA", e.target?.files, {
                                shouldValidate: true,
                                shouldDirty: true,
                              });
                            } else {
                              Swal.fire({
                                allowOutsideClick: false,
                                position: "center",
                                title:
                                  '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                                html: '<div style="text-align: center; font-size: 15px">El tamaño del Certificado debe ser menor a 5Mb</div>',
                                confirmButtonColor: "#11427C",
                              }).then(() => {
                                setSubmitDoc(true);
                                return;
                              });
                            }
                          } else {
                            Swal.fire({
                              allowOutsideClick: false,
                              position: "center",
                              title:
                                '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                              html: '<div style="text-align: center; font-size: 15px">Debe ingresar uno de los tipos de archivos permitidos</div>',
                              confirmButtonColor: "#11427C",
                            });
                          }
                        }}
                        formcontrolname="file"
                        name="file"
                        accept=".pdf, .jpeg, .jpg, .png"
                        className={styles["form-control"]}
                        aria-label="Adjuntar certificado"
                      />
                      <small className={styles["form-error-message"]}>
                        {files?.ATTACH_DOC_NAME === null && submitDoc
                          ? "* Campo obligatorio"
                          : ""}
                      </small>
                    </div>
                  </div>
                  {/* <!-- Fila 8 --> */}
                  <div className={styles["row-1"]}>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                    >
                      <label className={styles["form-label"]}>
                        Nivel Educativo Titulado
                      </label>
                      <div className={styles["input-group"]}>
                        <select
                          className={styles["select-col"]}
                          {...register("HIGHEST_EDUC_LVL")}
                        >
                          {education.map((b) => {
                            return (
                              <option
                                key={b.id}
                                value={b.FIELDVALUE}
                                selected={b.FIELDVALUE === info.HIGHEST_ED_LVL}
                              >
                                {b.XLATLONGNAME}
                              </option>
                            );
                          })}
                        </select>
                        <small className={styles["form-error-message"]}>
                          {errors.HIGHEST_EDUC_LVL?.message}
                        </small>
                      </div>
                    </div>
                    <div
                      className={`${styles["col-12"]} ${styles["col-md-6"]} ${styles["mb-3"]}`}
                    >
                      <div
                        className={`${styles["col-12"]} ${styles["col-md-12"]}`}
                      >
                        <label className={styles["form-label"]}>
                          Adjuntar certificado (Archivos menores a 5Mb)
                        </label>
                        <input
                          type="file"
                          id="file2"
                          className={styles["form-control"]}
                          aria-label="Adjuntar certificado"
                          onChange={(e) => {
                            if (
                              e.target.files[0]?.type === "image/jpeg" ||
                              e.target.files[0]?.type === "image/jpg" ||
                              e.target.files[0]?.type === "application/pdf" ||
                              e.target.files[0]?.type === "image/png"
                            ) {
                              if (e.target.files[0].size <= 5000000) {
                                setFiles({
                                  ATTACH_CERT_NAME: e.target?.files[0].name,
                                });
                                setValue(
                                  "ATTACH_CERT_DATA",
                                  e.target?.files[0],
                                  { shouldValidate: true, shouldDirty: true }
                                );
                              } else {
                                Swal.fire({
                                  allowOutsideClick: false,
                                  position: "center",
                                  title:
                                    '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                                  html: '<div style="text-align: center; font-size: 15px">El tamaño del Certificado debe ser menor a 5Mb</div>',
                                  confirmButtonColor: "#11427C",
                                }).then(() => {
                                  setSubmitCert(true);
                                  return;
                                });
                              }
                            } else {
                              Swal.fire({
                                allowOutsideClick: false,
                                position: "center",
                                title:
                                  '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                                html: '<div style="text-align: center; font-size: 15px">Debe ingresar uno de los tipos de archivos permitidos</div>',
                                confirmButtonColor: "#11427C",
                              });
                            }
                          }}
                          formcontrolname="file2"
                          name="file2"
                          accept=".pdf, .jpeg, .jpg, .png"
                        />
                        <small className={styles["form-error-message"]}>
                          {files?.ATTACH_CERT_NAME === null && submitCert
                            ? "* Campo obligatorio"
                            : ""}
                        </small>
                      </div>
                    </div>
                  </div>
                  {/* <!-- fin filas --> */}
                </form>
              </div>
            </div>
          </>
        )}
      </div>
      {editInfo ? (
        <></>
      ) : (
        <div
          className={`${styles["row"]} ${styles["justify-content-end"]} ${styles["mb-4"]}`}
        >
          <div className={`${styles["col-6"]} ${styles["col-lg-auto"]}`}>
            {/* <!-- Quitar disabled para volver activar boton --> */}
            <button
              type="button"
              className={`${styles["btn"]} ${styles["btn-primary"]} ${styles["w-100"]}`}
              onClick={() => changeDisplay(false)}
            >
              Cancelar{" "}
              <i
                className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                aria-hidden="true"
              ></i>
            </button>
          </div>
          <div className={`${styles["col-6"]} ${styles["col-lg-auto"]}`}>
            <button
              type="submit"
              className={`${styles["btn"]} ${styles["btn-degradado"]} ${styles["w-100"]}`}
              onClick={handleSubmit(onSubmit)}
            >
              Guardar{" "}
              <i
                className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`}
                aria-hidden="true"
              ></i>
            </button>
          </div>
        </div>
      )}
      {noEditData ? handleStatusError() : null}
    </>
  );
}
