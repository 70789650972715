import { useQuery } from "react-query";
import { getBonus } from "../services/apiRequest";
import { ResponseBonus, typeBonus } from "../interfaces/interfaces";
import { defaultQuerys } from "./defaultQuerys";

export const useBonusGetQuery = (type: string, parameters: string) => {
  return useQuery<ResponseBonus>(
    [parameters? `task-bonus-${type}-${parameters}` : `bonus-${type}`],
    () => getBonus(parameters),
    defaultQuerys
  );
};
