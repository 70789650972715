import { Bar } from "react-chartjs-2";
import { useProfileSummaryProvider } from "../../context/EmployeeProfileSummaryContext";

export const GraphicVacation = () => {
  const { summaryEmployee } = useProfileSummaryProvider();

  const currentYear = new Date().getFullYear();
  const formatYear = (date?: string) => {
    if (date) {
      const year = date.split("-")[0];
      return parseInt(year, 10);
    }
    return 0
  }
  const filteredVacations = 
  summaryEmployee.data.vacations?.filter(
    (vacation) => formatYear(vacation?.period) >= currentYear - 5
  );
  
  const data = {
    labels: filteredVacations.map((vacation) => vacation.period.split("-")[0]),
    datasets: [
      {
        label: "Días Arrastre",
        backgroundColor: "rgba(255,99,132,0.2)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: summaryEmployee.data.vacations.map((vacation) =>
          parseInt(vacation.previousPeriodCarryoverDays, 10)
        ),
      },
      {
        label: "Días Derecho",
        backgroundColor: "rgba(54,162,235,0.2)",
        borderColor: "rgba(54,162,235,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(54,162,235,0.4)",
        hoverBorderColor: "rgba(54,162,235,1)",
        data: summaryEmployee.data.vacations.map((vacation) =>
          parseInt(vacation.normalDays, 10)
        ),
      },
      {
        label: "Días Tomados",
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75,192,192,0.4)",
        hoverBorderColor: "rgba(75,192,192,1)",
        data: summaryEmployee.data.vacations.map((vacation) =>
          parseInt(vacation.daysTaken, 10)
        ),
      },
    ],
  };

  if (summaryEmployee.isLoading) return <></>;

  return (
    <div className="col-12 col-xl-12 d-flex">
      <div className="box align-items-stretch w-100">
        <h2 className="h4 tit-section">Gráfico Vacaciones</h2>
        <div className="data-list-box data-list-box-even text-uppercase">
          <Bar
            data={data}
          />
        </div>
      </div>
    </div>
  );
};
