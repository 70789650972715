import { FC } from "react";
import { Controller } from "react-hook-form";
import { InputForm } from "../../RemoteJob/interfaces/interfaces";

const TextAreaInput:FC<InputForm> = ({formState, input, control}) => {

    return(
        <>
            <span className="col">
            <Controller
                name={input.key}
                control={control}
                defaultValue={input.defaultValue || ''}
                render={({ field }) => (
                    <>
                        <textarea
                            {...field}
                            rows={4}
                            name={input.key}
                            className="form-control"
                            readOnly={input.readOnly}
                            onChange={e => field.onChange(e)}>
                        </textarea>
                    </>
                )}
            />
            {formState.errors[input.key] && formState.errors[input.key]?.message ? (
                <div className="invalid-feedback d-block">
                    {formState.errors[input.key]?.message}
                </div>
            ) : null}
            </span>
        </>
    )
}

export default TextAreaInput;