import React, { useEffect, useState } from "react";
import { RemoteData } from "@devexperts/remote-data-ts";
import * as RD from "@devexperts/remote-data-ts";
import { HttpError } from "../../../../services/apiServices/interfaces";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import { httpClientEnv } from "../../../../services/apiServices/httpClient";
import { getNewsByID, getNewsCard, getItemsCache, getRemotePhotosNewsList, existsPhotoIds, likeNews, handleCacheNewsList, getFileByID, crudCommentNews } from "../../utilities/apiServices/apiRequest";
import { Link, useNavigate, useParams } from "react-router-dom";
import { downloadBase64, removeFileExt, scrollToTop, useCarousel } from "../../../../services/utiles";
import { newsListCards, GetNewsByIDProps, PostNews, GetNewsCard, GetPhotoNews, PhotoNewsProps, GetFileNews, typeLinks, NewsComments, GetCommentNews } from "../../utilities/interfaces";
import { SpinerFullScreen } from "../../../commonComponents/SpinerFullScreen";
import { urlsAws } from "../../../../resources/foo/api-endpoints";
import { Share } from "../../../commonComponents";
import Slider from "../../../commonComponents/Slider";
import moment from "moment";

const transformDataForSlider = (data: typeLinks[]) => {
    return data.map((video, i) => (
        <div key={i} className="col-12 col-md-4">
            <div className="ratio ratio-16x9 mb-4">
                <iframe className="embed-responsive-item" src={video.url}></iframe>
            </div>
        </div>
    ));
};

const NewsDetail = () => {
    const idParam = useParams();
    const [remoteDataNewsByID, setRemoteDataNewsByID] = useState<RemoteData<HttpError, GetNewsByIDProps>>(RD.initial);
    const [totalLike, setTotallike] = useState(0);
    const [disabledLike, setDisabledLike] = useState(true);
    const [ownLike, setOwnLike] = useState(false);
    const [dataLike, setDataLike] = useState<RemoteData<HttpError, PostNews>>(RD.initial);
    const [relatedNewsList, setRelatedNewsList] = useState<RemoteData<HttpError, GetNewsCard>>(RD.initial);
    const [relatedNewsListCopy, setRelatedNewsListCopy] = useState<RemoteData<HttpError, GetNewsCard>>(RD.initial);
    const [imgCard, setImgCard] = useState('');
    const [remoteDataPhotoRelated, setRemoteDataPhotoRelated] = useState<RemoteData<HttpError, GetPhotoNews>>(RD.initial);
    const [remoteDataPhoto, setRemoteDataPhoto] = useState<RemoteData<HttpError, GetPhotoNews>>(RD.initial);
    const [remoteDataFile, setRemoteDataFile] = useState<RemoteData<HttpError, GetFileNews>>(RD.initial);
    const [isDataRelated, setIsDataRelated] = useState(false);
    const [shareActive, setShareActive] = useState<boolean>(false);
    const [commentActive, setCommentActive] = useState<boolean>(false);
    const [newComment, setNewComment] = useState("")
    const [remoteDataComment, setRemoteDataComment] = useState<NewsComments[]>([]);
    const [remoteDataDeleteCommentNews, setRemoteDataDeleteCommentNews] = useState<RemoteData<HttpError, GetCommentNews>>(RD.initial);
    const [remoteDataNewCommentNews, setRemoteDataNewCommentNews] = useState<RemoteData<HttpError, GetCommentNews>>(RD.initial);

    const location = window.location.href;
    let navigate = useNavigate();

    const GetNewsByID = async (idBenef: number) => {
        setRemoteDataNewsByID(RD.pending)
        const dataNewsByID = await getItemsCache(urlsAws.crudNews + "?id=" + idBenef);
        if (dataNewsByID) {
            setRemoteDataNewsByID(dataNewsByID);
            setOwnLike(dataNewsByID.value.response.news.own_like === 1 ? true : false)
            setTotallike(dataNewsByID.value.response.news.total_likes)
            setRemoteDataComment(dataNewsByID.value.response.news.news_comments)
        } else {
            RTE.run(await getNewsByID(idBenef, true), httpClientEnv)
                .then(E.fold(e => { return setRemoteDataNewsByID(RD.failure(e)) }, a => {
                    let data = RD.success(a)
                    if (data._tag === 'RemoteSuccess') {
                        setOwnLike(data.value.response.news.own_like === 1 ? true : false)
                        setTotallike(data.value.response.news.total_likes)
                        handleCacheNewsList(urlsAws.crudNews + "?id=" + idBenef, data)
                        setRemoteDataComment(data.value.response.news.news_comments)
                        return setRemoteDataNewsByID(RD.success(a));
                    } else {
                        return setRemoteDataNewsByID(RD.failure({ tag: 'httpRequestError', error: 'length GetNewsByID 0' }));
                    }
                }))
        }
    };

    const GetFileByID = async (idBenef: number) => {
        RTE.run(await getFileByID(idBenef), httpClientEnv)
            .then(E.fold(e => { return setRemoteDataFile(RD.failure(e)) }, a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess') {
                    downloadBase64(removeFileExt(data.value.response.name_file), data.value.response.file);
                    return setRemoteDataFile(RD.success(a));
                } else {
                    return setRemoteDataFile(RD.failure({ tag: 'httpRequestError', error: 'length GetNewsByID 0' }));
                }
            }))

    };

    const setDataRelated = async (temp: RemoteData<HttpError, GetNewsCard>) => {
        let arrayIdsNotCache: number[] = [];
        const dataNews = await Promise.all(
            temp._tag === 'RemoteSuccess' ? temp.value.response.newsList.map(async (news) => {
                const response = await caches.match(urlsAws.getPhotoNews + "?id=" + news.id);
                if (response) {
                    const data = await response.json();
                    if (data.id === news.id) {
                        return { ...news, img_card: data.img_card };
                    } else {
                        return { ...news }
                    }
                } else {
                    arrayIdsNotCache.push(news.id)
                    return news;
                }
            }) : []
        );
        if (arrayIdsNotCache.length > 0) {
            GetPhotoNewsList(arrayIdsNotCache);
        } else if (temp._tag === 'RemoteSuccess') {
            let newNews = dataNews.filter(
                nw => nw.id !== Number(idParam.id),
            );
            temp.value.response.newsList = newNews;
            setRelatedNewsListCopy(temp);
            setIsDataRelated(false);
        }
    };

    const GetPhotoNewsList = (arrayIds: number[]) => {
        if (arrayIds.length > 0) {
            setIsDataRelated(true);
            existsPhotoIds(arrayIds)
                .then((emplidPhoto) => {
                    if (emplidPhoto.length > 0) {
                        getRemotePhotosNewsList(emplidPhoto, setRemoteDataPhotoRelated)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const GetNewsRelated = async (categoryId: number) => {
        const newsListRelated = await getItemsCache(urlsAws.getNewsCard + "?category=" + categoryId);
        if (newsListRelated) {
            setDataRelated(newsListRelated);
        } else {
            RTE.run(await getNewsCard(categoryId), httpClientEnv)
                .then(E.fold(e => { return setRelatedNewsList(RD.failure(e)) }, a => {
                    let data = RD.success(a)
                    if (data._tag === 'RemoteSuccess') {
                        setDataRelated(data)
                        handleCacheNewsList(urlsAws.getNewsCard + "?category=" + categoryId, data)
                        return setRelatedNewsList(RD.success(a));
                    } else {
                        return setRelatedNewsList(RD.failure({ tag: 'httpRequestError', error: 'error GetNewsRelated' }));
                    }
                }))
        }
    };

    const PostLikeNews = async (idBenef: number, like: boolean) => {
        setDataLike(RD.pending)
        const responseBenef = await caches.match(urlsAws.crudNews + "?id=" + idBenef);
        RTE.run(await likeNews({ id: idBenef }, like), httpClientEnv)
            .then(E.fold(e => { return setDataLike(RD.failure(e)) }, async a => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess' && data.value.valid) {
                    setDisabledLike(true);
                    if (responseBenef) {
                        const dataBenef = await responseBenef.json();
                        const updatedItem: GetNewsByIDProps = {
                            ...dataBenef.value.response.news,
                            total_likes: like ? dataBenef.value.response.news.total_likes + 1 : dataBenef.value.response.news.total_likes - 1,
                            own_like: like ? 1 : 0
                        };
                        dataBenef.value.response.news = updatedItem
                        caches.open("api-cache").then((cache) => {
                            cache.put(urlsAws.crudNews + "?id=" + idBenef, new Response(JSON.stringify(dataBenef)))
                        })
                    }
                    return setDataLike(RD.success(a));
                } else {
                    setDisabledLike(false);
                    return setDataLike(RD.failure({ tag: 'httpRequestError', error: 'error PostLikeNews' }));
                }
            }))
    };

    const renderHTML = (rawHTML: any) => (
        <div
            className="render-html-content"
            dangerouslySetInnerHTML={{ __html: rawHTML }}
        />
    );

    const handleLike = async (e: any) => {
        e.preventDefault();
        e.target.disabled = true;
        setDisabledLike(false);
        if (ownLike) {
            PostLikeNews(Number(idParam.id), false)
            setTotallike(totalLike - 1);
        } else {
            PostLikeNews(Number(idParam.id), true);
            setTotallike(totalLike + 1);
        }
        setOwnLike(!ownLike);
    };

    const getImgCard = async (idBenef: number) => {
        const responseBenef = await caches.match(urlsAws.getPhotoNews + "?id=" + idBenef);
        if (responseBenef) {
            const data: PhotoNewsProps = await responseBenef.json();
            setImgCard(data.img_card)
        } else {
            getRemotePhotosNewsList([idBenef], setRemoteDataPhoto, setImgCard)
        }
    }

    const onSaveCommentClick = async () => {
        setRemoteDataNewCommentNews(RD.pending)
        const responseNoticeCache = await caches.match(urlsAws.crudNews + "?id=" + idParam.id);
        let body = {
            idNews: Number(idParam.id),
            comment: newComment
        }

        RTE.run(await crudCommentNews(body, 'POST'), httpClientEnv)
            .then(E.fold(e => { return setRemoteDataNewCommentNews(RD.failure(e)) }, async (a) => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess' && data.value.valid) {
                    if (responseNoticeCache) {
                        const newDataNotice = await responseNoticeCache.json();
                        const newComments = data.value.comments;
                        const updatedItem: GetNewsByIDProps = {
                            ...newDataNotice.value.response.news,
                            news_comments: newComments
                        };
                        newDataNotice.value.response.news = updatedItem;
                        caches.open("api-cache").then((cache) => {
                            cache.put(urlsAws.crudNews + "?id=" + idParam.id, new Response(JSON.stringify(newDataNotice)));
                        });
                    }
                    setNewComment("")
                    setCommentActive(false)
                    setRemoteDataComment(data.value.comments)
                    return setRemoteDataNewCommentNews(RD.success(a))
                } else {
                    return setRemoteDataNewCommentNews(RD.failure({ tag: 'httpRequestError', error: 'Valid deleteNews' }))
                }
            }))
    }


    const onDeleteCommentClick = async (idComment: number) => {
        setRemoteDataDeleteCommentNews(RD.pending)
        const responseNoticeCache = await caches.match(urlsAws.crudNews + "?id=" + idParam.id);
        let body = {
            id: idComment
        }

        RTE.run(await crudCommentNews(body, 'DELETE'), httpClientEnv)
            .then(E.fold(e => {
                return setRemoteDataDeleteCommentNews(RD.failure(e))
            }, async (a) => {
                let data = RD.success(a)
                if (data._tag === 'RemoteSuccess' && data.value.valid) {
                    const commentsWithoutRemovedItem = remoteDataComment.filter(com => com.id !== idComment);
                    if (responseNoticeCache) {
                        const newDataNotice = await responseNoticeCache.json();
                        const updatedItem: GetNewsByIDProps = {
                            ...newDataNotice.value.response.news,
                            news_comments: commentsWithoutRemovedItem
                        };
                        newDataNotice.value.response.news = updatedItem;
                        caches.open("api-cache").then((cache) => {
                            cache.put(urlsAws.crudNews + "?id=" + idParam.id, new Response(JSON.stringify(newDataNotice)));
                        });
                    }
                    setRemoteDataComment(commentsWithoutRemovedItem)
                    return setRemoteDataDeleteCommentNews(RD.success(a))
                } else {
                    return setRemoteDataDeleteCommentNews(RD.failure({ tag: 'httpRequestError', error: 'Valid deleteNews' }))
                }
            }))
    };

    useEffect(() => {
        scrollToTop();
        Number(idParam.id) > 0 && GetNewsByID(Number(idParam.id))
    }, [idParam.id])

    useEffect(() => {
        if (remoteDataNewsByID._tag === 'RemoteSuccess') {
            GetNewsRelated(remoteDataNewsByID.value.response.news.category_id);
            getImgCard(remoteDataNewsByID.value.response.news.id)
        }
    }, [remoteDataNewsByID])

    useEffect(() => {
        if (
            remoteDataPhotoRelated._tag === 'RemoteSuccess' &&
            isDataRelated &&
            relatedNewsList._tag === 'RemoteSuccess'
        ) {
            setTimeout(() => {
                setDataRelated(relatedNewsList);
            }, 1500);
        }
    }, [remoteDataPhotoRelated, isDataRelated, relatedNewsList]);

    useCarousel(10000, 'carousel-noticias-mobile', relatedNewsList._tag === 'RemoteSuccess');

    return (
        <>
            <section className="noticias">
                {remoteDataNewsByID._tag === 'RemoteSuccess' && (
                    <>
                        <nav id="nav" className="breadcrumbs" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/inicio"><i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio</Link></li>
                                <li className="breadcrumb-item"><Link type="button" to="/mis-noticias"><i style={{ pointerEvents: "none" }} aria-hidden="true"></i> Mis Noticias</Link></li>
                                {remoteDataNewsByID && remoteDataNewsByID.value.response.news.title && <li className="breadcrumb-item active" aria-current="page">{remoteDataNewsByID.value.response.news.title}</li>}
                            </ol>
                        </nav>
                    </>
                )}

                <h1>Mis Noticias</h1>
                {remoteDataNewsByID._tag === 'RemoteSuccess' ? (
                    <>
                        <div className="card card-box-mobile card-image-end p-0 mb-3">
                            <div className="row align-items-center g-0">
                                <div className="col-md-6">
                                    <div className="card-body p-4">
                                        <div className="color-gradient-1 fw-bold fs-1 mb-4">
                                            <div>{remoteDataNewsByID.value.response.news.title}</div>
                                        </div>
                                        <p className="fs-4 fw-bold m-0">{remoteDataNewsByID.value.response.news.subtitle}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <img className="img-fluid img-banner-detail" src={imgCard ? imgCard : require('../../../../resources/images/default_img.png')} width="792" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="row w-auto">
                                <div className="col-12 col-md-6">
                                    <p className="fs-5 color-primary"></p>
                                    {remoteDataNewsByID.value.response.isFile && <p className="fs-5 color-primary"><strong>Descargar:</strong> <a className="fw-bold btnLink" onClick={(e) => { GetFileByID(Number(idParam.id)) }}>Archivo adjunto <i className="fa fa-download fa-fw color-primary" aria-hidden="true"></i></a></p>}
                                </div>
                                <div className="col-12 col-md-6 text-md-end">
                                    <div className="mt-3 text-center text-md-end btn-news">
                                        <button className="btn color-primary-strong" onClick={(e) => setCommentActive(!commentActive)}><i className="fa fa-comment-o fa-fw fa-lw color-primary" aria-hidden="true"></i></button>
                                        <button className="btn color-primary-strong"><i id="rojo" onClick={(e) => { disabledLike && handleLike(e); }} style={ownLike ? { color: 'red' } : { color: "#d2d2d2" }} className="fa fa-heart fa-fw fa-lg" aria-hidden="true"></i>  {totalLike} Me gusta</button>
                                        <button className="btn color-primary-strong" onClick={(e) => setShareActive(!shareActive)}><i className="fa fa-paper-plane-o fa-fw color-primary" aria-hidden="true"></i> Compartir</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {commentActive &&
                            <div className="box mb-4">
                                <h2 className="h4 tit-section">Nuevo Comentario</h2>
                                <div>
                                    <textarea className="input-area-comment" rows={4} onChange={(e) => setNewComment(e.target.value)} value={newComment} />
                                    <button className="btn btn-primary" disabled={remoteDataNewCommentNews === RD.pending} onClick={() => onSaveCommentClick()}>Comentar</button>
                                </div>
                            </div>
                        }


                        {remoteDataComment.length > 0 &&
                            <div className="box mb-4">
                                <h2 className="h4 tit-section">Comentarios</h2>
                                {remoteDataComment.map((comment, index) => (
                                    <div className="comment-container">
                                        <div className="head-comment">
                                            <div className="date-comment">{moment(comment.created_at).format("DD/MM/YYYY")}</div>
                                            <div className="cursor-pointer" style={{ cursor: 'pointer' }}>
                                                <i onClick={() => onDeleteCommentClick(comment.id)} className="fa fa-trash-o fa-fw color-orange" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="label-area-comment" key={`${index} ${comment.id}`}>
                                            {comment.comment}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }

                        {remoteDataNewsByID.value.response.news.description &&
                            <div className="box mb-4">
                                <h2 className="h4 tit-section">Descripción</h2>
                                <div className="html-content">{renderHTML(remoteDataNewsByID.value.response.news.description)}</div>
                            </div>
                        }

                        {remoteDataNewsByID.value.response.news.condition &&
                            <div className="box mb-4" style={{ whiteSpace: 'pre-line' }}>
                                <h2 className="h4 tit-section">Condiciones</h2>
                                <div className="html-content">{renderHTML(remoteDataNewsByID.value.response.news.condition)}</div>
                            </div>
                        }

                        {remoteDataNewsByID.value.response.news.videos.length > 0 &&
                            <div className="box mb-5">
                                <h2 className="h4 tit-section">Videos</h2>
                                <Slider
                                    id="videos-noticias"
                                    data={transformDataForSlider(remoteDataNewsByID.value.response.news.videos)}
                                />
                            </div>
                        }
                        {relatedNewsListCopy._tag === 'RemoteSuccess' && relatedNewsListCopy.value.response.newsList.length > 0 ? <div className="mb-4">
                            <h2 className="h4 tit-section">Noticias Relacionados</h2>
                            <div id="carousel-noticias" className="carousel carousel-dark slide carousel-control-inner mb-3 d-none d-md-block" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                    {relatedNewsListCopy.value.response.newsList.reduce((chunks: newsListCards[][], noticia, i) => {
                                        const index = Math.floor(i / 4);
                                        if (!chunks[index]) {
                                            chunks[index] = [];
                                        }
                                        chunks[index].push(noticia);
                                        return chunks;
                                    }, []).map((banner, index) => {
                                        if (banner) {
                                            return <button key={index} type="button" data-bs-target="#carousel-noticias" data-bs-slide-to={index} aria-label={"Slide " + index} className={index === 0 ? "active" : ""} aria-current="true"></button>
                                        }
                                    })
                                    }
                                </div>
                                <div className="carousel-inner">
                                    {relatedNewsListCopy.value.response.newsList.reduce((chunks: newsListCards[][], noticia, i) => {
                                        const index = Math.floor(i / 4);
                                        if (!chunks[index]) {
                                            chunks[index] = [];
                                        }
                                        chunks[index].push(noticia);
                                        return chunks;
                                    }, []).map((news, index) => (
                                        <div className={`carousel-item p-2 ${index === 0 ? 'active' : ''}`} data-bs-interval="10000">
                                            <div className="row row-cols-4">
                                                {news.map((nw) => {
                                                    if (nw.id && nw.id !== Number(idParam.id)) {
                                                        return (
                                                            <div className="col d-flex mx-auto mb-4">
                                                                <article className="d-flex flex-column box-card w-100">
                                                                    <div className="box-card__img">
                                                                        <div className="card-icon card-icon-absolute">
                                                                            <span className="material-symbols-outlined fs-2">
                                                                                <i className={`fa ${nw.category.icon} fa-fw`} aria-hidden="true"></i>
                                                                            </span>
                                                                        </div>
                                                                        <img className="img-fluid img-cards" src={nw.img_card ? nw.img_card : require('../../../../resources/images/default_img.png')} alt="" />
                                                                    </div>
                                                                    <div className="box-card__body d-flex flex-column flex-grow-1">
                                                                        <h3>
                                                                            <a className="color-dark" href={"mis-noticias/" + nw.id}>{nw.title}</a>
                                                                        </h3>
                                                                        <p>{nw.subtitle.length > 40 ? nw.subtitle.substring(0, 40) + '...' : nw.subtitle}</p>
                                                                        <div className="text-end mt-auto">
                                                                            <a className="btn btn-degradado" onClick={() => {
                                                                                navigate("/mis-noticias/" + nw.id);
                                                                            }}>Ver más<i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                                                                        </div>
                                                                    </div>
                                                                </article>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carousel-noticias" data-bs-slide="prev">
                                    <i className="fa fa-chevron-left color-dark" aria-hidden="true"></i>
                                    <span className="visually-hidden">Anterior</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carousel-noticias" data-bs-slide="next">
                                    <i className="fa fa-chevron-right color-dark" aria-hidden="true"></i>
                                    <span className="visually-hidden">Siguiente</span>
                                </button>
                            </div>
                            <div id="carousel-noticias-mobile" className="carousel carousel-dark slide carousel-control-inner d-md-none pointer-event" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                    {relatedNewsListCopy.value.response.newsList.map((banner, index) => {
                                        if (banner.id) {
                                            return <button key={index} type="button" data-bs-target="#carousel-noticias-mobile" data-bs-slide-to={index} aria-label={"Slide " + index} className={index === 0 ? "active" : ""} aria-current="true"></button>
                                        }
                                    })
                                    }
                                </div>
                                <div className="carousel-inner">
                                    {relatedNewsListCopy.value.response.newsList.map((news, i) => {
                                        if (news.id && news.id !== Number(idParam.id)) {
                                            return (
                                                <>
                                                    <div className={`carousel-item p-2 mb-4 ${i === 0 ? 'active' : ''}`} data-bs-interval="10000">
                                                        <div className="row row-cols-1 row-cols-md-2 row-cols-xl-2 row-cols-xxl-4 g-4 mb-2 w-auto">
                                                            <div className="col d-flex">
                                                                <article className="d-flex flex-column box-card w-100">
                                                                    <div className="box-card__img">
                                                                        <div className="card-icon card-icon-absolute">
                                                                            <span className="material-symbols-outlined fs-2">
                                                                                <i className={`fa ${news.category.icon} fa-fw`} aria-hidden="true"></i>
                                                                            </span>
                                                                        </div>
                                                                        <img className="img-fluid img-cards" src={news.img_card ? news.img_card : require('../../../../resources/images/default_img.png')} alt="" />
                                                                    </div>
                                                                    <div className="box-card__body d-flex flex-column flex-grow-1">
                                                                        <h3><a className="color-dark" href="#">{news.title}</a></h3>
                                                                        <p>{news.subtitle.length > 40 ? news.subtitle.substring(0, 40) + '...' : news.subtitle}</p>
                                                                        <div className="text-end mt-auto">
                                                                            <a className="btn btn-degradado" onClick={() => {
                                                                                navigate("/mis-noticias/" + news.id);
                                                                            }}>Ver más <i className="fa fa-chevron-right" aria-hidden="true"></i></a>
                                                                        </div>
                                                                    </div>
                                                                </article>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                            : relatedNewsListCopy._tag === 'RemotePending' || relatedNewsListCopy._tag === 'RemoteInitial' &&
                            <>
                                <div className="text-center">
                                    <p>Cargando noticias relacionados...</p>
                                    <SpinerFullScreen />
                                </div>
                            </>
                        }
                    </>
                ) : remoteDataNewsByID._tag === 'RemotePending' ?
                    <>
                        <div className="text-center">
                            <p>Cargando noticia...</p>
                            <SpinerFullScreen />
                        </div>
                    </>
                    : remoteDataNewsByID._tag === 'RemoteFailure' &&
                    <>
                        <div className="text-center">
                            <p>Ha ocurrido un error al obtener la información, por favor, intente más tarde.</p>
                        </div>
                    </>
                }

                <div className="d-grid d-md-flex justify-content-md-between gap-2 flex-nowrap">
                    <Link to="/mis-noticias" onClick={() => {
                        setRemoteDataNewsByID(RD.initial)
                    }}>
                        <button type="button" className="btn btn-link order-md-first"> {'Volver'}</button>
                    </Link>
                </div>
            </section>

            <Share
                modalTitle="Compartir Noticia"
                urlToShare={location}
                activeState={shareActive}
                toggleState={setShareActive}
                shareIn={[
                    "Facebook",
                    "Whatsapp",
                    "X",
                    "Telegram",
                    "Linkedin",
                ]}
                emailTitle="Compartir Oferta"
                emailBody=""
            />
        </>
    )
}
export default NewsDetail;