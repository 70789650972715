import * as t from "io-ts";
import { Dispatch, SetStateAction } from "react";
import { Control, FormState } from "react-hook-form";

type InputsProps = {
    [key: string]: string;
};

type InputsPropsNumber = {
    [key: string]: number;
};

interface ResultObject {
    [key: string]: any;
}

interface InputForm {
    input: FormularioProps;
    formState: FormState<InputsProps>;
    control: Control<InputsProps>;
    handleFunction?: (key: string, value: string) => void;
    getValues?: any
}

interface FormPropsCommons {
    control: Control<InputsProps>;
    formState: FormState<InputsProps>;
    setTotalFiles: Dispatch<SetStateAction<InputsProps>>;
    totalFiles?: InputsProps
}

interface FormProps extends FormPropsCommons {
    form: FormularioProps[];
    handleDropdownChange: (key: any, value: any) => void
    totalFiles: InputsProps
}

interface FilesSolicProps extends FormPropsCommons {
    input: FormularioProps;
    fileInputRef?: any
    setValue?: any;
}

interface DataModalDinamicProps {
    icon: string;
    msg: string;
    submsg?: string;
    callBack: () => void;
}
interface bodyPostAnswer {
    reqId: number,
    isAccepted: boolean,
}

export const validationType = t.type({
    type: t.string,
    value: t.any
})

export const formType = t.type({
    key: t.string,
    type: t.string,
    label: t.string,
    placeholder: t.string,
    tooltip: t.string,
    defaultValue: t.string,
    readOnly: t.boolean,
    validations: t.array(validationType),
    options: t.array(t.type({
        value: t.string,
        label: t.string
    })),
    dependencies: t.array(t.type({
        dependencyKey: t.string,
        dependencyValues: t.array(t.string)
    }))
})

export const sectionType = t.type({
    title: t.string,
    description: t.string,
    forms: t.array(formType)
})

export const fetchRemoteJobCodec = t.type({
    response: t.type({
        ongoingRequest: t.boolean,
        reqStatus: t.string,
        status: t.string,
        reqId: t.number,
        valid: t.boolean,
        proposalRequest: t.boolean,
        proposalOriginal: t.string,
        proposalResponse: t.string,
        information: t.string,
        empName: t.string,
        empRut: t.string,
        empEmail: t.string,
        empPhone: t.string,
        empJob: t.string,
        empLocation: t.string,
        sections: t.array(sectionType)
    }),
});

export type ResponseRemoteJob = t.TypeOf<typeof fetchRemoteJobCodec>;

export const fetchRemotePostJobCodec = t.type({
    response: t.type({
        status: t.string,
        valid: t.boolean
    }),
});

export type ResponsePostRemoteJob = t.TypeOf<typeof fetchRemotePostJobCodec>;

export type FormularioProps = t.TypeOf<typeof formType>

type TValiadationsTypes =
    | 'required'
    | 'justLetters'
    | 'rutChileno'
    | 'phoneNumberCL'
    | 'phoneNumberAR'
    | 'phoneNumberPR'
    | 'phoneNumberBR'
    | 'phoneNumberCB'
    | 'email'
    | 'minLength'
    | 'maxLength'
    | 'minWords'
    | 'maxWords'
    | 'minNumber'
    | 'maxNumber'
    | 'moreThan'
    | 'lessThan'
    | 'isTrue'
    | 'isFalse'
    | 'dateEqual'
    | 'dateEqualOrLess'
    | 'dateEqualOrMore'
    | 'dateLessThan'
    | 'dateMoreThan'
    | 'dateEqualToForm'
    | 'dateEqualOrLessThanInput'
    | 'dateEqualOrMoreThanInput'
    | 'dateLessThanInput'
    | 'dateMoreThanInput'
    | 'fileFormat'
    | 'readonly'
    | 'text'
    | 'number'
    | 'boolean'
    | 'string'
    | 'fileSize'
    ;

export interface IDynamicValidations {
    type: TValiadationsTypes;
    value?: any;
    description?: string;
}

export type {
    FormProps,
    FilesSolicProps,
    InputsProps,
    InputForm,
    ResultObject,
    DataModalDinamicProps,
    bodyPostAnswer,
    InputsPropsNumber
};