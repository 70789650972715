import { Auth } from "aws-amplify";
import { urlsAws } from "../resources/foo/api-endpoints";

type Body = { [key: string]: any } | FormData | null;
type Method = "GET" | "POST" | "PUT" | "DELETE";
type FetchData = {
  url: string;
  method?: Method;
  body?: Body;
  saveCache?: boolean;
  isFile?: boolean;
};

const fetchData = async ({
  url = "",
  method = "GET",
  body = null,
}: FetchData) => {
  try {
    if (!url) return false;
    const auth = await Auth.currentSession();
    const headers: { [key: string]: string } = {
      AccessToken: auth.getAccessToken().getJwtToken(),
      Authorization: auth.getIdToken().getJwtToken(),
    };
    let requestOptions: RequestInit = {
      method,
      headers,
    };

    if (body instanceof FormData) {
      requestOptions.body = body;
    } else {
      headers["Content-Type"] = "application/json; charset=utf-8";
      requestOptions.body = body ? JSON.stringify(body) : null;
    }

    const response = await fetch(url, requestOptions);

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getFeatureFlag = async (featureFlag: string) => {
  try {
    const data = await fetchData({
      url: `${urlsAws.getFeatureFlag}/${featureFlag}`,
      method: "GET",
    });

    return data;
  } catch (error) {
    console.error("Error getting feature flag:", error);
    throw error;
  }
};
