import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styles from "../../resources/css/style-COL.module.css";
import RenunciaVoluntariaService from '../../services/renuncia-voluntaria-col/service';
import errorMessage from '../SharedComponents/error-message';
import warningMessage from '../SharedComponents/warning-message';

const Formulario = ({ data, loading, loadingEstado }) => {
    const [loadingPost, setLoadingPost] = useState(false)

    const navigate = useNavigate()

    const { state } = useLocation();
    
    const {
        register,
        watch,
        formState: { errors },
        handleSubmit
    } = useForm();

    const today = moment().format("DD/MM/YYYY")

    const onSubmit = async (info) => {
        setLoadingPost(true)

        if (data.correo_electronico_personal == "" || data.correo_electronico_personal == undefined || data.correo_electronico_personal == null) {
            warningMessage(null, "No cuentas con dirección de correo personal, complétalo en la sección Información Personal");
            setLoadingPost(false)
            return;
        }
        const fechaLimite = moment().subtract(7, 'days');
        const fechaIngresada = moment(info.ultimo_dia_trabajado);

        if (fechaIngresada.isSameOrBefore(fechaLimite, 'day')) {
            errorMessage(null, "La fecha ingresada no permite solicitar la renuncia voluntaria por este medio.");
            setLoadingPost(false)
            return
        }

        const input = {
            nombre_colaborador: data.nombre_colaborador,
            cc_colaborador: data.cc_colaborador,
            correo_electronico_personal: data.correo_electronico_personal,
            fecha_emision: moment().format("YYYY-MM-DD"),
            ultimo_dia_trabajado: info.ultimo_dia_trabajado,
            comentario: info.comentario,
            comentario_text: info.comentario == 0 ? "" : info.comentario_text,
            ...state
        }

        const pdf = await RenunciaVoluntariaService.crearPdf(input)

        if (pdf.status == 200) {
            navigate("../ver-archivo", { state: data.correo_electronico_personal })
            setLoadingPost(false)
        } else {
            errorMessage(null)
            setLoadingPost(false)
        }
    }

    const caracteres = useRef({})
    caracteres.current = watch("comentario_text", "")

    return (<>
        <div className={`${styles["box"]} ${styles["align-items-stretch"]} ${styles["w-100"]}`}>
            <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>Renuncia Voluntaria Digital</h2>
            <ul className={`${styles["list-unstyled"]} ${styles["d-flex"]} ${styles["justify-content-center"]} ${styles["flex-column"]} ${styles["gap-3"]} ${styles["flex-md-row"]} ${styles["gap-md-5"]} ${styles["fw-bold"]} ${styles["border-md-bottom"]} ${styles["pb-4"]} ${styles["mb-0"]} ${styles["mb-md-5"]}`}>
                <li className={`${styles["d-flex"]} ${styles["align-items-center"]}`}><span className={`${styles["ic-circle"]} ${styles["bg-orange"]}`}>1</span><span className={styles["color-primary"]}>Encuesta</span></li>
                <li className={`${styles["d-flex"]} ${styles["align-items-center"]}`}><span className={`${styles["ic-circle"]} ${styles["bg-orange"]}`}>2</span><span className={styles["color-primary"]}>Completa el Formulario</span></li>
                <li className={`${styles["d-flex"]} ${styles["align-items-center"]}`}><span className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}>3</span><span className={styles["color-primary"]}>Ratifica la Carta</span></li>
                <li className={`${styles["d-flex"]} ${styles["align-items-center"]}`}><span className={`${styles["ic-circle"]} ${styles["ic-circle-primary"]}`}>4</span><span className={styles["color-primary"]}>Descarga la Carta</span></li>
            </ul>
            {(!loading && !loadingEstado) ? <div className={styles["row"]}>
                <div className={`${styles["col-md-7"]} ${styles["d-flex"]}`}>
                    <div className={`${styles["border"]} ${styles["p-4"]} ${styles["mb-5"]} ${styles["mb-md-3"]}`}>
                        <div className={styles["box-scroll"]} style={{ maxHeight: 670 + "px" }}>
                            <p>“Respetado; <strong>CENCOSUD COLOMBIA S.A.</strong>, le hace saber que, al usted informar y/o radicar su decisión de renunciar al cargo desempeñado al interior de la organización, entendiéndose ésta como una decisión eminentemente voluntaria, pura y simple, y/o conforme lo dispone el artículo 61 literal h) del Código Sustantivo del Trabajo y de la Seguridad Social, reconoce y acepta las implicaciones y/o consecuencias de tal decisión,
                                entendiendo estas como la ruptura y/o finalización de la relación laboral -contrato individual de trabajo- y por ende, del fenecimiento de las obligaciones en cabeza de ambas partes y que se encontraban intrínsecas en tal relación legal que ha decidido finalizar.</p>
                            <p>Entendido lo anterior, es menester indicar que la presente decisión de renunciar al cargo desempeñado será inmediatamente aceptada y de la misma se correrá traslado, de ser necesario, al departamento legal de CENCOSUD COLOMBIA S.A. a fin de que se pronuncie sobre la misma, ello de ser procedente.</p>
                            <p>Para finalizar y de acuerdo con la renuncia que pretende gestionar, deberá además informarla a su jefe inmediato, a fin de que el mismo esté enterado de la decisión por usted tomada y no impacte sorpresivamente el área o proceso en que desempeñaba sus servicios”.</p>
                        </div>
                    </div>

                </div>
                <div className={styles["col-md-5"]}>
                    <div className={styles["mb-4"]}>
                        <h3 className={`${styles["h5"]} ${styles["fw-bold"]} ${styles["bg-primary"]} ${styles["color-white"]} ${styles["d-inline-block"]} ${styles["p-1"]} ${styles["mb-4"]}`}>Antecedentes del colaborador</h3>
                        <div className={styles["mb-3"]}>
                            <label className={styles["form-label"]}>Nombre del colaborador</label>
                            <input type="text" className={styles["form-control"]} value={data.nombre_colaborador} disabled />
                        </div>
                        <div className={styles["mb-3"]}>
                            <label className={styles["form-label"]}>CC colaborador</label>
                            <input type="text" className={styles["form-control"]} value={data.cc_colaborador} disabled />
                        </div>
                        <div className={styles["mb-3"]}>
                            <label className={styles["form-label"]}>Email Personal</label>
                            <input type="email" className={styles["form-control"]} value={data.correo_electronico_personal} disabled />
                            <small className={`${styles["mt-1"]} ${styles["d-inline-block"]}`}>Si la dirección no es correcta o no cuentas con una, por favor modifícala desde la sección <Link to="/col/datospersonales">Información de contacto</Link>.</small>
                        </div>
                    </div>
                    <div>
                        <h3 className={`${styles["h5"]} ${styles["fw-bold"]} ${styles["bg-primary"]} ${styles["color-white"]} ${styles["d-inline-block"]} ${styles["p-1"]} ${styles["mb-4"]}`}>Antecedentes de la renuncia</h3>
                        <div className={`${styles["mb-3"]} ${styles["col-md-6"]}`}>
                            <label className={styles["form-label"]}>Fecha de emisión y ratificación de la renuncia</label>
                            <input className={styles["form-control"]} type="text" value={today} disabled />
                        </div>
                        <div className={`${styles["mb-3"]} ${styles["col-md-6"]}`}>
                            <label className={styles["form-label"]}>Fecha del último dia trabajado (Fecha de baja)</label>
                            <input className={styles["form-control"]} type="date" placeholder="dd/mm/aaaa"
                                {...register("ultimo_dia_trabajado", { required: "* Campo obligatorio" })}
                            />
                            <small className="form-error-message">{errors.ultimo_dia_trabajado?.message}</small>{" "}
                        </div>
                        <div className={styles["mb-3"]}>
                            <label className={styles["form-label"]}>Deseas agregar tus motivos a la carta de renuncia</label>
                            <div>
                                <div className={`${styles["form-check"]} ${styles["form-check-inline"]}`}>
                                    <input className={styles["form-check-input"]} type="radio" name="comentario" id="motivo-renuncia-si" value={1}
                                        {...register("comentario", { required: "* Campo obligatorio", maxLength: 1 })} />
                                    <label className={styles["form-check-label"]} htmlFor="motivo-renuncia-si">Sí</label>
                                </div>
                                <div className={`${styles["form-check"]} ${styles["form-check-inline"]}`}>
                                    <input className={styles["form-check-input"]} type="radio" name="comentario" id="motivo-renuncia-no" value={0}
                                        {...register("comentario", { required: "* Campo obligatorio", maxLength: 1 })}
                                        defaultChecked
                                    />
                                    <label className={styles["form-check-label"]} htmlFor="motivo-renuncia-no">No</label>
                                </div>
                            </div>
                        </div>
                        <div className={styles["mb-3"]}>
                            <label className={styles["form-label"]}>Motivos</label>
                            <span className={styles["m-2"]}>{caracteres.current.length}/1000</span>
                            <textarea className={styles["form-control"]}
                                {...register("comentario_text",
                                    {
                                        required: watch("comentario") == 0 ? false : "* Campo obligatorio",
                                        maxLength: 1000,
                                        onChange: (e) => {
                                            if (e.target.value.length >= caracteres.current.length) {
                                                return
                                            }
                                        }
                                    })}
                                disabled={watch("comentario") != 1}
                                maxLength={1000}
                            ></textarea>
                            {watch("comentario") == 0 ? null : <small className="form-error-message">{errors.comentario_text?.message}</small>}{" "}
                        </div>
                    </div>
                </div>
            </div> : <div data-testid="loading" className={styles["full-spinner"]}>
                <div className={`${styles["spinner-border"]} ${styles["text-primary"]}`}></div>
            </div>}
            <div className={`${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["justify-content-md-end"]} ${styles["gap-3"]} ${styles["flex-nowrap"]} ${styles["my-5"]}`}>
                <div className={`${styles["order-md-last"]} ${styles["d-grid"]} ${styles["d-md-flex"]} ${styles["gap-2"]}`}>
                    <button type="button" className={`${styles["btn"]} ${styles["btn-degradado"]}`} onClick={handleSubmit(onSubmit)} disabled={loadingPost}>Siguiente {loadingPost ? <div className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}></div> : <i className={`${styles["fa"]} ${styles["fa-chevron-right"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>}</button>
                </div>
                <button type='button' className={`${styles["btn"]} ${styles["btn-primary"]}`} onClick={() => navigate(-1)} ><i className={`${styles["fa"]} ${styles["fa-chevron-left"]} ${styles["fa-fw"]} ${styles["fa-xs"]}`} aria-hidden="true"></i>Anterior</button>
            </div>
        </div>
    </>
    )
}

export default Formulario;