import { useEffect, useState } from "react";
import { useAxiosFetch } from "../../../../hooks";
import {
  CollectiveAgreementBenefitsApi,
  CollectiveAgreementBenefitsDetailApi,
  CreateCollectiveAgreementBenefitsApi,
} from "./CollectiveAgreementApi.types";

export const useCollectiveAgreementFetch = (id?: number) => {
  const [isLoading, setIsLoading] = useState(false);

  const [fetchBenefitsList, benefitsListData] = useAxiosFetch<
    CollectiveAgreementBenefitsApi[]
  >({
    url: `/api/benefits/list`,
    method: "GET",
  });

  const [fetchBenefitsListDetail, benefitsListDetailData] = useAxiosFetch<
    CollectiveAgreementBenefitsDetailApi[]
  >({
    url: `/api/benefits/detail/${id}`,
    method: "GET",
  });

  const [createBenefitRequest, createBenefitRequestData] =
    useAxiosFetch<CreateCollectiveAgreementBenefitsApi>({
      url: `/api/benefits/benefit-request/create`,
      method: "POST",
    });

  useEffect(() => {
    fetchBenefitsList();
    if (id) {
      fetchBenefitsListDetail();
    }
  }, [id]);

  useEffect(() => {
    setIsLoading(
      createBenefitRequestData.isLoading ||
        benefitsListDetailData.isLoading ||
        benefitsListData.isLoading
    );
  }, [
    createBenefitRequestData.isLoading,
    benefitsListDetailData.isLoading,
    benefitsListData.isLoading,
  ]);

  const benefitListDataMapper = () => {
    if (benefitsListData.data) {
      const flattenedData = benefitsListData.data.flat();
      return (
        flattenedData.map((item) => ({
          id: item.id ?? "-",
          name: item.name ?? "-",
          description: item.description ?? "-",
          benefitCode: item.benefitCode ?? "-",
          requirements: item.requirements ?? "-",
        })) || []
      );
    }
    return [];
  };

  const benefitDetailDataMapper = () => {
    if (benefitsListDetailData.data) {
      return (
        benefitsListDetailData.data.map((item) => ({
          fieldName: item.fieldName ?? "-",
          fieldLabel: item.fieldLabel ?? "-",
          available: item.available,
          isRequired: item.isRequired,
          type: item.type ?? "-",
          order: item.order ?? 0,
          length: item.length ?? 0,
        })) || []
      );
    }
    return [];
  };
  const submitCreateBenefitRequest = async (
    data: CreateCollectiveAgreementBenefitsApi
  ) => {
    try {
      await createBenefitRequest({
        data,
      });
      return { success: true, message: "Solicitud creada correctamente." };
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        return { success: false, message: error.response.data.message };
      } else {
        return { success: false, message: "Error desconocido al crear la solicitud." };
      }
    }
  };

  return {
    benefitsList: benefitListDataMapper(),
    benefitsListDetail: benefitDetailDataMapper(),
    submitCreateBenefitRequest,
    createBenefitRequestData,
    isLoading,
    createBenefitRequestStatus: createBenefitRequestData.status,
  } as const;
};
