import Auth from "@aws-amplify/auth";
import { either as E, readerTaskEither as RTE } from "fp-ts";
import * as t from "io-ts";
import { urlsAws } from "../../../../resources/foo/api-endpoints";
import { getJson } from "../../../../services/apiServices/httpClient"
import { HttpRequestError, HttpContentTypeError, HttpResponseStatusError, HttpClientEnv } from "../../../../services/apiServices/interfaces";
import { serialize } from "../../../../services/utiles";
import { bodyPostAnswer, fetchRemoteJobCodec, fetchRemotePostJobCodec, ResponsePostRemoteJob, ResponseRemoteJob } from "../interfaces/interfaces";

export const getRemoteJob = async () => {
    const params: string = ""
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: "GET",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        ResponseRemoteJob
    > = getJson(urlsAws.remoteJob, params, requestOptions, fetchRemoteJobCodec.decode)
    return response;
};

export const postRemoteJob = async (body: {}) => {
    const params: string = ""
    const auth = await Auth.currentSession();
    let serialized = serialize(body, { indices: true });
    const requestOptions: RequestInit = {
        method: "POST",
        headers: { Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
        body: serialized
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        ResponsePostRemoteJob
    > = getJson(urlsAws.remoteJob, params, requestOptions, fetchRemotePostJobCodec.decode)
    return response;
};

export const postRemoteJobAnswer = async (body: bodyPostAnswer) => {
    console.log("entramooos")
    const params: string = ""
    const auth = await Auth.currentSession();
    const requestOptions: RequestInit = {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8", Authorization: auth.getIdToken().getJwtToken(), AccessToken: auth.getAccessToken().getJwtToken() },
        body: JSON.stringify(body)
    };
    const response: RTE.ReaderTaskEither<
        HttpClientEnv,
        HttpRequestError | HttpContentTypeError | HttpResponseStatusError | t.Errors,
        ResponsePostRemoteJob
    > = getJson(urlsAws.remoteJobAnswer, params, requestOptions, fetchRemotePostJobCodec.decode)
    return response;
};
