import { FC, Children } from "react";
import { TablesProps } from "../interfaces/interfaces";
import TableInfoBonus from "./TableInfo";

const BonusInfo: FC<TablesProps> = ({tableSection}) => {
    return (
        <>
            {Children.toArray(tableSection.map((table, i)=> {
                return(
                    <>
                        <div className="box mb-4">
                            <h2 className="h4 tit-section">{table.title}</h2>
                            <h3 className="h5">{'Detalle'}</h3>
                            <p>{table.description}</p>
                            <p className="color-orange">{"*Usted posee nuevo convenio, los valores reflejados están en proceso de actualización."}</p>
                            <TableInfoBonus tables={table.tables}/>
                        </div>
                    </>
                )
            }))}
        </>
    )
}
export default BonusInfo;