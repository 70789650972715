/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import VacacionesService from "../../services/solicitud-vacaciones/vacaciones-arg-service";
import Swal from "sweetalert2";
import Tippy from "@tippyjs/react";
import { RiErrorWarningLine } from "react-icons/ri";
import moment from "moment";
import styles from "../../resources/css/style-ARG.module.css";
import ReplacementsService from "../../services/asignacion-reemplazo/replacements-service";
import ReplacementExportableComponent from "../asignacion-reemplazo/replacement-exportable";
import { FaChevronRight } from "react-icons/fa";

const EditCardVacaciones = () => {
  const navigate = useNavigate();
  const [terms, setTerms] = useState({});
  const [data, setData] = useState({});
  const [fetched, setFetched] = useState(false);
  const [disabled, setDisabled] = useState(null);

  const [appSent, setAppSent] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [loadingSave, setLoadingSave] = useState(false);

  const [sinAprobador, setSinAprobador] = useState(null);
  const [loadingCard, setLoadingCard] = useState(true);
  const [acceptedTermsFlag, setAcceptedTermsFlag] = useState(false);

  const [fiFlag, setFiFlag] = useState(false);
  const [ffFlag, setFfFlag] = useState(false);
  const [faFlag, setFaFlag] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [duration, setDuration] = useState(0);
  const [absence, setAbsence] = useState({
    id: "",
    tipoAusencia: "",
    userId: "",
    fechaInicio: null,
    fechaFinal: null,
    advanceDate: null,
    applicantComments: "",
    applicationId: "",
    aprovedBy: "",
    aprovedComments: "",
    aprovedDate: null,
    aprovedFlag: "",
    aprovedName: "",
    beginDate: null,
    durationDays: "",
    periodEnd: null,
    requestDate: null,
    returnDate: null,
    state: "",
    tieneAdvanceDate: null,
  });
  const [currentReplacement, setCurrentReplacement] = useState({});
  const [replacements, setReplacements] = useState([]);
  const [dataNotFetched, setDataNotFetched] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingApproverStatus, setLoadingApproverStatus] = useState(false);
  const [isApprover, setIsApprover] = useState(true);
  const [replacementsActive, setReplacementsActive] = useState(true);
  const [isEnabled, setIsEnabled] = useState(false);
  const [advanceDateIsChanged, setAdvanceDateIsChanged] = useState(false);

  useEffect(() => {
    const fetchUserReplacements = async () => {
      setLoadingTable(true);
      try {
        const response = await ReplacementsService.getUserReplacements();
        setReplacements(response.data);
      } catch (error) {
        console.error("Error fetching replacements:", error);
        setDataNotFetched(true);
      } finally {
        setLoadingTable(false);
      }
    };

    fetchUserReplacements();
  }, []);

  useEffect(() => {
    hasSubordinates();
    getTermsBP();
    getSummaryData();
    doesReplacementExist();
  }, [fetched]);

  useEffect(() => {
    const fetchFeatureFlag = async () => {
      try {
        setLoadingApproverStatus(true);
        const response = await ReplacementsService.getReplacementsFeatureFlag();
        setIsEnabled(response.data.isEnabled);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingApproverStatus(false);
      }
    };

    fetchFeatureFlag();
  }, []);

  const hasSubordinates = async () => {
    try {
      await ReplacementsService.hasSubordinates(
        setIsApprover,
        setLoadingApproverStatus
      );
    } catch (error) {
      console.error("Error while setting values:", error);
    }
  };
  const doesReplacementExist = async () => {
    try {
      await ReplacementsService.getCurrentReplacement(
        setLoadingTable,
        setCurrentReplacement,
        setDataNotFetched
      );
    } catch (error) {
      console.error("Error while setting values:", error);
    }
  };

  useEffect(() => {
    //Measurement of content height and update of func() state
    const contentElement = document.querySelector(
      `.${styles["holiday-card-content"]}`
    );
    if (contentElement) {
      setContentHeight(contentElement.scrollHeight);
    }
  }, [loadingCard, fetched]);

  const getSummaryData = async () => {
    await VacacionesService.getSummaryCard(
      setData,
      setFetched,
      setLoadingCard,
      setAbsence,
      absence,
      setSinAprobador,
      setDisabled
    );
  };

  const validateDate = (beginDate, endDate, advanceDate) => {
    let minYear = new Date().getFullYear() - 2;
    let bg = new Date(beginDate);
    let ed = new Date(endDate);
    let ad = advanceDate && advanceDateIsChanged ? new Date(advanceDate) : null;
    if (bg > ed) {
      Swal.fire({
        allowOutsideClick: false,
        position: "center",
        title:
          '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
        html: '<div style="text-align: center; font-size: 15px"> la fecha de inicio no puede ser mayor a la fecha final </div>',
        confirmButtonColor: "#0169b5",
      });
      //setValidDate(false);
      return false;
    }
    if (bg.getFullYear() < minYear || ed.getFullYear() < minYear) {
      Swal.fire({
        allowOutsideClick: false,
        position: "center",
        title:
          '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
        html: '<div style="text-align: center; font-size: 15px">El año ingresado no es un año valido para la solicitud</div>',
        confirmButtonColor: "#0169b5",
      });
      return false;
    }
    if (!absence.tieneAdvanceDate && ad !== null) {
      if (ad.getDay() !== 4) {
        Swal.fire({
          allowOutsideClick: false,
          position: "center",
          title:
            '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
          html: '<div style="text-align: center; font-size: 15px"> La fecha de anticipo debe ser un día viernes </div>',
          confirmButtonColor: "#0169b5",
        });
        //setValidDate(false)
        return false;
      }

      if (advanceDateInvalid(ad)) {
        Swal.fire({
          allowOutsideClick: false,
          position: "center",
          title:
            '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
          html: `<div style="text-align: center; font-size: 15px">La fecha de anticipo debe ser entre el ${moment(
            data.limitBeginAdvanceDate
          ).format("DD/MM/YYYY")} y el ${moment(
            data.limitEndAdvanceDate
          ).format("DD/MM/YYYY")}</div>`,
          confirmButtonColor: "#0169b5",
        });
        // setValidDate(false);
        return false;
      }

      var today = new Date();
      var diffInTime =
        ad.getTime() +
        ad.getTimezoneOffset() * 60000 -
        new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        ).getTime();
      var diffInDays = Math.round(diffInTime / (1000 * 60 * 60 * 24));
      if (diffInDays < 14 && advanceDateIsChanged) {
        Swal.fire({
          allowOutsideClick: false,
          position: "center",
          title:
            '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
          html: '<div style="text-align: center; font-size: 15px"> La fecha de anticipo debe ser por lo menos 14 días posterior a la fecha de la solicitud </div>',
          confirmButtonColor: "#0169b5",
        });
        //setValidDate(false);
        return false;
      }
    }

    //setValidDate(true)
    return true;
  };

  const negativesDaysConfirm = (daysAfterTake, callback) => {
    Swal.fire({
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonColor: "#0067b7",
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
      position: "center",
      title:
        '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
      html: `<div style="text-align: center; font-size: 15px">Usted no cuenta con el suficiente saldo de vacaciones para el periodo, si continua su saldo quedara en ${daysAfterTake} dias negativos</div>`,
      confirmButtonColor: "#f59300",
      confirmButtonText: "Enviar",
    }).then((confirmed) => {
      callback(confirmed);
    });
  };

  const advanceDateInvalid = (advanceDate) => {
    var limitBeginAdvanceDate = new Date(data.limitBeginAdvanceDate);
    var limitEndAdvanceDate = new Date(data.limitEndAdvanceDate);
    var ad = new Date(advanceDate);

    return ad < limitBeginAdvanceDate || ad > limitEndAdvanceDate;
  };

  const getTermsBP = async () => {
    await VacacionesService.getTermsBP(setTerms);
  };

  const modalTerms = () => {
    return Swal.fire({
      title: "BP",
      html: `<div style="text-align: left; height: 300px;"> ${terms?.bpsConditions} </div>`,
      confirmButtonColor: "#0169b5",
    });
  };

  const resetDates = () => {
    setAcceptedTermsFlag(false);
    setAbsence({
      ...absence,
      advanceDate: !absence.tieneAdvanceDate ? null : absence.advanceDate,
      beginDate: null,
      returnDate: null,
    });
  };

  const validateForm = () => {
    if (!absence.beginDate || !absence.returnDate) {
      Swal.fire({
        allowOutsideClick: false,
        position: "center",
        title:
          '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
        html: '<div style="text-align: center; font-size: 15px"> Complete las fechas de la solicitud para continuar </div>',
        confirmButtonColor: "#0169b5",
      });
      return false;
    } else if (!acceptedTermsFlag) {
      Swal.fire({
        allowOutsideClick: false,
        position: "center",
        title:
          '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
        html: '<div style="text-align: center; font-size: 15px"> Debe aceptar las condiciones para poder enviar la solicitud </div>',
        confirmButtonColor: "#0169b5",
      });
      return false;
    } else {
      return true;
    }
  };

  const handleRD = () => {
    setFfFlag(!absence.returnDate ? true : false);
  };
  const handleBD = () => {
    setFiFlag(!absence.beginDate ? true : false);
  };
  const handleAD = () => {
    setFaFlag(!absence.advanceDate ? true : false);
  };

  const contactoBP = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: "center",
      title:
        '<div style="text-align: center; color: red; font-size: 16px"> Advertencia! </div>',
      html: '<div style="text-align: center; font-size: 15px"> Usted no tiene aprobador, contacte a su BP </div>',
      confirmButtonColor: "#0169b5",
    }).then((result) => {
      if (result.isConfirmed) {
        setSinAprobador(false);
      }
    });
  };

  const errorHandler = () => {
    if (errorMessage) {
      Swal.fire({
        allowOutsideClick: false,
        position: "center",
        title:
          '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
        html: `<div style="text-align: center; font-size: 15px"> ${errorMessage} </div>`,
        confirmButtonColor: "#0169b5",
      });
      setErrorMessage("");
    } else {
      Swal.fire({
        allowOutsideClick: false,
        position: "center",
        title:
          '<div style="text-align: center; color: red; font-size: 16px"> Error! </div>',
        html: `<div style="text-align: center; font-size: 15px"> Ocurrió un error en el procesamiento de su solicitud </div>`,
        confirmButtonColor: "#0169b5",
      });
    }
    setErrorFlag(false);
  };

  const successHandler = () => {
    Swal.fire({
      allowOutsideClick: false,
      position: "center",
      title:
        '<div style="text-align: center; color: green; font-size: 16px"> Estado de la solicitud </div>',
      html: `<div style="text-align: center; font-size: 15px"> Solicitud enviada exitosamente </div>`,
      confirmButtonColor: "#0169b5",
    }).then((result) => {
      if (result.isConfirmed) {
        setAppSent(false);
        navigate("solicitudes-vacaciones-arg");
      }
    });
  };

  const handleSubmit = () => {
    var validF = validateForm();
    if (validF) {
      var validDate = validateDate(
        absence.beginDate,
        absence.returnDate,
        absence.advanceDate
      );
      if (validDate) {
        var daysAfterTake = data.availableDays - duration;
        if (daysAfterTake < 0) {
          negativesDaysConfirm(daysAfterTake, (confirmed) => {
            if (confirmed.isConfirmed) {
              setLoadingSave(true);
              let formData = {
                beginDate: absence.beginDate,
                returnDate: absence.returnDate,
                advanceDate:
                  absence.advanceDate == null ? "" : absence.advanceDate,
                comments: absence.applicantComments,
                aproverName: data.aproverName,
                tieneAdvanceDate: advanceDateIsChanged,
              };
              VacacionesService.postSolicitud(
                formData,
                setAppSent,
                setLoadingSave,
                setErrorFlag,
                setErrorMessage
              );
            }
          });
        } else {
          setLoadingSave(true);
          let formData = {
            beginDate: absence.beginDate,
            returnDate: absence.returnDate,
            advanceDate: absence.advanceDate == null ? "" : absence.advanceDate,
            comments: absence.applicantComments,
            aproverName: data.aproverName,
            tieneAdvanceDate: advanceDateIsChanged,
          };
          VacacionesService.postSolicitud(
            formData,
            setAppSent,
            setLoadingSave,
            setErrorFlag,
            setErrorMessage
          );
        }
      }
    }
  };
  //function to calculate duration period of vacation in days
  const durationLogic = (init, end) => {
    return moment(end).diff(init, "days") + 1;
  };

  useEffect(() => {
    if (absence.beginDate && absence.returnDate) {
      setDuration(durationLogic(absence.beginDate, absence.returnDate));
    }
  }, [absence.beginDate, absence.returnDate]);

  return (
    <>
      <div className="mx-auto">
        <div
          className={`${styles["card-box"]} ${styles["holiday-card"]} ${contentHeight > 482 ? styles["overflow-y-auto"] : ""
            }`}
        >
          {!loadingCard && fetched ? (
            <div className={styles["holiday-card-content"]}>
              <div className={`${styles["btn-container"]}`}>
                <div className={`${styles["button-history-container"]}`}>
                  <button
                    disabled={disabled}
                    className={`${styles.btn} ${styles.history}`}
                  >
                    <Link to="solicitudes-vacaciones-arg">
                      Ver Historico{" "}
                      <i
                        className={`${styles["color-white"]} fa fa-chevron-right fa-fw fa-xs`}
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </button>
                </div>
                <div className={`${styles["holiday-btn"]}`}>
                  <button
                    className={`${styles.btn} ${styles.cancel}`}
                    type="reset"
                    onClick={() => {
                      setAbsence({
                        ...absence,
                        requestDate: new Date(),
                        returnDate: null,
                        applicantComments: "",
                        beginDate: null,
                      });
                      resetDates;
                    }}
                  >
                    Cancelar{" "}
                    <i
                      className={`${styles["color-black"]} fa fa-chevron-right fa-fw fa-xs`}
                      aria-hidden="true"
                    ></i>
                  </button>
                  <button
                    disabled={loadingSave}
                    className={`${styles.btn} ${styles.save}`}
                    onClick={handleSubmit}
                  >
                    Enviar{" "}
                    {loadingSave ? (
                      <div
                        className={`${styles["spinner-border"]} ${styles["spinner-border-sm"]}`}
                      ></div>
                    ) : (
                      <i
                        className={`${styles["color-white"]} fa fa-chevron-right fa-fw fa-xs`}
                        aria-hidden="true"
                      ></i>
                    )}
                  </button>
                </div>
              </div>

              <form
                id="form"
                className={`${styles["need-validation"]}`}
                noValidate
              >
                <div className={`${styles["form-container"]} mt-2`}>
                  {/*GENERALES*/}

                  <div className="row">
                    <div className="col-12">
                      <div className={styles.fields}>
                        <div className={styles.approver}>
                          <div className={styles["approver-title"]}>
                            Aprobador de vacaciones :{" "}
                            <span className={`${styles["approver-name"]}`}>
                              {data?.aproverName}
                            </span>
                          </div>
                          <div className={styles["approver-subtitle"]}>
                            Si tu aprobador no es el correcto contactá a tu BP.{" "}
                            <a
                              className={`${styles["terms-anchor"]} ${styles["anchor-margin"]}`}
                              onClick={() => modalTerms()}
                            >
                              Consultá acá
                            </a>{" "}
                            en caso de dudas sobre tu BP.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles["holiday-card-section"]}>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-0 m-0">
                        <div className={styles["fields-dates"]}>
                          <div
                            className={` ${fiFlag ? styles["error-colour"] : ""
                              } ${styles.label} fw-bold`}
                          >
                            <div style={{ color: "red" }}>*</div>Fecha Inicio{" "}
                            <Tippy content="La fecha de inicio debe ser un día hábil.">
                              <div>
                                <i
                                  className="fa fa-question fa-fw fa-lg color-orange"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </Tippy>
                          </div>
                          <input
                            disabled={disabled}
                            onBlur={handleBD}
                            className={`form-control ${fiFlag ? styles["error-border"] : ""
                              }`}
                            required
                            name="beginDate"
                            type="date"
                            defaultValue={absence?.beginDate}
                            onChange={(e) => {
                              if (e.target.value > absence.returnDate) {
                                Swal.fire({
                                  allowOutsideClick: false,
                                  position: "center",
                                  title:
                                    '<div style="text-align: center; color: orange; font-size: 16px"> Advertencia! </div>',
                                  html: '<div style="text-align: center; font-size: 15px"> La fecha final no puede ser menor a la fecha inicio. </div>',
                                  confirmButtonColor: "#0169b5",
                                });
                              }
                              setAbsence({
                                ...absence,
                                beginDate: e.target.value,
                              });
                            }}
                          ></input>
                          {fiFlag && (
                            <div
                              className={`d-block d-md-none ${styles["error-label"]}`}
                            >
                              *complete este campo
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-0 m-0">
                        <div className={styles["fields-dates"]}>
                          <div
                            className={` ${ffFlag ? styles["error-colour"] : ""
                              } ${styles.label} fw-bold`}
                          >
                            <div style={{ color: "red" }}>*</div>Fecha Final{" "}
                            <Tippy content="Para establecer la fecha fin considerá que los días deben ser corridos e incluir los fines de semana.">
                              <div>
                                <i
                                  className="fa fa-question fa-fw fa-lg color-orange"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </Tippy>
                          </div>
                          <input
                            disabled={disabled}
                            className={`form-control ${ffFlag ? styles["error-border"] : ""
                              }`}
                            onBlur={handleRD}
                            required
                            name="endDate"
                            type="date"
                            onChange={(e) => {
                              setAbsence({
                                ...absence,
                                returnDate: e.target.value,
                              });
                            }}
                          ></input>
                          {ffFlag && (
                            <div
                              className={`d-block d-md-none ${styles["error-label"]}`}
                            >
                              *complete este campo
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-0 m-0">
                        <div className={styles["fields-dates"]}>
                          <div className={`${styles.label} fw-bold`}>
                            Duración
                          </div>
                          <input
                            className={styles["form-control"]}
                            type="text"
                            value={`${duration} días`}
                            disabled
                          />
                          <div className={styles["error-label"]}> </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-none d-md-flex p-0 w-100">
                      <div className={`row w-100 p-0 ${styles["error-tags"]}`}>
                        <div
                          className={`col-md-4 col-lg-4 m-0 ${styles["padding-custom"]}}`}
                        >
                          {fiFlag && (
                            <div className={styles["error-label"]}>
                              *complete este campo
                            </div>
                          )}
                        </div>
                        <div
                          className={`col-md-4 col-lg-4 m-0 ${styles["padding-custom"]}}`}
                        >
                          {ffFlag && (
                            <div className={styles["error-label"]}>
                              *complete este campo
                            </div>
                          )}
                        </div>
                        <div
                          className={`col-md-4 col-lg-4 m-0 ${styles["padding-custom"]}}`}
                        ></div>
                      </div>
                    </div>
                    <div className={styles["holiday-card-section"]}>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-0 m-0">
                        <div className={styles["fields-dates"]}>
                          <div className={`${styles.label} fw-bold`}>
                            Fecha Anticipo{" "}
                            <Tippy
                              content="Completá esta fecha en
                                        caso de querer tu anticipo
                                        de vacaciones.
                                        Siempre deberá ser un día
                                        viernes anterior al goce,
                                        sin excepción, con una
                                        antelación a 14 días desde
                                        la fecha solicitada de
                                        pago.
                                        El anticipo se hará en
                                        función del total de los
                                        días derecho periodo que
                                        corresponden por año,
                                        aún cuando se estén
                                        solicitando menos días."
                            >
                              <div>
                                <i
                                  className={`fa fa-question fa-fw fa-lg ${styles["color-orange"]}`}
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </Tippy>
                          </div>
                          <input
                            disabled={absence.tieneAdvanceDate}
                            className={`form-control`}
                            onBlur={handleAD}
                            name="advanceDate"
                            type="date"
                            defaultValue={absence?.advanceDate}
                            onChange={(e) => {
                              setAbsence({
                                ...absence,
                                advanceDate: e.target.value,
                              })
                              setAdvanceDateIsChanged(true)
                            }}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="d-none d-md-flex row">
                      <div className={`row w-100 p-0 ${styles["error-tags"]}`}>
                        <div
                          className={`col-md-4 col-lg-4 m-0 ${styles["padding-custom"]}}`}
                        ></div>
                      </div>
                    </div>
                  </div>

                  {/*DETALLE*/}
                  <div className={`col`}>
                    <div className="m-1">
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className={styles.fields}>
                          <div className={`${styles.label} fw-bold`}>
                            Observaciones
                          </div>
                          <textarea
                            disabled={disabled}
                            type="text"
                            rows="2"
                            defaultValue={absence?.applicantComments}
                            name="applicantComments"
                            className="form-control"
                            onChange={(e) =>
                              setAbsence({
                                ...absence,
                                applicantComments: e.target.value,
                              })
                            }
                          ></textarea>
                        </div>
                      </div>

                      {disabled ? (
                        <div className="col-xs-12 col-sm-12 col-md-12">
                          <div className={styles.fields}>
                            <div className={`${styles.label} fw-bold`}>
                              Comentario Aprobador
                            </div>
                            <textarea
                              disabled={disabled}
                              type="text"
                              rows="2"
                              value={absence?.aprovedComments}
                              name="aprovedComments"
                              className="form-control"
                            ></textarea>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {loadingApproverStatus ? (
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border text-primary"></div>
                      </div>
                    ) : (
                      <div className="mt-3">
                        {isApprover && isEnabled ? (
                          <>
                            <div className={styles.fields}>
                              <div className={`${styles.label} fw-bold mb-2`}>
                                Asignar Reemplazo
                                <div
                                  onClick={() =>
                                    setReplacementsActive(!replacementsActive)
                                  }
                                >
                                  <Tippy content="Depliegue para definir un reemplazo durante su ausencia">
                                    <div>
                                      <FaChevronRight
                                        className={`${styles["replacements-arrow"]
                                          } ${replacementsActive
                                            ? styles["arrow-active"]
                                            : ""
                                          }`}
                                      />
                                    </div>
                                  </Tippy>
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                !replacementsActive
                                  ? styles.replacements
                                  : styles["replacements-active"]
                              }
                            >
                              <ReplacementExportableComponent
                                initDate={absence.beginDate}
                                endDate={absence.returnDate}
                                current={currentReplacement}
                                replacements={replacements}
                                setReplacements={setReplacements}
                                hideSendButton={true}
                                isSubmitForm={appSent}
                              />
                            </div>
                          </>
                        ) : null}
                      </div>
                    )}

                    {!disabled ? (
                      <div className={`${styles["terms-legals"]}`}>
                        <div className={styles["terms-legals-title"]}>
                          <div className={styles["red-asterisk"]}>* </div>
                          Términos y legales
                        </div>
                        <div className={styles["terms-legals-text"]}>
                          Estoy en conocimiento del saldo de días disponibles
                          posterior a la solicitud de vacaciones, declarando mi
                          conformidad con la información proporcionada por el
                          sistema. Certifico que he delegado los permisos de
                          acceso a los sistemas correspondientes, de manera de
                          garantizar la continuidad normal del flujo de los
                          procesos/negocios de la Compañía.
                        </div>
                        <div className={styles["terms-legals-accept"]}>
                          <input
                            disabled={disabled}
                            type="checkbox"
                            className={`${styles["accept-checkbox"]} ${styles["check-input"]}`}
                            name="approvedFlag"
                            value={acceptedTermsFlag}
                            checked={acceptedTermsFlag}
                            onChange={(e) =>
                              setAcceptedTermsFlag(!acceptedTermsFlag)
                            }
                            required
                          ></input>
                          <label
                            className={`${styles["form-check-label"]}`}
                            htmlFor="invalidCheck"
                          >
                            Acepto Términos y legales
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                {errorFlag ? errorHandler() : appSent ? successHandler() : null}
                {sinAprobador ? contactoBP() : null}
              </form>
            </div>
          ) : !loadingCard ? (
            <div
              className={`${styles["error-container-lb"]} ${styles["text-def"]}}`}
            >
              <RiErrorWarningLine
                className={`${styles["color-primary"]} ${styles["error-icon"]} fs-5`}
              />
              <div>Se ha producido un error. Vuelva a intentarlo</div>
            </div>
          ) : (
            <div className={styles["full-spinner"]}>
              <div
                className={`${styles["spinner-border"]} ${styles["text-primary"]} fw-bold`}
              ></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditCardVacaciones;
