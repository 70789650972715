import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import datosLaboralesService from "../../../services/datos-laborales-service";
import solicitudesService from "../../../services/solicitudes-service";
import { decrypt, dotNumber } from "../../../services/utiles";

const Anticipo = () => {
  const [data, setData] = useState(false);
  let navigate = useNavigate();
  const emplid = localStorage.getItem("emplid");
  const name = useState(decrypt(localStorage.getItem("name")));
  const [anticipoData, setAnticipoData] = useState(null);
  const [valorPorcentaje, setValorPorcentaje] = useState(0);
  const [modalInicio, setModalInicio] = useState(true);
  const [confirmacion, setConfirmacion] = useState(false);
  const [porcentajeSeleccionado, setPorcentajeSeleccionado] = useState(40);
  const [porcentajeAnterior, setPorcentajeAnterior] = useState(0);
  const [montoLiquido, setMontoLiquido] = useState(0);
  const [montoAnticipo, setMontoAnticipo] = useState(0);
  const [totalMesAnterior, setTotalMesAnterior] = useState(0);
  const [maximoAnticipo, setMaximoAnticipo] = useState(0);
  const [diaCorte, setDiaCorte] = useState(0);
  const [montoSeleccionado, setMontoSeleccionado] = useState(0);
  const [anticipoModificacion, setAnticipoModificacion] = useState(false);
  const [response, setResponse] = useState(null);
  const [estadoResponse, setEstadoResponse] = useState(false);
  const [textResponse, setTextResponse] = useState("");
  const [confirmacionModificacion, setConfirmacionModificacion] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [estadoResponseError, setEstadoResponseError] = useState(false);
  const [desactivado, setDesactivado] = useState(true);
  const [primeravez, setPrimeraVez] = useState(true);
  const [tieneNet, setTieneNet] = useState(true);
  const [indefinido, setIndefinido] = useState(false);
  const [maximoAnticipoSolicitar, setMaximoAnticipoSolicitar] = useState(0);
  const [modalPrimerIngreso, setModalPrimerIngreso] = useState(false);
  const delays = (ms = 13500) => new Promise((r) => setTimeout(r, ms));

  const newCall3 = async () => {
    await delays();
    setCargando(false);
    setTextResponse(response.status);
    setEstadoResponse(true);
  };

  const handleClose = () => {
    setConfirmacion(false);
    setModalInicio(true);
    setEstadoResponse(false);
    setConfirmacionModificacion(false);
    setEstadoResponseError(false);
    setPrimeraVez(false);
    setTieneNet(true);
    setModalPrimerIngreso(false);
    try {
      document.body.removeChild(document.getElementById("modal-overlay"));
      document.body.classList.remove("modal-open");
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0";
    } catch (error) {
      undefined;
    }
  };

  const handleClose2 = () => {
    setEstadoResponse(false);
    setEstadoResponseError(false);
    try {
      document.body.removeChild(document.getElementById("modal-overlay"));
      document.body.classList.remove("modal-open");
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0";
    } catch (error) {
      undefined;
    }
  };

  const handleCancel = () => {
    setConfirmacion(false);
    document.body.removeChild(document.getElementById("modal-overlay"));
    document.body.classList.remove("modal-open");
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0";
  };

  const handleCancelConf = () => {
    setConfirmacionModificacion(false);
    document.body.removeChild(document.getElementById("modal-overlay"));
    document.body.classList.remove("modal-open");
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0";
  };
  
  useEffect(() => {
    if (
      anticipoData !== null &&
      anticipoData !== 2 &&
      anticipoData !== undefined
    ) {
      if (anticipoData.porcentaje_default) {
        setMaximoAnticipo(
          parseInt(totalMesAnterior) * anticipoData.porcentaje_default
        );
        setMontoSeleccionado(totalMesAnterior * (porcentajeAnterior/100));
      }
    }
  }, [totalMesAnterior]);

  useEffect(() => {
    if (
      anticipoData !== null &&
      anticipoData !== 2 &&
      anticipoData !== undefined
    ) {
      if (!data.message) {
        if (
          anticipoData.contrato_indefinido === "Y" &&
          (anticipoData.btn_eliminar === "Y" ||
            anticipoData.btn_ingreso === "Y" ||
            anticipoData.btn_modificar === "Y")
        ) {
          setIndefinido(true);
        } else {
          setIndefinido(false);
        }

        if (anticipoData.porcentaje_default) {
          setMaximoAnticipoSolicitar(
            parseInt(montoLiquido) * anticipoData.porcentaje_default
          );

          setValorPorcentaje(anticipoData.porcentaje_default.replace("0.", ""));
        }
        if (anticipoData.monto_anticipo) {
          if (
            anticipoData.monto_anticipo !== "" &&
            anticipoData.monto_anticipo !== " " &&
            anticipoData.monto_anticipo !== null &&
            anticipoData.monto_anticipo !== undefined
          ) {
            var valorAnticipo = anticipoData.monto_anticipo;
            if (
              valorAnticipo === null ||
              valorAnticipo === undefined ||
              valorAnticipo === "" ||
              valorAnticipo === " "
            ) {
              valorAnticipo = 0;
            }
            setMontoAnticipo(valorAnticipo);
          }
        }
        if (anticipoData.monto_liquido) {
          if (
            anticipoData.monto_liquido !== "" &&
            anticipoData.monto_liquido !== " " &&
            anticipoData.monto_liquido !== null &&
            anticipoData.monto_liquido !== undefined
          ) {
            setMontoLiquido(anticipoData.monto_liquido);
          }
        }
        if (anticipoData.dia_corte) {
          setDiaCorte(anticipoData.dia_corte);
        }

        if (anticipoData.anticipo_anterior && anticipoData.estado) {
          if (
            anticipoData.anticipo_anterior === "N" &&
            anticipoData.estado === ""
          ) {
            setAnticipoModificacion(false);

            if (primeravez && modalInicio) {
              setModalPrimerIngreso(true);
            }
          } else if (
            anticipoData.anticipo_anterior === "Y" &&
            anticipoData.estado === "I"
          ) {
            setAnticipoModificacion(false);
            if (primeravez && modalInicio) {
              setModalPrimerIngreso(true);
            }
          } else {
            setAnticipoModificacion(true);
          }
        }
        if (anticipoData.porcentaje_anterior &&
            anticipoData.porcentaje_anterior !== "" &&
            anticipoData.porcentaje_anterior !== " " &&
            anticipoData.porcentaje_anterior !== 0 &&
            anticipoData.porcentaje_anterior !== undefined &&
            anticipoData.porcentaje_anterior !== null
          ) setPorcentajeAnterior(parseFloat(anticipoData.porcentaje_anterior)*100);
      }
    }
  }, [anticipoData, montoLiquido]);

  useEffect(() => {
    if (montoLiquido !== 0) {
      setTotalMesAnterior(parseInt(montoAnticipo) + parseInt(montoLiquido));
    }
  }, [montoLiquido]);

  useEffect(() => {
    solicitudesService.getAnticipos(setAnticipoData, emplid, setPorcentajeSeleccionado);
  }, []);

  useEffect(() => {
    if (emplid !== null) {
      // newCall2();

      if (!navigator.onLine) {
        if (desactivado) {
          setDesactivado(false);
        }
      }
    } else {
      if (emplid !== "" && emplid !== null) {
        datosLaboralesService.datosLaborales(setData, emplid);
      } else {
        navigate("/");
      }
    }
  }, [emplid, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    document.body.classList.add("modal-open");
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = "15px";
    let newDiv = document.createElement("div");
    newDiv.id = "modal-overlay";
    newDiv.className = "modal-backdrop fade show";
    document.body.appendChild(newDiv);
    if (navigator.onLine) {
      if (!anticipoModificacion) {
        setConfirmacion(true);
      }
    } else {
      setTieneNet(false);
    }
  };

  function rangoValores() {
    let valores = [];
    for (var i = 0; i <= valorPorcentaje; i += 5) {
      if (parseInt(i) !== porcentajeAnterior) {
        valores.push(
          <option key={i} value={i}>
            {i}%
          </option>
        );
      } else {
        valores.push(
          <option selected key={i} value={i}>
            {i}%
          </option>
        );
      }
    }
    if (valores.length > 0) {
      if (desactivado) {
        setDesactivado(false);
      }
    }
    return valores;
  }

  useEffect(() => {
    if (
      anticipoData !== null &&
      anticipoData !== 2 &&
      anticipoData !== undefined
    ) {
      if (anticipoData.porcentaje_default) {
        setMontoSeleccionado(totalMesAnterior * (porcentajeSeleccionado/100));
      }
    }
  }, [porcentajeSeleccionado]);

  let date = new Date();
  const hoy = date.toISOString().split("T")[0];

  useEffect(() => {
    if (response) {
      if (response === 2) {
        setTextResponse("Error al ejecutar la acción.");
        setCargando(false);
        setEstadoResponseError(true);
      } else {
        if (response.status) {
          solicitudesService.getAnticipos(setAnticipoData, emplid, setPorcentajeSeleccionado);
          newCall3(response.status);
        }
      }
    }
  }, [response]);

  const aceptarAnticipo = () => {
    setConfirmacion(false);
    setCargando(true);
    solicitudesService.postAnticipo(
      emplid,
      (porcentajeSeleccionado/100).toString(),
      "C",
      "I",
      setResponse, setCargando
    );
  };

  const eliminarAnticipo = () => {
    setAnticipoModificacion(false);
    setCargando(true);
    solicitudesService.postAnticipo(
      emplid,
      "0",
      "U",
      "A",
      setResponse, setCargando
    );
  };

  const aceptarModificacionAnticipo = () => {
    setConfirmacionModificacion(false);
    setCargando(true);
    solicitudesService.postAnticipo(
      emplid,
      (porcentajeSeleccionado/100).toString(),
      "C",
      "A",
      setResponse, setCargando
    );
  };

  function formato(texto) {
    return texto.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1");
  }

  const levantaModalModificacion = () => {
    try {
      document.body.classList.add("modal-open");
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "15px";
      let newDiv = document.createElement("div");
      newDiv.id = "modal-overlay";
      newDiv.className = "modal-backdrop fade show";
      document.body.appendChild(newDiv);
    } catch (error) {
      undefined;
    }

    setConfirmacionModificacion(true);
  };

  const redireccionarInicio = () => {
    try {
      document.body.removeChild(document.getElementById("modal-overlay"));
      document.body.classList.remove("modal-open");
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0";
    } catch (error) {
      undefined;
    }

    navigate("/mis-solicitudes");
  };

  return (
    <>
      {/* inicio breadcrumbs */}
      <nav className="breadcrumbs" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={"/inicio"}>
              <i className="fa fa-home" aria-hidden="true"></i> Inicio
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={"/mis-solicitudes"}>Mis Solicitudes</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Solicitud Anticipo Quincenal
          </li>
        </ol>
      </nav>

      {indefinido ? (
        <>
          {/* fin breadcrumbs */}

          {/* inicio Mis solicitudes */}
          <form onSubmit={(e) => handleSubmit(e)}>
            <section className="mis-solicitudes">
              <h1>Mis solicitudes</h1>

              {/* inicio Solicitud Anticipo Quincenal */}
              <div className="box mb-4">
                <h2 className="h4 tit-section">Solicitud Anticipo Quincenal</h2>
                <h3 className="h5">Detalle</h3>
                <p>
                  Registrar una solicitud de anticipo permite al colaborador/a recibir un porcentaje (%) del sueldo 
                  líquido del mes anterior en la quincena de cada mes. Una vez ingresado y enviado el porcentaje 
                  solicitado, este quedará registrado de forma permanente hasta que el colaborador/a lo modifique o 
                  elimine a través de este mismo menú.
                </p>
                <p>
                  Importante: Para recibir el Anticipo Quincenal, no debes tener sobregiro el mes pasado ni haber 
                  presentado una licencia médica de 10 o más días en el mes actual.
                </p>
              </div>
              {/* fin Solicitud Anticipo Quincenal */}

              {/* inicio Descripción del Anticipo */}
              <div className="box mb-4">
                <h2 className="h4 tit-section">Descripción del Anticipo</h2>
                {/* Escritorio (Tabla) */}
                <table className="table table-even table-th-border table-section table-borderless text-uppercase d-none d-md-table align-middle">
                  <thead>
                    <tr>
                      <th>Detalle</th>
                      <th>Monto / Porcentaje</th>
                      <th>Simbología</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Anticipo recibido mes anterior</td>
                      <td>${dotNumber(montoAnticipo)}</td>
                      <td>A</td>
                    </tr>
                    <tr>
                      <td>Sueldo recibido mes anterior</td>
                      <td>${dotNumber(montoLiquido)}</td>
                      <td>B</td>
                    </tr>
                    <tr>
                      <td>Total mes anterior</td>
                      <td>${dotNumber(totalMesAnterior)}</td>
                      <td>C = A + B</td>
                    </tr>
                    <tr>
                      <td>Máximo anticipo a solicitar ({valorPorcentaje}%)</td>
                      <td>${dotNumber(maximoAnticipoSolicitar)}</td>
                      <td>D</td>
                    </tr>
                    <tr>
                      <td>Seleccione porcentaje a solicitar</td>
                      <td>
                        <div className="col-12 col-xl-6">
                          <select
                            id="inputState"
                            className="form-select"
                            required="required"
                            onChange={(e) => { setPorcentajeSeleccionado(e.target.value) }}
                          >
                            <option disabled value="">
                              Seleccione porcentaje
                            </option>
                            {rangoValores().map((e) => {
                              return e;
                            })}
                          </select>
                        </div>
                      </td>
                      <td>E</td>
                    </tr>
                    <tr>
                      <td>Anticipo a recibir</td>
                      <td>${dotNumber(montoSeleccionado)}</td>
                      <td>F = C * E</td>
                    </tr>
                  </tbody>
                </table>
                {/* Móvil (Acordeón) */}
                <div className="accordion accordion-flush accordion-light accordion-table d-md-none">
                  {/* 1er acordeón */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="accordion-one">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-item-anticipo-1"
                        aria-expanded="true"
                        aria-controls="accordion-item-anticipo-1"
                      >
                        <strong className="text-uppercase">
                          Anticipo recibido mes anterior
                        </strong>
                      </button>
                    </h2>
                    <div
                      id="accordion-item-anticipo-1"
                      className="accordion-collapse collapse show"
                      aria-labelledby="accordion-one"
                    >
                      <div className="accordion-body p-0">
                        {/* cuerpo item */}
                        <div className="data-list-box text-uppercase">
                          <ul className="data-list-section--item list-unstyled">
                            <li>
                              <span className="data-list-box__title">
                                Monto / Porcentaje
                              </span>
                              <span className="data-list-box__text">
                                ${dotNumber(montoAnticipo)}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title">
                                Simbología
                              </span>
                              <span className="data-list-box__text">A</span>
                            </li>
                          </ul>
                        </div>
                        {/* fin cuerpo item */}
                      </div>
                    </div>
                  </div>
                  {/* 2do acordeón */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="accordion-two">
                      <button
                        className="accordion-button "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-item-anticipo-2"
                        aria-expanded="true"
                        aria-controls="accordion-item-anticipo-2"
                      >
                        <strong className="text-uppercase">
                          Sueldo recibido mes anterior
                        </strong>
                      </button>
                    </h2>
                    <div
                      id="accordion-item-anticipo-2"
                      className="accordion-collapse collapse show"
                      aria-labelledby="accordion-two"
                    >
                      <div className="accordion-body p-0">
                        {/* cuerpo item */}
                        <div className="data-list-box text-uppercase">
                          <ul className="data-list-section--item list-unstyled">
                            <li>
                              <span className="data-list-box__title">
                                Monto / Porcentaje
                              </span>
                              <span className="data-list-box__text">
                                ${dotNumber(montoLiquido)}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title">
                                Simbología
                              </span>
                              <span className="data-list-box__text">B</span>
                            </li>
                          </ul>
                        </div>
                        {/* fin cuerpo item */}
                      </div>
                    </div>
                  </div>
                  {/* 3er acordeón */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="accordion-three">
                      <button
                        className="accordion-button "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-item-anticipo-3"
                        aria-expanded="true"
                        aria-controls="accordion-item-anticipo-3"
                      >
                        <strong className="text-uppercase">
                          Total mes anterior
                        </strong>
                      </button>
                    </h2>
                    <div
                      id="accordion-item-anticipo-3"
                      className="accordion-collapse collapse show"
                      aria-labelledby="accordion-three"
                    >
                      <div className="accordion-body p-0">
                        {/* cuerpo item */}
                        <div className="data-list-box text-uppercase">
                          <ul className="data-list-section--item list-unstyled">
                            <li>
                              <span className="data-list-box__title">
                                Monto / Porcentaje
                              </span>
                              <span className="data-list-box__text">
                                ${dotNumber(totalMesAnterior)}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title">
                                Simbología
                              </span>
                              <span className="data-list-box__text">
                                C = A + B
                              </span>
                            </li>
                          </ul>
                        </div>
                        {/* fin cuerpo item */}
                      </div>
                    </div>
                  </div>
                  {/* 4to acordeón */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="accordion-four">
                      <button
                        className="accordion-button "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-item-anticipo-4"
                        aria-expanded="true"
                        aria-controls="accordion-item-anticipo-4"
                      >
                        <strong className="text-uppercase">
                          Máximo anticipo a solicitar ({valorPorcentaje}%)
                        </strong>
                      </button>
                    </h2>
                    <div
                      id="accordion-item-anticipo-4"
                      className="accordion-collapse collapse show"
                      aria-labelledby="accordion-four"
                    >
                      <div className="accordion-body p-0">
                        {/* cuerpo item */}
                        <div className="data-list-box text-uppercase">
                          <ul className="data-list-section--item list-unstyled">
                            <li>
                              <span className="data-list-box__title">
                                Monto / Porcentaje
                              </span>
                              <span className="data-list-box__text">
                                ${dotNumber(maximoAnticipo)}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title">
                                Simbología
                              </span>
                              <span className="data-list-box__text">D</span>
                            </li>
                          </ul>
                        </div>
                        {/* fin cuerpo item */}
                      </div>
                    </div>
                  </div>
                  {/* 5to acordeón */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="accordion-five">
                      <button
                        className="accordion-button "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-item-anticipo-5"
                        aria-expanded="true"
                        aria-controls="accordion-item-anticipo-5"
                      >
                        <strong className="text-uppercase">
                          Seleccione porcentaje a solicitar
                        </strong>
                      </button>
                    </h2>
                    <div
                      id="accordion-item-anticipo-5"
                      className="accordion-collapse collapse show"
                      aria-labelledby="accordion-five"
                    >
                      <div className="accordion-body p-0">
                        {/* cuerpo item */}
                        <div className="data-list-box text-uppercase">
                          <ul className="data-list-section--item list-unstyled">
                            <li>
                              <span className="data-list-box__title">
                                Monto / Porcentaje
                              </span>
                              <span className="data-list-box__text">
                                <select
                                  id="inputState"
                                  className="form-select"
                                  required="required"
                                  onChange={(e) => setPorcentajeSeleccionado(e.target.value)}
                                >
                                  <option disabled value=""
                                  >
                                    Seleccione porcentaje
                                  </option>
                                  {rangoValores().map((e) => {
                                    return e;
                                  })}
                                </select>
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title">
                                Simbología
                              </span>
                              <span className="data-list-box__text">E</span>
                            </li>
                          </ul>
                        </div>
                        {/* fin cuerpo item */}
                      </div>
                    </div>
                  </div>
                  {/* 6to acordeón */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="accordion-six">
                      <button
                        className="accordion-button "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-item-anticipo-6"
                        aria-expanded="true"
                        aria-controls="accordion-item-anticipo-6"
                      >
                        <strong className="text-uppercase">
                          Máximo anticipo a recibir
                        </strong>
                      </button>
                    </h2>
                    <div
                      id="accordion-item-anticipo-6"
                      className="accordion-collapse collapse show"
                      aria-labelledby="accordion-six"
                    >
                      <div className="accordion-body p-0">
                        {/* cuerpo item */}
                        <div className="data-list-box text-uppercase">
                          <ul className="data-list-section--item list-unstyled">
                            <li>
                              <span className="data-list-box__title">
                                Monto / Porcentaje
                              </span>
                              <span className="data-list-box__text">
                                ${dotNumber(montoSeleccionado)}
                              </span>
                            </li>
                            <li>
                              <span className="data-list-box__title">
                                Simbología
                              </span>
                              <span className="data-list-box__text">
                                F = C * E
                              </span>
                            </li>
                          </ul>
                        </div>
                        {/* fin cuerpo item */}
                      </div>
                    </div>
                  </div>
                  {/* fin items acordeón */}
                </div>
                {/* fin Móvil (Acordeón) */}
              </div>
              {/* fin Descripción del Anticipo */}

              <div className="ps-4 d-flex">
                <i
                  className="fa fa-exclamation-triangle fa-lg mt-1 me-3 color-red"
                  aria-hidden="true"
                ></i>
                <div>
                  <p className="fw-bold">
                    Recuerde que esto corresponde a una Simulación, por lo que
                    el monto a recibir de anticipo podría presentar variación.
                  </p>
                  <p>
                    (*) Bono de Evaluación de desempeño{" "}
                    <strong>no incluido</strong>.
                  </p>
                </div>
              </div>

              <div className="text-end">
                {/* Botón enviar y editar */}
                {anticipoModificacion ? (
                  <>
                    <div className="text-end d-grid d-md-block">
                      <button
                        type="button"
                        onClick={(e) => levantaModalModificacion(e)}
                        className="btn btn-degradado me-md-4 mb-2 mb-md-0"
                      >
                        Enviar{" "}
                        <i
                          className="fa fa-chevron-right fa-fw fa-xs"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <button
                        type="button"
                        onClick={(e) => eliminarAnticipo(e)}
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#eliminar"
                      >
                        Eliminar{" "}
                        <i
                          className="fa fa-chevron-right fa-fw fa-xs"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="text-end d-grid d-md-block">
                    <button
                      type="submit"
                      disabled={desactivado}
                      className="btn btn-degradado me-md-4 mb-2 mb-md-0"
                      data-bs-toggle="modal"
                      data-bs-target="#confirmacion"
                    >
                      Enviar{" "}
                      <i
                        className="fa fa-chevron-right fa-fw fa-xs"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                )}
              </div>
            </section>
          </form>
        </>
      ) : (
        <>
          <h1>Mis Solicitudes</h1>

          <div className="box mb-4">
            <h2 className="h4 tit-section">Solicitud Anticipo Quincenal</h2>
            <h3 className="h5">Detalle</h3>
            <p>
              Este servicio no se encuentra disponible según tu empleador o tipo
              de contrato.
            </p>
          </div>
        </>
      )}
      {modalPrimerIngreso && anticipoData && anticipoData.eliminar === "N" && anticipoData.ingreso === "N" && anticipoData.modificar === "N" ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-exclamation-triangle color-orange fa-3x"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Aviso importante</h2>
                    <br></br>
                    <p align="left">
                      Las solicitudes de ingreso, modificación o eliminación de
                      Anticipo Quincenal de Sueldo son procesadas para hacerse
                      efectivas hasta el día {diaCorte} de cada mes, por lo que
                      cualquier acción posterior a esa fecha se verá reflejada
                      al mes siguiente de su ejecución.
                    </p>
                    <br></br>
                    <p align="left">
                      <b>RECUERDE QUE:</b>
                    </p>

                    <p align="left">
                      El monto a recibir de anticipo es variable, debido a que
                      corresponde a un porcentaje respecto del sueldo líquido
                      total recibido el mes anterior.
                    </p>
                    <br></br>
                    <p align="left">FECHA DE HOY: {formato(hoy)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {confirmacion ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    {/*                 
                                                    <i className="fa fa-check-circle-o color-orange fa-3x mb-2" aria-hidden="true"></i> */}
                    <h2 className="h5">NECESITAMOS TU CONFIRMACIÓN</h2>
                    <br></br>
                    <p align="left">
                      Estimado(a) {name}, está ingresando la siguiente
                      solicitud:
                    </p>

                    <div className="data-list-box">
                      <div className="d-block d-md-flex">
                        <ul className="text-uppercase list-unstyled">
                          <li>
                            <span
                              className="col-4"
                              style={{ marginRight: "3%" }}
                            >
                              <b>Tipo: &nbsp;</b>
                            </span>
                            <span>INGRESO ANTICIPO QUINCENAL</span>
                          </li>
                          <li>
                            <span
                              className="col-4"
                              style={{ marginRight: "3%" }}
                            >
                              <b>Porcentaje: &nbsp;</b>
                            </span>
                            <span className="data-list-box__text col-2">
                              {porcentajeSeleccionado}%
                            </span>
                          </li>

                          <li>
                            <span
                              className="col-4"
                              style={{ marginRight: "3%" }}
                            >
                              <b>Recurrencia: &nbsp;</b>
                            </span>
                            <span>TODOS LOS MESES</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p align="left">
                      (*) En caso de que en un mes no presente las condiciones
                      para optar al beneficio, este quedará inhabilitado y se
                      reactivará de forma automática cuando se vuelvan a cumplir
                      los requisitos.
                    </p>
                    <br></br>

                    <button
                      style={{ marginRight: "3%" }}
                      onClick={(e) => {
                        handleCancel(e);
                      }}
                      type="submit"
                      className="btn btn-primary"
                      id="enviarSoli"
                      data-bs-dismiss="modal"
                    >
                      Cancelar{" "}
                      <i
                        className="fa fa-chevron-right fa-fw fa-xs"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <button
                      onClick={(e) => aceptarAnticipo(e)}
                      disabled={porcentajeSeleccionado === 0}
                      type="submit"
                      className="btn btn-degradado"
                      id="enviarSoli"
                      data-bs-dismiss="modal"
                    >
                      Confirmar{" "}
                      <i
                        className="fa fa-chevron-right fa-fw fa-xs"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {confirmacionModificacion ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    {/*                 
                                                    <i className="fa fa-check-circle-o color-orange fa-3x mb-2" aria-hidden="true"></i> */}
                    <h2 className="h5">NECESITAMOS TU CONFIRMACIÓN</h2>
                    <br></br>
                    <p align="left">
                      Estimado(a) {name}, está ingresando la siguiente
                      solicitud:
                    </p>

                    <div className="data-list-box">
                      <div className="d-block d-md-flex">
                        <ul className="text-uppercase list-unstyled">
                          <li>
                            <span
                              className="col-4"
                              style={{ marginRight: "3%" }}
                            >
                              <b>Tipo: &nbsp;</b>
                            </span>
                            <span>MODIFICAR ANTICIPO QUINCENAL</span>
                          </li>
                          <li>
                            <span
                              className="col-4"
                              style={{ marginRight: "3%" }}
                            >
                              <b>Total mes anterior:</b>
                            </span>
                            <span>${dotNumber(totalMesAnterior)}</span>
                          </li>

                          <li>
                            <span
                              className="col-4"
                              style={{ marginRight: "3%" }}
                            >
                              <b>Porcentaje: &nbsp;</b>
                            </span>
                            <span>
                            {porcentajeSeleccionado}%
                            </span>
                          </li>

                          <li>
                            <span
                              className="col-4"
                              style={{ marginRight: "3%" }}
                            >
                              <b>Anticipo a solicitar: &nbsp;</b>
                            </span>

                            <span>${dotNumber(montoSeleccionado)}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p align="left">
                      (*) En caso de que en un mes no presente las condiciones
                      para optar al beneficio, este quedará inhabilitado y se
                      reactivará de forma automática cuando se vuelvan a cumplir
                      los requisitos.
                    </p>
                    <br></br>

                    <button
                      style={{ marginRight: "3%" }}
                      onClick={() => handleCancelConf(false)}
                      type="submit"
                      className="btn btn-primary"
                      id="enviarSoli"
                      data-bs-dismiss="modal"
                    >
                      Cancelar{" "}
                      <i
                        className="fa fa-chevron-right fa-fw fa-xs"
                        aria-hidden="true"
                      ></i>
                    </button>

                    <button
                      onClick={(e) => aceptarModificacionAnticipo(e)}
                      type="submit"
                      className="btn btn-degradado"
                      id="enviarSoli"
                      data-bs-dismiss="modal"
                    >
                      Confirmar{" "}
                      <i
                        className="fa fa-chevron-right fa-fw fa-xs"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {estadoResponse ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => redireccionarInicio(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Solicitud de anticipo</h2>
                    <br></br>
                    <p>
                      <b>{textResponse}</b>
                    </p>
                  </div>
                  <div className="modal-footer  border-0 pt-0">
                    <button
                        type="button"
                        className="btn btn-degradado"
                        data-bs-dismiss="modal"
                        onClick={(e) => redireccionarInicio(e)}
                    >Cerrar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {!tieneNet ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => handleClose2(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-exclamation-triangle color-orange fa-3x"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">No tienes conexión</h2>
                    <p>No puedes ejecutar esta acción si no tienes conexión</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {estadoResponseError ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0">
                    <button
                      type="submit"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="enviar"
                      onClick={(e) => redireccionarInicio(e)}
                    ></button>
                  </div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Solicitud de anticipo</h2>
                    <br></br>
                    <p>
                      <b>{textResponse}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {cargando ? (
        <>
          <div className="col">
            <div
              className="modal fade show"
              id="foto-credencial"
              tabIndex={"-1"}
              aria-labelledby="enviar-por-correo"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", textAlign: "left" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header border-0 pb-0"></div>
                  <div className="modal-body text-center py-4">
                    <i
                      className="fa fa-download color-orange fa-3x mb-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      aria-hidden="true"
                    ></i>
                    <h2 className="h5">Enviando solicitud..</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default Anticipo;
