import { create } from 'zustand';

interface FormStore {
  submitForm: (() => Promise<FormData | null>) | null;
  setSubmitForm: (fn: () => Promise<any>) => void;
  dataForm: FormData | null;
  setDataForm: (data: FormData) => void;
  resetDataForm: () => void;
}

export const useFormStore = create<FormStore>((set) => ({
  dataForm: null,
  submitForm: null,
  setSubmitForm: (fn) => set({ submitForm: fn }),
  setDataForm: (data) => set({ dataForm: data }),
  resetDataForm: () => set({ dataForm: null })
}));
