import React from 'react';
import "dotenv/config";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router} from 'react-router-dom';
import App from './App';
import './resources/css/style.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ContextProvider } from './context';
import { QueryClientProvider } from 'react-query';
import queryClient from './queryClient';

import "./utils/i18n/i18n";

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Router>
                <ContextProvider>
                    <App />
                </ContextProvider>
            </Router>
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
serviceWorkerRegistration.unregister();
