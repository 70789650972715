import { useEffect, useState } from "react";
import { useAxiosFetch } from "../../../../hooks";
import { CollectiveAgreementRequestsApi } from "./CollectiveAgreementRequestApi.types";

export const useCollectiveAgreementRequestsFetch = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [fetchCollectiveAgreementRequests, collectiveData] = useAxiosFetch<
    CollectiveAgreementRequestsApi[]
  >({
    url: `api/benefits/benefit-requests`,
    method: "GET",
  });

  const [updateBenefitRequest, updateBenefitRequestData] = useAxiosFetch({
    url: `/api/benefits/benefit-request/cancel`,
    method: "PUT",
  });

  useEffect(() => {
    fetchCollectiveAgreementRequests();
  }, []);

  useEffect(() => {
    setIsLoading(
      collectiveData.isLoading || updateBenefitRequestData.isLoading
    );
  }, [updateBenefitRequestData.isLoading, collectiveData.isLoading]);
  const submitUpdateBenefitRequest = async (idRequest: number) => {
    try {
      await updateBenefitRequest({
        data: { idRequest },
      });
      await fetchCollectiveAgreementRequests();

      return { success: true, message: "Solicitud eliminada con éxito" };
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        return { success: false, message: error.response.data.message };
      } else {
        return { success: false, message: "Error al eliminar la solicitud" };
      }
    }
  };

  const collectiveDataMapper = () => {
    if (collectiveData.data) {
      return (
        collectiveData.data.map((item) => ({
          requestId: item.requestId ?? "-",
          benefitId: item.benefitId ?? "-",
          benefitName: item.benefitName ?? "-",
          dateRequest: item.dateRequest ?? "-",
          status: item.status ?? "-",
        })) || []
      );
    }
    return [];
  };

  return {
    collectiveAgreement: collectiveDataMapper(),
    submitUpdateBenefitRequest,
    updateBenefitRequestData,
    isLoading,
  } as const;
};
