import React, { useContext } from "react";
import { httpClientEnv } from "../../../../services/apiServices/httpClient";
import RemoteJobs from "./remoteJob/remoteJob";

export const HttpClientContext = React.createContext(httpClientEnv);
export const useHttpClient = () => useContext(HttpClientContext);

export const RemoteJob = () => {
    return (
        <HttpClientContext.Provider value={httpClientEnv}>
            <RemoteJobs/>
        </HttpClientContext.Provider>
    )
};
