import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import styles from "../../resources/css/style-COL.module.css";
import Formulario from './formulario';
import VerArchivo from './verArchivo';
import DescargarArchivo from './descargarArchivo';
import RenunciaVoluntariaService from '../../services/renuncia-voluntaria-col/service';
import Encuesta from './encuesta';

const RenunciaVoluntaria = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [loadingEstado, setLoadingEstado] = useState(true)
    const [data, setData] = useState({})

    const verEstado = async () => {
        const response = await RenunciaVoluntariaService.verEstado();
        console.log(response, "ver estado")
        if (response.status == 200 && response.data) {
            navigate("descargar-archivo")
            setLoadingEstado(false)
        } else {
            navigate("")
            setLoadingEstado(false)
        }
    }
    const getData = async () => {
        try {
            const response = await RenunciaVoluntariaService.getInfoColaborador();

            if (
                response !== null &&
                response !== undefined &&
                (response?.statusCode === 200 || response?.status === 200)
            ) {
                setData(response?.data);
                setLoading(false)
            } else {
                setLoading(false)
            }

            setLoading(false);
        } catch (error) {
            errorMessage(null, error?.response?.data);
            setLoading(false);
        }
    }

    useEffect(() => {
        getData()
        verEstado()
    }, [])

    return <>
        {/* <!-- inicio breadcrumbs -->   */}
        <nav className={`${styles["breadcrumbs"]} ${styles["d-none"]} ${styles["d-lg-block"]}`} aria-label="breadcrumb">
            <ol className={styles["breadcrumb"]}>
                <li className={styles["breadcrumb-item"]}><Link to={"/"} ><i className={`${styles["fa"]} ${styles["fa-home"]}`} aria-hidden="true"></i> Inicio</Link></li>
                <li className={`${styles["breadcrumb-item"]} ${styles["active"]}`} aria-current="page">Renuncia Voluntaria</li>
            </ol>
        </nav>
        {/* <!-- fin breadcrumbs --> */}
        <section className={styles["renuncia-voluntaria"] + " " + "cencosud-col"}>
            <h1>Renuncia Voluntaria</h1>
            <Routes>
                <Route exact path={"/"} element={<Encuesta data={data} />} />
                <Route exact path={"formulario"} element={<Formulario data={data} loading={loading} loadingEstado={loadingEstado} />} />
                <Route exact path={"ver-archivo"} element={<VerArchivo />} />
                  <Route exact path={"descargar-archivo"} element={<DescargarArchivo email={data.correo_electronico_personal}/>} />
            </Routes>
        </section>
    </>
}

export default RenunciaVoluntaria;