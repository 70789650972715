import React from 'react'
import { Link } from 'react-router-dom';
import CodeEthicsTable from './codeEthics-table';

const CodeEthics = () => {

    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home color-orange" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                       Código de ética
                    </li>
                </ol>
            </nav>
            <section className="noticias mb-3">
                <h1>Código de ética</h1>
            </section>
            <div>
                <div className="row">
                <CodeEthicsTable></CodeEthicsTable>
                </div>
            </div>

        </>
    )
}

export default CodeEthics