const RUT_REGEXP: RegExp = /^0*(\d{1,3}(\d{3})*)-([\dkK])$/;

export const validarRut = (rut: string = ''): boolean => {

    if (!RUT_REGEXP.test(rut)) return false;

    rut = rut.replace(/\./g, "").replace(/-/g, "");
    if (rut.slice(-1) === "K") {
        rut = rut.slice(0, -1) + "k";
    }

    const cleanRut = rut.replace("-", "");
    const rutDigits = cleanRut.slice(0, -1);
    const verificador = cleanRut.slice(-1);

    let suma = 0;
    let multiplicador = 2;

    for (let i = rutDigits.length - 1; i >= 0; i--) {
        suma += parseInt(rutDigits[i]) * multiplicador;
        multiplicador = multiplicador >= 7 ? 2 : multiplicador + 1;
    }

    const resto = suma % 11;
    const digitoVerificador = resto === 1 ? "k" : resto === 0 ? "0" : (11 - resto).toString();

    return verificador === digitoVerificador;
}