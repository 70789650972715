import { Outlet, useNavigate } from "react-router-dom";
import FirstLoginService from "../../services/firstlogin-service";
import Auth from "@aws-amplify/auth";
import config from "../../config";

const signIn = async () => {
  await Auth.federatedSignIn({
    provider: config.AWS_COGNITO_IDP_NAME,
  });
};

const RequireFirstLogin = ({}) => {
  const navigate = useNavigate();

  Auth.currentAuthenticatedUser()
    .then(() => {
      FirstLoginService.validateLogin().then((response) => {
        const loginData = response;
        loginData.loginstatus == 0 ? navigate("/terminosycondiciones") : null;
        loginData.loginstatus == 1 ? navigate("/maildeactivacion") : null;
        if (loginData.loginstatus > 1) {
          return <Outlet />;
        } else {
          return null;
        }
      });
    })
    .catch(() => {
      signIn();
    });

  return <Outlet />;
};

export default RequireFirstLogin;
