import React, { Children, FC } from "react";
import { TableInfoBonusProps } from "../interfaces/interfaces";

const TableInfoBonus: FC<TableInfoBonusProps> = ({tables}) => {
    return(
        <>
                {Children.toArray(tables.map((table)=> {
                    return(
                        <>
                            <div className="table-responsive">
                                <p>{table.description}</p>
                                <table className="table table-even table-th-border table-section table-borderless text-uppercase align-middle">
                                    <thead>
                                        <tr>
                                            {Children.toArray(table.tableHeader.map((header)=> {
                                                return(
                                                    <>
                                                        <th>{header}</th>
                                                    </>
                                                )
                                            }))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Children.toArray(table.dataTable.map((data)=> {
                                            return(
                                                <>
                                                    <tr>
                                                        <td>{data.key}</td>
                                                        <td>{data.value}</td>
                                                    </tr>
                                                </>
                                            )
                                        }))}                                  
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )
                }))}
                
        </>
    )
}
export default TableInfoBonus; 