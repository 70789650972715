import React, { useEffect, useState } from "react";
import styles from "../../resources/css/style-COL.module.css";
import Fields from "./fields";
import Historic from "./historic";

const Form = ({
  setViewHistoric,
  conflictTypes,
  relationships,
  historics,
  reload,
  interestConflictStatus,
}) => {
  return (
    <div className={`${styles["row"]} ${styles["mb-4"]}`}>
      {/* <!-- Nombre y cargo --> */}
      <div className={`${styles["col-12"]} ${styles["mb-4"]}`}>
        <div className={styles["box"]}>
          {/* <img src="assets/images/foto-usuario-h.png" alt="" className={`${styles["rounded-circle"]} ${styles["mb-3"]}`} width="120" height="120">
            <h2 className={`${styles["h3"]} ${styles["mb-2"]}`}>Pablo Torres A.</h2>
            <p className={`${styles["h5"]} ${styles["fw-bold"]}`}>Especialista en Aplicaciones</p> */}

          <div
            className={
              styles["data-list-box"] +
              " " +
              styles["data-list-box-align-middle"] +
              " " +
              styles["mt-3"]
            }
          >
            <ul className={styles["list-unstyled"]}>
              <li>
                <span
                  className={`${styles["data-list-box__title"]} ${styles["col-6"]} ${styles["color-primary"]} ${styles["d-inline-block"]}`}
                >
                  Estado:
                </span>
                <span className={styles["data-list-box__text"]}>
                  {interestConflictStatus?.status}
                </span>
              </li>
              <li>
                <span
                  className={`${styles["data-list-box__title"]} ${styles["col-6"]} ${styles["color-primary"]} ${styles["d-inline-block"]}`}
                >
                  Última Firma:
                </span>
                <span className={styles["data-list-box__text"]}>
                  {interestConflictStatus?.signatureDate}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- Detalle --> */}
      <div className={`${styles["col-12"]}`}>
        <div className={styles["box"]}>
          <h2 className={`${styles["h4"]} ${styles["tit-section"]}`}>
            Detalle
          </h2>
          <div className={styles["mb-4"]}>
            <p>
              A continuación, y segun lo estipulado en el Código de Ética, doy a
              conocer las situaciones que actualmente pudieran "Ser" o "Parecer"
              un conlifcto de interés respecto a mi cargo.
            </p>
            <p>
              Junto con ello, me pongo en disposición de la compañía para
              entregarle información adicional, en caso de que esto sea
              necesario (Especificar la situación y en caso que corresponda:
              Nombres, parentesco, número de documento de identificación de
              Sociedades, vinculaciones con proveedores/terceros, etc).
            </p>
          </div>
          <div className={styles["mb-4"]}>
            <label className={styles["form-label"]}>Tipos de Conflicto</label>
            <div
              className={`${styles["border"]} ${styles["border-primary"]} ${styles["p-3"]} ${styles["rounded-4"]} ${styles["mb-3"]}`}
            >
              <div
                className={styles["box-scroll"]}
                style={{ maxHeight: "200px" }}
              >
                <p>
                  <span className={styles["color-primary"]}>
                    Familiar en la competencia
                  </span>
                  : Corresponde a amigos, <b>parejas o familiares</b> que el
                  colaborador tenga en competidores del grupo de empresas de
                  Cencosud.
                </p>
                <p>
                  <span className={styles["color-primary"]}>
                    Familiar de Proveedor/Cliente
                  </span>
                  : Corresponde a amigos, <b>parejas o familiares</b> que tengan
                  alguna participación o sean accionistas (superior al 10%) de
                  cualquier empresa que sea proveedora y/o cliente del grupo de
                  empresas de Cencosud.
                </p>
                <p>
                  <span className={styles["color-primary"]}>
                    Familiar / pareja en la compañía
                  </span>
                  : Corresponde a amigos, <b>parejas o familiares</b> que
                  trabajen en alguna de las empresas el grupo Cencosud.
                </p>
                <p>
                  <span className={styles["color-primary"]}>
                    Emprendimientos
                  </span>
                  : Corresponde a cualquier negocio, participación en empresa, o
                  acciones en Sociedades que posea el colaborador en empresas
                  que tengan una actividad similar a las del grupo de empresas
                  de Cencosud. En Observaciones colocar información adicional
                  como actividad de la empresa, principales clientes /
                  Proveedores, mencionar los dueños/propietarios/accionistas,
                  escrituras de constitución, etc.
                </p>
                <p>
                  <span className={styles["color-primary"]}>Otros</span>:
                  Corresponde a cualquier relación que pudiera ser considerada
                  como conflicto de interés no mencionado precedentemente. En
                  Observaciones puede colocar la información adicional referente
                  a este conflicto.
                </p>
                <p>
                  Cuando nos referimos a <b>"Familiares"</b> nos referimos a
                  cualquier pariente incluidas las personas que residan en el
                  domicilio del colaborador.
                </p>
                <p>
                  Cuando nos referimos a <b>"Parejas"</b> corresponde a una
                  relación sentimental actual, entre los colaboradores, bien sea
                  con vinculo de matrimonio o sin él.
                </p>
              </div>
            </div>
          </div>

          <div className={styles["row"]}>
            <Fields
              setViewHistoric={setViewHistoric}
              conflictTypes={conflictTypes}
              relationships={relationships}
              reload={reload}
            />
          </div>

          <div className={styles["row"]}>
            <br />
            <Historic
              historics={historics}
              signatureDate={interestConflictStatus?.signatureDate}
              relationships={relationships}
              reload={reload}
              conflictTypes={conflictTypes}
            />
          </div>
        </div>
      </div>

      {/* <!-- fin Detalle --> */}
    </div>
  );
};

export default Form;
