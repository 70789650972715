import { FC } from "react";
import { Link } from "react-router-dom";
import { BreadcrumRequestProps } from "./interfaces";

const BreadcrumRequest: FC<BreadcrumRequestProps> = ({title}) => {
    return(
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true" /> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/mis-solicitudes">
                            <i className="breadcrumb-item" aria-hidden="true" /> Mis Solicitudes
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                </ol>
            </nav>
        </>
    )
}

export default BreadcrumRequest;