import { useMemo, useState } from "react";
import { ColumnsConfig } from "../../../../components/table";
import { useProfileSummaryProvider } from "../../context/EmployeeProfileSummaryContext";
import { LayoutCard } from "../employeeProfileSummary/LayoutCard";

export const DaysTaken = () => {
  const { summaryEmployee } = useProfileSummaryProvider();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const columnsConfigDays: ColumnsConfig[] = [
    {
      accessor: "year",
      header: "AÑO",
      className: "text-center",
    },
    {
      accessor: "dateInit",
      header: "F. INICIO",
      className: "text-center",
    },
    {
      accessor: "dateEnd",
      header: "F. FINAL",
      className: "text-center",
    },
    {
      accessor: "days",
      header: "DÍAS",
      className: "text-center",
    },
  ];

  const filteredData = useMemo(() => {
    return summaryEmployee.data.daysTaken.filter((day) => day.approved_flag);
  }, [summaryEmployee.data]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    return filteredData.slice(startIndex, startIndex + pageSize).map((day) => ({
      ...day,
      approved_flag: (
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          aria-label="..."
          checked={day.approved_flag}
          disabled={true}
        />
      ),
    }));
  }, [filteredData, currentPage]);

  const totalPages = Math.ceil(filteredData.length / pageSize);

  if (summaryEmployee.isLoading) return <></>;

  return (
    <div className="col-12 col-xl-7 d-flex">
      <div className="box align-items-stretch w-100">
        <LayoutCard
          title="Días Tomados"
          columnsConfig={columnsConfigDays}
          data={paginatedData}
          style={{height: "21rem"}}
        />
        {filteredData.length > pageSize && (
        <div className="d-flex justify-content-between mt-2">
          <button
            className="btn btn-primary"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          <span>
            Página {currentPage} de {totalPages}
          </span>
          <button
            className="btn btn-primary"
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
          >
            Siguiente
          </button>
        </div>
        )}
      </div>
    </div>
  );
};
