import {FC} from 'react'
import { TaskProps, TaskComponentProps } from './Helpers/interfaces/task'
import { Link } from 'react-router-dom'
import { formatDate } from '../../services/utiles'
import useResponsiveBreakpoint from './hooks/useResponsiveBreakpoint'
const Task : FC<TaskComponentProps> = ({id, data} : TaskComponentProps) => {
    const breakpoint = useResponsiveBreakpoint();
    const heightClassName = `box-scroll${breakpoint === 'xxl' ? '-x2' :
        ['xs', 'sm', 'md', 'lg'].includes(breakpoint) ? '-sm' : ''}`;

    return <div id={id} className="py-2 m-0 px-0 col-12 col-md-4 w-100 h-100" >
        <div className='tit-group d-flex justify-content-between mb-2'>
            <h2 className={`m-0 fs-5 tit-section`}>Tareas Pendientes</h2>
            <div className='d-flex flex-column flex-center justify-content-center me-3'>
                { data && data.length > 0 && <span className={`badge bg-primary rounded-circle ms-2 pt-1`}>{data.length}</span> }    
            </div>
        </div>

        <ul className={`list-group list-group-flush ${heightClassName}`}>
            { data.length > 0 ? data.map((item: TaskProps, i : number) => 
                <li key={i} className="list-group-item p-0 pt-1 pb-1">
                    <Link to={item.component + '?' + item.parameters}  state={{id:item.id, title:item.title}} className="m-0 text-secondary text-decoration-none d-flex justify-content-between align-items-center pe-2">
                        <div className="d-flex flex-column">
                            <span className="fw-bold">{item.title}</span>
                            <span className="small">{formatDate(item.created_at)}</span>
                        </div>
                        <i className="fa fa-chevron-right fs-4 text-secondary"></i>
                    </Link>
                </li>
            )
            : <li className="list-group-item p-0 pt-1 pb-1">
                <span className="fw-bold text-secondary">No hay tareas pendientes</span>
            </li>
            }
        </ul>
    </div>;
}

export default Task;