import { createContext, useContext, useState } from "react";
import {
  CollectiveAgreementContextType,
  CollectiveAgreementProviderProps,
  CreateCollectiveAgreementBenefitsApi,
} from "../hooks/CollectiveAgreementApi.types";
import { useCollectiveAgreementFetch } from "../hooks";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";

export const CollectiveAgreementDataContext = createContext<
  CollectiveAgreementContextType | undefined
>(undefined);

export type FormData = {
  [key: string]: string;
};

export const CollectiveAgreementProvider = (
  props: CollectiveAgreementProviderProps
) => {
  const { children } = props;
  const [modalMessage, setModalMessage] = useState<string | null>(null);
  const [modalIcon, setModalIcon] = useState<"success" | "error">("error");
  const [showModal, setShowModal] = useState(false);
  const [selectedBenefit, setSelectedBenefit] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const {
    benefitsList,
    benefitsListDetail,
    submitCreateBenefitRequest,
    isLoading,
  } = useCollectiveAgreementFetch(selectedBenefit?.id);

  const handleBenefitChange = ({
    target,
  }: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = target.selectedOptions[0].getAttribute("data-id");
    const selectedName = target.selectedOptions[0].getAttribute("data-name");

    if (selectedId && selectedName) {
      setSelectedBenefit({
        id: Number(selectedId),
        name: selectedName,
      });
    } else {
      setSelectedBenefit(null);
    }
  };

  const displayModal = (message: string, icon: "success" | "error") => {
    setModalMessage(message);
    setModalIcon(icon);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const navigate = useNavigate();

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === "string") {
          const base64String = reader.result.split(",")[1];
          resolve(base64String);
        } else {
          reject(new Error("Failed to convert file to base64"));
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const processValue = async (value: any): Promise<string | number> => {
    if (value instanceof FileList && value.length > 0) {
      const file = value[0];
      return await fileToBase64(file);
    }
    if (value instanceof File) {
      return await fileToBase64(value);
    }
    if (typeof value === "number") {
      return value;
    }
    return String(value);
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const adaptedBody: CreateCollectiveAgreementBenefitsApi = {
        idBenefit: selectedBenefit?.id!,
        dateRequest: new Date().toISOString().split("T")[0],
        fieldList: await Promise.all(
          Object.entries(data).map(async ([key, value]) => ({
            name: key,
            value: await processValue(value),
            type:
              benefitsListDetail.find((input) => input.fieldName === key)
                ?.type ?? "",
          }))
        ),
      };
      const result = await submitCreateBenefitRequest(adaptedBody);
      if (result.success) {
        displayModal(result.message, "success");
        setTimeout(() => {
          navigate("/co/beneficios/convencion-colectiva/solicitudes");
        }, 2000);
      } else {
        displayModal(result.message, "error");
      }
    } catch (error) {
      if (error instanceof Error) {
        displayModal(`Error: ${error.message}`, "error");
      } else {
        displayModal("Error desconocido", "error");
      }
    }
  };

  const handleInputChange = (
    fieldName: string,
    fieldType: string,
    value: string
  ) => {
    setValue(fieldName, { value, type: fieldType });
  };

  const resetSelectedBenefit = () => {
    setSelectedBenefit(null);
  };

  const contextValue = {
    selectedBenefit,
    benefitsList,
    benefitsListDetail,
    handleBenefitChange,
    onSubmit,
    resetSelectedBenefit,
    register,
    handleSubmit,
    handleInputChange,
    errors,
    isLoading,
    showModal,
    modalMessage,
    hideModal,
    modalIcon,
  };

  return (
    <CollectiveAgreementDataContext.Provider value={contextValue}>
      {children}
    </CollectiveAgreementDataContext.Provider>
  );
};

export const useCollectiveAgreementProvider = () => {
  const collectiveAgreementDataContext = useContext(
    CollectiveAgreementDataContext
  );

  if (!collectiveAgreementDataContext)
    return {} as CollectiveAgreementContextType;

  return collectiveAgreementDataContext;
};
