export const factorsOptions = [
    { label: "Mejor oferta económica en otra compañía", value: "ENC_1A" },
    { label: "Necesito tiempo para asuntos familiares", value: "ENC_1B" },
    { label: "Oportunidad de crecimiento", value: "ENC_1C" },
    { label: "Equilibrio vida personal - trabajo", value: "ENC_1D" },
    { label:  "Jornada de trabajo/turno", value: "ENC_1E" },
    { label: "Relacionado con mi jefe directo", value: "ENC_1F" },
    { label: "Beneficios", value: "ENC_1G" },
    { label:  "Estudios", value: "ENC_1H" },
    { label: "Larga distancia del hogar al sitio de trabajo", value: "ENC_1I" }
];

export const leadershipQuestions = [
    "Trata con respeto al equipo de trabajo",
    "Provee instrucciones de forma clara y hace acompañamiento",
    "Da retroalimentación constructiva y constante sobre el desempeño",
    "Demuestra capacidad al solucionar problemas y conflictos",
    "Coordina y delega funciones de manera objetiva y apropiada"
];
