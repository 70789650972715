interface LoadingSpinnerProps {
  label?: string;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  label = "Cargando información...",
}) => {
  return (
    <div className="text-center">
      <span className="spinner-border text-primary spinner-border-sm" />
      <p>{label}</p>
    </div>
  );
};
